import allProducts from './allProducts';
import { OrientationLandscape, OrientationPortrait } from '../redux/productReducer';

const getAllProductIds = () => allProducts.map(v => v.id);

const findProductId = (productType, size) => {
    const product = allProducts.find(v => v.printable_product_type === productType && (!size || v.trim_text === size));
    if (!product) return null;
    return product.id;
};

const findInvert = productType => {
    const invertedProductId = allProducts.find(product => product.id === productType.id)?.inverted_id;
    return allProducts.find(product => product.id === invertedProductId);
};

// eslint-disable-next-line max-len
const findProduct = (productType, size) =>
    allProducts.find(v => v.printable_product_type === productType && v.trim_text === size);

const findProductById = productId => allProducts.find(v => v.id === productId);

export const findLowestProduct = (finish_type, orientation) => {
    const trimText = orientation === OrientationPortrait ? '8x10' : '10x8';
    return allProducts.find(product => product.finish_type === finish_type && product.trim_text === trimText);
};

const findProductByMaterial = (finish_type, { orientation, trim_text }) => {
    const products = allProducts.filter(
        product => product.finish_type === finish_type && product.orientation === orientation
    );
    let selectedProduct = products.find(product => product.trim_text === trim_text);
    if (!selectedProduct) selectedProduct = products.shift();
    return selectedProduct;
};

const getAllSizes = productType => {
    const filtered = allProducts.filter(v => v.printable_product_type === productType);
    return filtered.map(v => v.trim_text);
};

export {
    getAllProductIds,
    findInvert,
    findProduct,
    findProductId,
    findProductById,
    getAllSizes,
    findProductByMaterial,
};
