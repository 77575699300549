import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { backFromEdit, cancelEdit } from '../../redux/fileReducer';
import { DokaModal } from '../../components/doka';
import { calculateAspectRatioForDoka, getBleedPercentages } from '../../utils/productService';
import { useIntl } from 'react-intl';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/dashboard/dist/style.css';

export const Doka = () => {
    const product = useSelector(state => state.product);
    const upload = useSelector(state => state.upload);
    const dispatch = useDispatch();
    const intl = useIntl();
    const { width, height } = getBleedPercentages(product.id, product.orientation);
    const bleed = {
        width: width / 2,
        height: height / 2
    };

    const mask = (root, setInnerHTML) => {
        // https://pqina.nl/doka/docs/patterns/api/doka-instance/#setting-the-crop-mask
        setInnerHTML(
            root,
            `
                <mask id="bleed-crop-mask">
                    <rect fill="rgba(255,255,255,1)" x="0%" y="0%" width="100%" height="${100.0 * bleed.height}%"/>
                    <rect fill="rgba(255,255,255,1)" x="0%" y="${100.0 - 100 * bleed.height}%" width="100%" height="${100.0 * bleed.height}%"/>
                    <rect fill="rgba(255,255,255,1)" x="0%" y="0%" width="${100 * bleed.width}%" height="100%"/>
                    <rect fill="rgba(255,255,255,1)" x="${100.0 - 100 * bleed.width}%" y="0%" width="${100 * bleed.width}%" height="100%"/>
                </mask>
                <rect fill="rgba(255,0,0,.3)" x="0" y="0" width="100%" height="100%" mask="url(#bleed-crop-mask)"/>     
            `
        );
    };

    return <DokaModal
        src = { upload.url }
        cropMask = { mask }
        cropMaskInset = { 0 }
        filters = {{
            'original': {
                label: intl.formatMessage({
                    id: 'doka-label-filter-original',
                    defaultMessage: 'Original',
                }),
                matrix: () => null
            },
            'chrome': {
                label: intl.formatMessage({
                    id: 'doka-label-filter-chrome',
                    defaultMessage: 'Chrome',
                }),
                matrix: () => [
                    1.398, -0.316, 0.065, -0.273, 0.201,
                    -0.051, 1.278, -0.080, -0.273, 0.201,
                    -0.051, 0.119, 1.151, -0.290, 0.215,
                    0.000, 0.000, 0.000, 1.000, 0.000
                ]
            },
            'fade': {
                label: intl.formatMessage({
                    id: 'doka-label-filter-fade',
                    defaultMessage: 'Fade',
                }),
                matrix: () => [
                    1.073, -0.015, 0.092, -0.115, -0.017,
                    0.107, 0.859, 0.184, -0.115, -0.017,
                    0.015, 0.077, 1.104, -0.115, -0.017,
                    0.000, 0.000, 0.000, 1.000, 0.000
                ]
            },
            'mono': {
                label: intl.formatMessage({
                    id: 'doka-label-filter-mono',
                    defaultMessage: 'Mono',
                }),
                matrix: () => [
                    0.212, 0.715, 0.114, 0.000, 0.000,
                    0.212, 0.715, 0.114, 0.000, 0.000,
                    0.212, 0.715, 0.114, 0.000, 0.000,
                    0.000, 0.000, 0.000, 1.000, 0.000
                ]
            },
            'noir': {
                label: intl.formatMessage({
                    id: 'doka-label-filter-noir',
                    defaultMessage: 'Noir',
                }),
                matrix: () => [
                    0.150, 1.300, -0.250, 0.100, -0.200,
                    0.150, 1.300, -0.250, 0.100, -0.200,
                    0.150, 1.300, -0.250, 0.100, -0.200,
                    0.000, 0.000, 0.000, 1.000, 0.000
                ]
            }
        }}
        labelButtonReset = {
            intl.formatMessage({
                id: 'doka-label-button-reset',
                defaultMessage: 'Reset',
            })
        }
        labelButtonCancel = {
            intl.formatMessage({
                id: 'doka-label-button-cancel',
                defaultMessage: 'Cancel',
            })
        }
        labelButtonConfirm = {
            intl.formatMessage({
                id: 'doka-label-button-confirm',
                defaultMessage: 'Confirm',
            })
        }
        labelStatusAwaitingImage = {
            intl.formatMessage({
                id: 'doka-label-status-awating-image',
                defaultMessage: 'Waiting for image…',
            })
        }
        labelStatusLoadingImage = {
            intl.formatMessage({
                id: 'doka-label-status-loading-image',
                defaultMessage: 'Loading image…',
            })
        }
        labelStatusLoadImageError = {
            intl.formatMessage({
                id: 'doka-label-status-load-image-error',
                defaultMessage: 'Error loading image…',
            })
        }

        labelStatusProcessingImage = {
            intl.formatMessage({
                id: 'doka-label-status-processing-image',
                defaultMessage: 'Processing image…',
            })
        }
        labelColorBrightness = {
            intl.formatMessage({
                id: 'doka-label-brightness',
                defaultMessage: 'Brightness',
            })
        }
        labelColorContrast = {
            intl.formatMessage({
                id: 'doka-label-contrast',
                defaultMessage: 'Contrast',
            })
        }
        labelColorExposure = {
            intl.formatMessage({
                id: 'doka-label-exposure',
                defaultMessage: 'Exposure',
            })
        }
        labelColorSaturation = {
            intl.formatMessage({
                id: 'doka-label-saturation',
                defaultMessage: 'Saturation',
            })
        }
        labelResizeWidth = {
            intl.formatMessage({
                id: 'doka-label-resize-width',
                defaultMessage: 'Width',
            })
        }
        labelResizeHeight = {
            intl.formatMessage({
                id: 'doka-label-resize-height',
                defaultMessage: 'Height',
            })
        }
        labelResizeApplyChanges = {
            intl.formatMessage({
                id: 'doka-label-resize-apply',
                defaultMessage: 'Apply',
            })
        }
        labelCropInstructionZoom = {
            intl.formatMessage({
                id: 'doka-label-crop-zoom-instruction',
                defaultMessage: 'Zoom in and out with your scroll wheel or touchpad.',
            })
        }
        labelButtonCropZoom = {
            intl.formatMessage({
                id: 'doka-label-button-crop-zoom',
                defaultMessage: 'Zoom',
            })
        }
        labelButtonCropRotateLeft = {
            intl.formatMessage({
                id: 'doka-label-button-crop-rotate-left',
                defaultMessage: 'Rotate left',
            })
        }
        labelButtonCropRotateRight = {
            intl.formatMessage({
                id: 'doka-label-button-crop-rotate-right',
                defaultMessage: 'Rotate right',
            })
        }
        labelButtonCropRotateCenter = {
            intl.formatMessage({
                id: 'doka-label-button-crop-rotate-center',
                defaultMessage: 'Center rotation',
            })
        }
        labelButtonCropFlipHorizontal = {
            intl.formatMessage({
                id: 'doka-label-button-crop-flip-horizontal',
                defaultMessage: 'Flip horizontal',
            })
        }
        labelButtonCropFlipVertical = {
            intl.formatMessage({
                id: 'doka-label-button-crop-flip-vertical',
                defaultMessage: 'Flip vertical',
            })
        }
        labelButtonCropAspectRatio = {
            intl.formatMessage({
                id: 'doka-label-button-crop-aspect-ratio',
                defaultMessage: 'Aspect ratio',
            })
        }
        labelButtonCropToggleLimit = {
            intl.formatMessage({
                id: 'doka-label-button-crop-toggle-limit',
                defaultMessage: 'Crop selection',
            })
        }
        labelButtonCropToggleLimitEnable = {
            intl.formatMessage({
                id: 'doka-label-button-crop-toggle-limit-enable',
                defaultMessage: 'Limit to image',
            })
        }
        labelButtonCropToggleLimitDisable = {
            intl.formatMessage({
                id: 'doka-label-button-crop-toggle-limit-disable',
                defaultMessage: 'Select outside image',
            })
        }
        labelButtonUtilCrop = {
            intl.formatMessage({
                id: 'doka-label-button-util-crop',
                defaultMessage: 'Crop',
            })
        }
        labelButtonUtilFilter = {
            intl.formatMessage({
                id: 'doka-label-button-util-filter',
                defaultMessage: 'Filter',
            })
        }
        labelButtonUtilColor = {
            intl.formatMessage({
                id: 'doka-label-button-util-color',
                defaultMessage: 'Colors',
            })
        }
        labelButtonUtilResize = {
            intl.formatMessage({
                id: 'doka-label-button-util-resize',
                defaultMessage: 'Resize',
            })
        }
        labelButtonUtilMarkup = {
            intl.formatMessage({
                id: 'doka-label-button-util-markup',
                defaultMessage: 'Markup',
            })
        }
        labelMarkupTypeRectangle = {
            intl.formatMessage({
                id: 'doka-label-markup-type-rectangle',
                defaultMessage: 'Rectangle',
            })
        }
        labelMarkupTypeEllipse = {
            intl.formatMessage({
                id: 'doka-label-markup-type-ellipse',
                defaultMessage: 'Circle',
            })
        }
        labelMarkupTypeText = {
            intl.formatMessage({
                id: 'doka-label-markup-type-text',
                defaultMessage: 'Text',
            })
        }
        labelMarkupTypeLine = {
            intl.formatMessage({
                id: 'doka-label-markup-type-type-line',
                defaultMessage: 'Arrow',
            })
        }
        labelMarkupSelectFontSize = {
            intl.formatMessage({
                id: 'doka-label-markup-select-font-size',
                defaultMessage: 'Size',
            })
        }
        labelMarkupSelectFontFamily = {
            intl.formatMessage({
                id: 'doka-label-markup-select-font-family',
                defaultMessage: 'Font',
            })
        }
        labelMarkupSelectLineDecoration = {
            intl.formatMessage({
                id: 'doka-label-markup-select-decoration',
                defaultMessage: 'Decoration',
            })
        }
        labelMarkupSelectLineStyle = {
            intl.formatMessage({
                id: 'doka-label-markup-select-line-style',
                defaultMessage: 'Style',
            })
        }
        labelMarkupSelectShapeStyle = {
            intl.formatMessage({
                id: 'doka-label-markup-select-shape-style',
                defaultMessage: 'Style',
            })
        }
        labelMarkupRemoveShape = {
            intl.formatMessage({
                id: 'doka-label-markup-remove-shape',
                defaultMessage: 'Remove',
            })
        }
        labelMarkupToolSelect = {
            intl.formatMessage({
                id: 'doka-label-markup-tool-select',
                defaultMessage: 'Select',
            })
        }
        labelMarkupToolDraw = {
            intl.formatMessage({
                id: 'doka-label-markup-tool-draw',
                defaultMessage: 'Draw',
            })
        }
        labelMarkupToolLine = {
            intl.formatMessage({
                id: 'doka-label-markup-tool-line',
                defaultMessage: 'Arrow',
            })
        }
        labelMarkupToolText = {
            intl.formatMessage({
                id: 'doka-label-markup-tool-text',
                defaultMessage: 'Text',
            })
        }
        labelMarkupToolRect = {
            intl.formatMessage({
                id: 'doka-label-markup-tool-rect',
                defaultMessage: 'Square',
            })
        }
        labelMarkupToolEllipse = {
            intl.formatMessage({
                id: 'doka-label-markup-tool-ellipse',
                defaultMessage: 'Circle',
            })
        }
        cropAspectRatio = {
            calculateAspectRatioForDoka(product.id, product.productData.orientation)
        }
        cropShowSize
        outputFit = "cover"
        outputType = "image/jpeg"
        outputQuality = { 100 }
        outputStripImageHead = { true }
        outputData
        outputFile
        onConfirm={async (output) => {
                let colorUsed = false;
                let filterUsed = false;
                let markupUsed = false;
                let cropUsed = false;
                const data = output?.data;

                if (data?.color) {
                    const color = data.color;
                    console.log(color);
                    if (color?.brightness?.value !== 0) {
                        colorUsed = true;
                    }
                    if (color?.contrast?.value !== 1) {
                        colorUsed = true;
                    }
                    if (color?.exposure?.value !== 1) {
                        colorUsed = true;
                    }
                    if (color?.saturation?.value !== 1) {
                        colorUsed = true;
                    }
                }

                if (data?.filter) {
                    filterUsed = true;
                }
                if (data?.markup) {
                    const markup = output.data.markup;
                    if (markup && markup?.length > 0) {
                        markupUsed = true;
                    }
                }
                if (data?.crop) {
                    const { crop } = data;
                    if (crop?.center) {
                        const { x, y } = crop.center;
                        if (x !== 0.5 || y !== 0.5) {
                            cropUsed = true;
                        }
                    }
                    if (crop?.flip) {
                        const { horizontal, vertical } = crop.flip;
                        if (horizontal || vertical) {
                            cropUsed = true;
                        }
                    }
                    if (crop?.rotation !== 0) {
                        cropUsed = true;
                    }
                    if (crop?.zoom !== 1) {
                        cropUsed = true;
                    }
                }

                return dispatch(await backFromEdit(output,{crop:cropUsed,markup:markupUsed,color:colorUsed,filter:filterUsed}));
            }}
        onCancel={() => dispatch(cancelEdit())}
    />;
}
