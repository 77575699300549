import React from 'react';
import theme from '../theme/theme';

export function UploadIcon() {
    const color = theme.colors.gray['200'];
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="73" fill={color} viewBox="0 0 80 73">
            <g fill="#A2AFB7" opacity="0.7">
                <path
                    fillOpacity="0.9"
                    d="M64.608 20.122C61.906 6.58 48.725-2.212 35.168.487a25.018 25.018 0 00-19.651 19.62C5.905 21.214-.99 29.896.117 39.5 1.136 48.338 8.629 55.006 17.535 55h12.514a2.502 2.502 0 100-5.001H17.535c-6.912-.041-12.482-5.672-12.442-12.577.041-6.904 5.677-12.469 12.59-12.429a2.502 2.502 0 002.487-2.225C21.441 11.793 31.377 3.926 42.362 5.195c9.236 1.068 16.522 8.346 17.59 17.573a2.652 2.652 0 002.636 2.225c6.912 0 12.515 5.598 12.515 12.503S69.5 50 62.588 50H50.073a2.502 2.502 0 100 5.001h12.515C72.264 54.94 80.06 47.055 80 37.388c-.055-8.803-6.646-16.196-15.392-17.265z"
                ></path>
                <path
                    fillOpacity="0.5"
                    d="M48.746 45.05a2.436 2.436 0 003.499.062 2.57 2.57 0 000-3.639l-10.493-10.73a2.436 2.436 0 00-3.5-.005c0 .002-.003.003-.004.005l-10.493 10.73a2.57 2.57 0 00-.06 3.577 2.437 2.437 0 003.559 0l6.272-6.415V70.47C37.526 71.868 38.634 73 40 73s2.474-1.132 2.474-2.53V38.636l6.272 6.415z"
                ></path>
            </g>
        </svg>
    );
}
