import { Box } from '../design/Box';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productMaterialChange } from '../../redux/productReducer';

import acrylic_thumbnail from '../design/images/materials/acrylic_thumbnail.svg';
import canvas_thumbnail from '../design/images/materials/canvas_thumbnail.svg';
import metal_thumbnail from '../design/images/materials/metal_thumbnail.svg';
import { useIntl } from 'react-intl';
import { handleTealiumClick } from '../../utils/serverUtil';

const MaterialBox = ({ active, type, src, ...props }) => {
    const intl = useIntl();

    return (
        <Box {...props} as="button" border="none" background="none" outline={0} id={type}>
            <Box
                fontSize="15px"
                color={active ? 'blurb_cyan.25' : '#272930'}
                fontWeight={active ? 'bold' : 'normal'}
                textAlign="center"
            >
                {intl.formatMessage({ id: type })}
            </Box>
            <Box
                as="img"
                maxWidth="67px"
                maxHeight="56px"
                src={src}
                border={active ? '2px solid' : '1px solid'}
                borderColor={active ? 'blurb_cyan.25' : '#DBDBDB'}
            />
        </Box>
    );
};

export const MaterialScreenFooter = ({ show }) => {
    const dispatch = useDispatch();
    const product = useSelector(state => state.product);
    const intl = useIntl();

    const materialTypes = [
        { type: 'product-name-canvas', finish_type: 'canvas_matte', src: canvas_thumbnail },
        { type: 'product-name-acrylic', finish_type: 'acrylic_gloss', src: acrylic_thumbnail },
        { type: 'product-name-metal', finish_type: 'metal_gloss', src: metal_thumbnail },
    ];
    return (
        <Box
            display={show ? 'flex' : 'none'}
            justifyContent={['space-between', 'space-evenly', 'space-around', 'space-around']}
        >
            {materialTypes.map((mt, i) => (
                <MaterialBox
                    active={product.productData.finish_type === mt.finish_type}
                    key={`materialBox${i}`}
                    src={mt.src}
                    type={mt.type}
                    onClick={() => {
                        handleTealiumClick('mt-change-' + intl.formatMessage({ id: mt.type }));
                        return dispatch(productMaterialChange(mt.finish_type));
                    }}
                />
            ))}
        </Box>
    );
};
