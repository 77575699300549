import { Box } from '../../../components/design/Box';
import React, { useEffect, useState } from 'react';

import {
    ForgotPasswordForm,
    ForgotPasswordInfo,
    ForgotPasswordInput,
    ForgotPasswordTitle,
    ResetLinkButton,
} from './ForgotPasswordComponents';

import { ErrorLabel } from '../AuthScreenComponents';

import { useFormValidation } from '../../../components/hooks/useFormValidation';
import { useFormState } from '../../../components/hooks/useFormState';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { ValidationResult } from '../../../components/hooks/ValidationResult';
import { getBlurbApiError, handleTealiumClick } from '../../../utils/serverUtil';
import { AuthScreenWrapper } from '../AuthScreenWrapper';

export const ForgotPassword = () => {
    const intl = useIntl();
    const [requestSent, setRequestSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const { handleFormChange, formState } = useFormState({
        username: '',
    });

    const { errorState, validate, setError, setServerError, setServerErrors } = useFormValidation(formState, {
        username: () => ValidationResult.success(),
    });

    const { username: userNameError } = errorState;
    const handleSubmit = async () => {
        const validationSuccess = validate();
        if (!validationSuccess) {
            return;
        }
        const { username } = formState;
        setLoading(true);

        const forgotPasswordData = new FormData();
        forgotPasswordData.append('username', username);

        try {
            const forgotPasswordResponse = await axios.post(
                `${process.env.REACT_APP_HOST}/my/account/forgot_password.json`,
                forgotPasswordData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            if (forgotPasswordResponse?.data?.error) {
                setLoading(false);
                setError('username', ValidationResult.failure('username', forgotPasswordResponse?.data.error));
            } else {
                setRequestSent(true);
            }
        } catch (e) {
            setLoading(false);
            const { blurbApiMessage, serverErrors } = getBlurbApiError(e, intl);
            if (serverErrors) {
                setServerErrors(serverErrors);
            } else {
                setServerError(blurbApiMessage);
            }
        }
    };


    return (
        <AuthScreenWrapper>
            {requestSent ? (
                <Box width="90%" marginTop="5%">
                    <ForgotPasswordTitle>
                        {intl.formatMessage({ id: 'reset-password', defaultMessage: 'RESET PASSWORD' })}
                    </ForgotPasswordTitle>
                    <ForgotPasswordInfo>
                        {intl.formatMessage({
                            id: 'a-password-assistance-email-is-en-route',
                            defaultMessage:
                                'A password assistance email is en route. For your security, the link in the message will only be valid for the next 24 hours.',
                        })}
                    </ForgotPasswordInfo>
                </Box>
            ) : (
                <ForgotPasswordForm>
                    <ForgotPasswordTitle>
                        {intl.formatMessage({ id: 'reset-password', defaultMessage: 'RESET PASSWORD' })}
                    </ForgotPasswordTitle>
                    <ForgotPasswordInfo>
                        {intl.formatMessage({
                            id: 'enter-the-email-address-associated-with-your-account',
                            defaultMessage:
                                "Enter the email address associated with your account, and we'll email you a link to reset your password",
                        })}
                    </ForgotPasswordInfo>
                    <ForgotPasswordInput
                        id={"forgot-password-username"}
                        placeholder={intl.formatMessage({
                            id: 'email-or-username-inputbox-hint',
                            defaultMessage: 'email or username',
                        })}
                        name={'username'}
                        value={formState?.username}
                        onChange={handleFormChange}
                        error={userNameError?.errorMessage}
                    />
                    {userNameError && (
                        <Box>
                            <ErrorLabel>{userNameError.errorMessage}</ErrorLabel>
                            <br />
                        </Box>
                    )}
                    <ResetLinkButton
                        disabled={loading}
                        id={"forgot-password-button"}
                        onClick={() => {
                            handleTealiumClick(intl.formatMessage({ id: 'send-reset-link-button', defaultMessage: 'SEND RESET LINK' }))
                            if (loading) {
                                return;
                            }
                            return handleSubmit();
                        }}
                    >
                        {' '}
                        {intl.formatMessage({ id: 'send-reset-link-button', defaultMessage: 'SEND RESET LINK' })}{' '}
                    </ResetLinkButton>
                </ForgotPasswordForm>
            )}
        </AuthScreenWrapper>
    );
};
