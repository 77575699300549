import React from 'react';
import { Box } from '../../components/design/Box';

export default {
    Base: ({ ...props }) => <Box 
        {...props}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        height="60%"
        position="relative"
        marginTop="2%"
    />,

    Title: ({ ...props }) => <Box 
        {...props}
        fontSize="20px"
        lineHeight="23px"
        letterSpacing="0.107692px"
        color="#272930"
        fontWeight="700"
        marginBottom={[ "2%", "1%" ]}
    />,

    ImageContainer: ({ ...props }) => <Box 
        {...props}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        width="100%"
        maxHeight="80%"
        position="relative"
        marginBottom={[ "8%", "3%" ]}
    />,

    Description: ({ ...props }) => <Box 
        {...props}
        fontWeight="normal"
        fontSize="14px"
        lineHeight="17px"
        letterSpacing="0.0753846px"
        color="#4a4a4a"
        textAlign="left"
        marginBottom={[ "8%", "3%" ]}
    />,

    BackButton: ({ display = "flex", ...props }) => <Box 
        {...props}
        cursor="pointer"
        display={display}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
    />,

    BackButtonText: ({ ...props }) => <Box 
        {...props}
        textDecoration="underline" 
        color="blurb_cyan.25" 
        fontWeight="700" 
        fontSize="12px"
    />,

    BackButtonBox: ({ ...props }) => <Box 
        {...props}
        display={[ "none", "none", "flex" ]}
        flexDirection="row"
        width="100%"
        justifyContent="flex-start"
        marginLeft="12px"
        marginTop="20px"
    />,
}