import React from 'react';
import { Box } from '../../../components/design/Box';
import { Input } from '../../../components/design/input/Input';

export const ForgotPasswordContainer = ({ ...props }) => (
    <Box {...props} width="100%" display="flex" flexDirection="row" justifyContent="center" />
);

export const ForgotPasswordForm = ({ ...props }) => <Box {...props} width="90%" marginTop="5%" />;

export const ForgotPasswordTitle = ({ ...props }) => (
    <Box
        {...props}
        fontSize="18px"
        fontWeight="700"
        letterSpacing="1px"
        lineHeight="1.063"
        textTransform="uppercase"
        color="#333"
    />
);

export const ForgotPasswordInfo = ({ ...props }) => (
    <Box {...props} fontSize="14px" textAlign="left" color="#595959" lineHeight="1.4" marginBottom="20px" />
);

export const ForgotPasswordInput = ({ error, ...props }) => (
    <Input
        {...props}
        as="input"
        backgroundColor="whitesmoke"
        width={['100%', '100%', '55%']}
        border="2px solid"
        borderColor={error ? '#f73c51!important' : '#000!important'}
        display="block"
    />
);

export const ResetLinkButton = ({ ...props }) => (
    <Box
        {...props}
        marginBottom="20px"
        marginTop="20px"
        display="inline-block"
        lineHeight="1"
        padding="7px 28px 6px"
        textAlign="center"
        cursor={"pointer"}
        fontWeight="700"
        textTransform="uppercase"
        letterSpacing="0.05em"
        textDecoration="none"
        border="2px solid #000"
        color="#222"
        boxSizing="border-box"
        wordSpacin="normal"
        textIndent="0px"
        textShadow="none"
        textRendering="auto"
        bg="#f0f0f0"
    />
);
