import React from 'react';
import PseudoBox from './PseudoBox';

export const ButtonStyle = ({ buttonColor, large, disabled, fontSize, borderRadius="4px 3px 3px 3px", disabledStyle,  padding="13px 17px 11px 17px", noHover, ...props }) => {
    const appliedColor = buttonColor ? buttonColor : 'blurb_cyan.25';
    if(disabledStyle) disabled = true;
    const hover = !disabled && !noHover && { borderColor: 'black', bg: 'white', color: 'black' };

    return (
        <PseudoBox
            {...props}
            textTransform="uppercase"
            border="1px solid"
            borderColor={appliedColor}
            borderRadius={borderRadius}
            bg={disabled ? 'blurb_gray.900' : appliedColor}
            color={disabled ? 'blurb_gray.600' : 'white'}
            fontFamily={"blurb"}
            borderColor={disabled ? 'blurb_gray.900' : appliedColor}
            fontSize={fontSize || '13px'}
            padding={padding}
            letterSpacing="blurb"
            fontWeight="bold"
            lineHeight="normal"
            textAlign="center"
            transition="all .2s linear"
            outline="none"
            boxSizing="border-box"
            textDecoration="none"
            textRendering="auto"
            textShadow="none"
            _hover={hover}
        />
    );
};

export const Button = ({ children, ...props }) => (
    <ButtonStyle as={'button'} {...props}>
        {children}
    </ButtonStyle>
);
