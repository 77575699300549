import React from 'react';
import PseudoBox from './PseudoBox';

export const ButtonStyle = ({ buttonColor, large, disabled, fontSize, noHover, ...props }) => {
    const appliedColor = buttonColor ? buttonColor : '#F06961';
    const hover = !disabled && !noHover && { borderColor: appliedColor, bg: 'white', color: appliedColor }
    return (
        <PseudoBox
            {...props}
            textTransform="uppercase"
            border="1px solid #00c0be"
            bg={disabled ? 'blurb_gray.900' : appliedColor}
            color={disabled ? 'blurb_gray.600' : 'white'}
            borderColor={disabled ? 'blurb_gray.900' : appliedColor}
            fontSize={fontSize || '13px'}
            padding="13px 17px 11px 17px"
            letterSpacing="blurb"
            fontWeight="bold"
            lineHeight="normal"
            textAlign="center"
            transition="all .2s linear"
            outline="none"
            boxSizing="border-box"
            textDecoration="none"
            textRendering="auto"
            textShadow="none"
            _hover={hover}
        />
    );
};

export const ErrorButton = ({ children, ...props }) => (
    <ButtonStyle as={'button'} {...props}>
        {children}
    </ButtonStyle>
);
