import React from 'react';

function UploadProcessingIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none" viewBox="0 0 19 26">
            <path fill="#8CE0FF" d="M3 23a4 4 0 014-4h5a4 4 0 014 4v2H3v-2z"></path>
            <path
                fill="#00AEEF"
                fillRule="evenodd"
                d="M16.87 4.34V1.3h1.466c.367 0 .664-.29.664-.65 0-.36-.297-.65-.664-.65H.664A.657.657 0 000 .65c0 .36.297.65.664.65H2.13v3.04a6.864 6.864 0 002.154 4.945l4.246 4.02-4.06 3.74a6.83 6.83 0 00-2.207 5.019V24.7h-1.6a.657.657 0 00-.664.65c0 .36.297.65.664.65h17.672c.367 0 .664-.29.664-.65 0-.36-.297-.65-.664-.65h-1.4v-2.588a6.839 6.839 0 00-2.292-5.08l-4.173-3.734 4.245-4.013A6.864 6.864 0 0016.87 4.34zm-1.262 17.772V24.7H3.592v-2.636a5.54 5.54 0 011.792-4.072l4.126-3.795 4.24 3.794a5.547 5.547 0 011.858 4.121zm-10.4-13.76L9.5 12.406l4.291-4.056a5.574 5.574 0 001.75-4.01V1.3H3.46v3.04a5.573 5.573 0 001.75 4.011z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default UploadProcessingIcon;
