import { Box } from '../design/Box';
import { switchEditScreen } from '../../redux/editScreenReducer';
import { productMaterialChange } from '../../redux/productReducer';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageScreenFooter } from './imageScreenFooter';
import { MaterialScreenFooter } from './materialScreenFooter';
import { SizeScreenFooter } from './sizeScreenFooter';
import CameraIcon from '../design/icons/CameraIcon';
import RulerIcon from '../design/icons/RulerIcons';
import MaterialIcon from '../design/icons/MaterialIcon';
import InfoIcon from '../design/icons/InfoIcon';

import CPNT from './footerComponents';
import { useIntl } from 'react-intl';
import ctEventsService from '../../utils/ctEvents/CTEventsService';
import Cookies from 'js-cookie';
import { handleTealiumClick } from '../../utils/serverUtil';

export const getIcon = (editScreens, screen) => {
    const width = '27px';
    const height = '27px';
    switch (screen) {
        case editScreens.image:
            return <CameraIcon width={width} height={height} />;
        case editScreens.material:
            return <MaterialIcon width={width} height={height} />;
        case editScreens.size:
            return <RulerIcon width={width} height={height} />;
        default:
            return;
    }
};

export const Footer = ({ showInfo, setShowInfo }) => {
    const intl = useIntl();
    const { currentEditScreen, editScreens } = useSelector(state => state.editScreen);
    const upload = useSelector(state => state.upload);
    const dispatch = useDispatch();

    const getTitle = screen => {
        switch (screen) {
            case editScreens.image:
                return intl.formatMessage({ id: 'tab-select-image-title', defaultMessage: 'Select image' });
            case editScreens.material:
                return intl.formatMessage({ id: 'tab-select-material-title', defaultMessage: 'Select material' });
            case editScreens.size:
                return intl.formatMessage({ id: 'tab-select-size-title', defaultMessage: 'Select size' });
            default:
                throw `invalid screen ${screen}`;
        }
    };

    const getIconName = screen => {
        switch (screen) {
            case editScreens.image:
                return intl.formatMessage({ id: 'image-icon-title', defaultMessage: 'image' });
            case editScreens.material:
                return intl.formatMessage({ id: 'material-icon-title', defaultMessage: 'material' });
            case editScreens.size:
                return intl.formatMessage({ id: 'size-icon-title', defaultMessage: 'size' });
            default:
                return;
        }
    };

    const title = getTitle(currentEditScreen);

    const InfoIconButton = () => (
        <Box>
            <CPNT.InfoButton
                onClick={() => {
                    handleTealiumClick('material info icon');
                    ctEventsService.sendMaterialHelp(upload?.project_id, 'Clicked on material info');
                    return setShowInfo(true);
                }}
            >
                <InfoIcon id={"material-info-icon"} width={18} height={18} color="blurb_cyan.25" />
            </CPNT.InfoButton>
        </Box>
    );

    return (
        <CPNT.Base>
            <CPNT.Column>
                <CPNT.Screen>
                    <Box display="flex" paddingBottom={currentEditScreen === editScreens.material ? '0px' : '6px'}>
                        <Box fontSize="14px">{title}</Box>
                        <Box id={'material-info-icon-box'} paddingX="5px">
                            {currentEditScreen === editScreens.material && <InfoIconButton />}
                        </Box>
                    </Box>
                    <CPNT.SelectedScreen>
                        <ImageScreenFooter show={currentEditScreen === editScreens.image} />
                        <MaterialScreenFooter show={currentEditScreen === editScreens.material} />
                        <SizeScreenFooter show={currentEditScreen === editScreens.size} />
                    </CPNT.SelectedScreen>
                </CPNT.Screen>
                <CPNT.IconsGrid>
                    {Object.keys(editScreens).map(screen => {
                        const active = screen === currentEditScreen;
                        const style = {
                            bg: active ? 'blurb_gray.50' : '',
                            borderColor: active ? 'blurb_gray.100' : 'blurb_gray.300',
                        };
                        return (
                            <CPNT.TabBox id={`screen-${screen}`} key={`screen-${screen}`}>
                                <CPNT.IconBox
                                    {...style}
                                    key={screen}
                                    onClick={() => {
                                        handleTealiumClick(getIconName(screen));
                                        return dispatch(switchEditScreen(screen));
                                    }}
                                >
                                    {getIcon(editScreens, screen)}
                                    <CPNT.IconName key={screen} fontWeight={active ? 'semibold' : ''}>
                                        {getIconName(screen)}
                                    </CPNT.IconName>
                                </CPNT.IconBox>
                            </CPNT.TabBox>
                        );
                    })}
                </CPNT.IconsGrid>
            </CPNT.Column>
        </CPNT.Base>
    );
};
