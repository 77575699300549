import { OrientationDetect, OrientationLandscape, OrientationPortrait } from '../redux/productReducer';
import { MINIMUM_DPI } from './Constants';
import { findInvert, findLowestProduct, findProductById, findProductId, getAllProductIds } from './productRepository';
import allProducts from './allProducts';
import {useIntl} from 'react-intl'
import {renderInCm} from "../index";

function calculateDPIByProductAndSize(product, size, upload, orientation) {
    const id = findProductId(product, size);
    return calculateDPI(id, upload.imageInfo.width, upload.imageInfo.height, orientation);
}

function calculateAspectRatioForDoka(id, orientation) {
    const productData = findProductById(id);
    return  productData.rendered_rect.height/ productData.rendered_rect.width;
}
export function calculateAspectRatio(id) {
    const productData = findProductById(id);
    return productData.rendered_rect.width / productData.rendered_rect.height;
}

export function getProductFinishTitle(intl, product) {

    const { finish_type, orientation, trim_text } = product;
    let name = '';
    switch (finish_type) {
        case 'canvas_matte':
            //name = "Canvas";
            name = intl.formatMessage({id:'product-name-canvas', defaultMessage:'Canvas'});
            break;
        case 'acrylic_gloss':
            //name = "Acrylic";
            name = intl.formatMessage({id:'product-name-acrylic', defaultMessage:'Acrylic'});
            break;
        case 'metal_gloss':
            //name = "Metal";
            name = intl.formatMessage({id:'product-name-metal', defaultMessage:'Metal'});
            break;
        default:
            name = '';
    }
    return name;
}
export function getProductOrientationTitle(intl, product,addMeasure) {
    const { orientation, trim_text, trim_text_cm } = product;
    const unitInCm = renderInCm(intl.locale);
    const trimTextPreformat = unitInCm ? trim_text_cm : trim_text;
    let trimText = trimTextPreformat;
    if (addMeasure) {
        const splited = trimTextPreformat.split('x');
        if (splited && splited.length === 2) {
            const firstPart = splited[0];
            const secondPart = splited[1];
            if (unitInCm) {
                trimText = firstPart + 'cm' + ' x ' + secondPart + 'cm';
            } else {
                trimText = firstPart + 'in' + ' x ' + secondPart + 'in';
            }
        }
    }


    switch (orientation) {
        case "landscape": return trimText + " " + intl.formatMessage({id:'orientation_landscape', defaultMessage:'Portrait'});
        case "portrait": return trimText + " " + intl.formatMessage({id:'orientation_portrait', defaultMessage:'Landscape'});
        case "square": return trimText + " " + intl.formatMessage({id:'orientation_square', defaultMessage:'Square'});
        default: throw `invalid orientation ${orientation}`;
    }
}
const getProductTitle = (intl, product,addMeasure) => {
    const finishTitle = getProductFinishTitle(intl, product);
    const orientationTitle = getProductOrientationTitle(intl, product,addMeasure);
    return `${finishTitle} ${orientationTitle}`;
};


const getDimensions = (productData, orientation) => {
    return { width: productData.rendered_rect.width, height: productData.rendered_rect.height };
};

const calculateDPI = (productId, imageWidth, imageHeight, orientation) => {
    const productData = findProductById(productId);
    const dimensions = getDimensions(productData, orientation);

    const dpiX = Math.ceil((imageWidth / dimensions.width) * 72.0);
    const dpiY = Math.ceil((imageHeight / dimensions.height) * 72.0);
    return Math.min(dpiX, dpiY);
};

const getMinimumResolution = (productId, orientation) => {
    const product = findProductById(productId);
    const dimensions = getDimensions(product, orientation);

    return {
        width: Math.floor((dimensions.width / 72.0) * MINIMUM_DPI),
        height: Math.floor((dimensions.height / 72.0) * MINIMUM_DPI),
    };
};

const getBleedPercentages = (productId, orientation) => {
    const product = findProductById(productId);
    const dimensions = getDimensions(product, orientation);
    const { width: bleedWidth, height: bleedHeight } = product.bleed;
    return { width: bleedWidth / dimensions.width, height: bleedHeight / dimensions.height };
};
// eslint-disable-next-line
const getSafeZonePercentages = (productId, orientation) => {
    const product = findProductById(productId);
    const dimensions = getDimensions(product, orientation);
    const { width: bleedWidth, height: bleedHeight } = product.bleed;
    const { width: safeWidth, height: safeHeight } = product.safe_area;

    return {
        width: (bleedWidth + safeWidth) / dimensions.width,
        height: (bleedHeight + safeHeight) / dimensions.height,
    };
};

function findBestMatchingProduct(upload, product, finishType) {
    const imageAspect = 1 / upload.imageInfo.aspect;
    let orientation;
    let resultProductData = product;

    if (product.orientation === OrientationDetect) {
        if (upload.imageInfo.width <= upload.imageInfo.height) orientation = OrientationPortrait;
        else orientation = OrientationLandscape;

        let bestAspectRatio = 99999;
        let bestProduct = findLowestProduct(finishType, orientation);
        let bestDPI = calculateDPI(bestProduct.id, upload.imageInfo.width, upload.imageInfo.height, orientation);

        for (const productId of getAllProductIds()) {
            const aspectDifference = Math.abs(calculateAspectRatioForDoka(productId, orientation) - imageAspect);
            const dpi = calculateDPI(productId, upload.imageInfo.width, upload.imageInfo.height, orientation);

            // if (aspectDifference <= bestAspectRatio) {
            //     if ((aspectDifference < bestAspectRatio || (aspectDifference === bestAspectRatio && dpi > bestDPI)) && dpi > MINIMUM_DPI) {
            //         bestProductId = productId;
            //         bestAspectRatio = aspectDifference;
            //         bestDPI = dpi;
            //     }
            // }
        }
        for (const productData of allProducts) {
            if (productData.orientation !== orientation) continue;
            if (productData.finish_type !== finishType) continue;
            const dpi = calculateDPI(productData.id, upload.imageInfo.width, upload.imageInfo.height, orientation);
            if (dpi < bestDPI && dpi > MINIMUM_DPI) {
                bestDPI = dpi;
                bestProduct = productData;
            }
        }

        // const bestProductData = findProductById(bestProductId);
        // resultProductData = bestProductData;
        // if (bestProductData.orientation !== orientation) {
        //     const invert = findInvert(bestProductData);
        //     resultProductData = invert;
        // }
        return bestProduct;
    }

    return resultProductData;
}

function isImageResolutionInvalid(product, upload) {
    const minimumResolution = getMinimumResolution(product.id, product.orientation);
    return minimumResolution.width > upload.imageInfo.width || minimumResolution.height > upload.imageInfo.height;
}

function validateResolution(
    upload,
    passedProduct,
    existingValidation,
    initialValidation = false,
    desiredFinishType = null
) {
    let aspect = 0;
    const imageAspect = 1 / upload.imageInfo.aspect;

    let finishType = ['acrylic_gloss', 'canvas_matte', 'metal_gloss'].includes(desiredFinishType)
        ? desiredFinishType
        : 'acrylic_gloss';

    let product = findBestMatchingProduct(upload, passedProduct, finishType);

    let dpi = calculateDPI(product.id, upload.imageInfo.width, upload.imageInfo.height, product.orientation);

    // If our product has low dpi or resolution, we chose the smallest product
    if ((dpi < MINIMUM_DPI || isImageResolutionInvalid(product, upload)) && initialValidation) {
        product = findLowestProduct(product.finish_type, product.orientation);
    }

    dpi = calculateDPI(product.id, upload.imageInfo.width, upload.imageInfo.height, product.orientation);

    const productAspectRatio = calculateAspectRatioForDoka(product.id, product.orientation);

    const aspectDifference = productAspectRatio - imageAspect;

    if (Math.abs(aspectDifference) < 0.01) aspect = 0;
    else if (aspectDifference < 0) aspect = 1;
    else aspect = -1;

    const { issues, success } = existingValidation;

    let dpiError = {};
    let resolutionError = {};

    if (aspect !== 0) {
    } else {
    }

    const isResolutionInvalid = isImageResolutionInvalid(product, upload) || dpi < MINIMUM_DPI;
    if (isResolutionInvalid) {
        resolutionError = {
            titleId: 'low-resolution-warning-title',
            modalTitleId: 'low-resolution-warning-modal-title',
            modalDescriptionId: 'low-resolution-warning-modal-description',
            descriptionId: 'low-resolution-warning-description',
        };
        issues.imageResolution = resolutionError;
    } else {
        issues.imageResolution = null;
    }

    // if (dpi < MINIMUM_DPI && !isResolutionInvalid) {
    //     dpiError = { title: 'Dpi is to low', description: 'Image may appear blurry' };
    //     issues.dpi = dpiError;
    // } else {
    //     issues.dpi = null;
    // }

    return {
        product: product,
        validations: {
            issues,
            success,
        },
    };
}

export {
    calculateDPIByProductAndSize,
    calculateAspectRatioForDoka,
    getBleedPercentages,
    getProductTitle,
    validateResolution,
};
