import React, { useState } from 'react';
import { Box } from '../../../components/design/Box';
import show from './show.png';
import hide from './hide.png';
import { AuthScreenWrapper } from '../AuthScreenWrapper';

import {
    AuthButton,
    AuthFooter,
    AuthInfo,
    AuthInput,
    AuthPasswordLabel,
    AuthTitle,
    DescriptionLink,
    ShowHidePassword,
} from '../AuthScreenComponents';

import { useFormValidation } from '../../../components/hooks/useFormValidation';
import { validateEmail, validatePassword, validateUserName } from '../auth-validation';
import { useFormState } from '../../../components/hooks/useFormState';
import { getBlurbApiError, handleTealiumClick } from '../../../utils/serverUtil';
import apiClient from '../../../components/apiClient';
import { useHistory, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import uppy from '../../../redux/uppy';
import { addFilesToUpload } from '../../../redux/fileReducer';
import { getProductTitle } from '../../../utils/productService';
import { assignSession, validateSession } from '../../../redux/apiReducer';

export const SignUp = () => {
    const intl = useIntl();
    const { handleFormChange, formState } = useFormState({
        email: '',
        username: '',
        password: '',
    });

    const { errorState, validate, serverError, setServerError, setServerErrors } = useFormValidation(formState, {
        email: validateEmail.bind(this, intl),
        username: validateUserName.bind(this, intl),
        password: validatePassword.bind(this, intl),
    });

    const { username: userNameError, password: passwordError, email: emailError } = errorState;
    let history = useHistory();
    const upload = useSelector(state => state.upload);
    const product = useSelector(state => state.product);
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async event => {
        const validationSuccess = validate();
        if (!validationSuccess) {
            return;
        }

        const { email, username, password } = formState;
        const signUpFormData = new FormData();
        signUpFormData.append('username', username);
        signUpFormData.append('password', password);
        signUpFormData.append('email', email);
        signUpFormData.append('email_signup_source', 'standard_wallart');
        signUpFormData.append('want_newsletter', 'true');
        signUpFormData.append('api_key', process.env.REACT_APP_API_KEY);
        signUpFormData.append('locale', intl.locale);

        try {
            setLoading(true);

            const response = await apiClient.post(`/v1_1/users.json`, signUpFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            const { success, data } = await validateSession(response?.data?.web_session_id);
            if (success && upload.imageInfo) {
                setLoading(false);
                if (window?.utag?.link) {
                    window.utag.link({
                        event_name: 'sign-up-success',
                        event_category: 'sign-up-success',
                        event_action: 'sign-up-success',
                        event_label: 'success-result',
                    });
                }
                await dispatch(assignSession(data));
                await history.push('/upload', {
                    from: '/auth/sign-up,',
                });
                await dispatch(addFilesToUpload(intl));

                uppy.setState({
                    printableProduct: {
                        sessionId: data.id,
                        title: getProductTitle(intl, product.productData,true),
                    },
                });
                await uppy.upload();
                return;
            } else {
                await history.push('/', {
                    from: '/auth/sign-up,',
                });
            }

            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
            const { blurbApiMessage, serverErrors } = getBlurbApiError(e, intl);
            if (serverErrors) {
                setServerErrors(serverErrors);
            } else {
                setServerError(blurbApiMessage);
            }
        }
    };

    return (
        <AuthScreenWrapper>
            <AuthTitle errorMessage={serverError}>
                {intl.formatMessage({ id: 'sign-up-button', defaultMessage: 'Sign up' })}{' '}
            </AuthTitle>
            <Box
                pb={4}
                fontSize={'18px'}
                letterSpacing={'0.7px'}
                textAlign={'center'}
                lineHeight={'24px'}
                fontWeight={500}
            >
                {intl.formatMessage({
                    id: 'signup-form-tagline',
                    defaultMessage:
                        'Your new Wall Art could be on its way soon! Create an account to complete your order.',
                })}{' '}
            </Box>
            <AuthInput id={"signup-email"} name="email" onChange={handleFormChange} value={formState.email} error={emailError}>
                {' '}
                {intl.formatMessage({
                    id: 'signup-form-email-address-form-label',
                    defaultMessage: 'Email address',
                })}{' '}
            </AuthInput>

            <AuthInput id={"signup-username"} name="username" onChange={handleFormChange} value={formState.username} error={userNameError}>
                {' '}
                {intl.formatMessage({ id: 'signup-form-username-form-label', defaultMessage: 'Username' })}{' '}
            </AuthInput>

            <AuthInput
                name="password"
                id={"signup-password"}
                onChange={handleFormChange}
                value={formState.password}
                error={passwordError}
                type={showPassword ? 'text' : 'password'}
            >
                <AuthPasswordLabel>
                    <Box>Password</Box>
                    <ShowHidePassword
                        onClick={() => {
                            handleTealiumClick(
                                showPassword
                                    ? intl.formatMessage({ id: 'signup-form-hide-password', defaultMessage: 'Hide' })
                                    : intl.formatMessage({ id: 'signup-form-show-password', defaultMessage: 'Show' })
                            );
                            setShowPassword(!showPassword);
                        }}
                        src={showPassword ? hide : show}
                    >
                        {showPassword
                            ? intl.formatMessage({ id: 'signup-form-hide-password', defaultMessage: 'Hide' })
                            : intl.formatMessage({ id: 'signup-form-show-password', defaultMessage: 'Show' })}
                    </ShowHidePassword>
                </AuthPasswordLabel>
            </AuthInput>

            <AuthInfo>
                {intl.formatMessage(
                    {
                        id: 'signup-form-signup-terms',
                        defaultMessage:
                            "By clicking sign up you agree to Blurb's <blurbTermsLink>Terms & Conditions</blurbTermsLink> and <blurbPrivacyLink>Privacy Policy</blurbPrivacyLink>. In addition, you will be subscribed to receive " +
                            "exclusive email offers. This page is protected by reCAPTCHA and is subject to Google's <googleTermsLink>Terms & Conditions</googleTermsLink> and <googlePrivacyLink>Privacy Policy</googlePrivacyLink>",
                    },
                    {
                        googleTermsLink: (...chunks) => (
                            <DescriptionLink
                                text={chunks}
                                onClick={()=>handleTealiumClick(chunks)}
                                href="https://www.google.com/intl/en/policies/terms/"
                            />
                        ),
                        googlePrivacyLink: (...chunks) => (
                            <DescriptionLink
                                text={chunks}
                                onClick={()=>handleTealiumClick(chunks)}
                                href="https://www.google.com/policies/privacy/"
                            />
                        ),
                        blurbPrivacyLink: (...chunks) => (
                            <DescriptionLink
                                text={chunks}
                                onClick={()=>handleTealiumClick(chunks)}
                                href="https://www.blurb.com/privacy"
                            />
                        ),
                        blurbTermsLink: (...chunks) => (
                            <DescriptionLink
                                text={chunks}
                                onClick={()=>handleTealiumClick(chunks)}
                                href="https://www.blurb.com/terms"
                            />
                        ),
                    }
                )}
            </AuthInfo>

            <AuthButton
                disabled={loading}
                id={"signup-button"}
                onClick={() => {
                    handleTealiumClick(intl.formatMessage({ id: 'sign-up-button', defaultMessage: 'Sign up' }));
                    if (loading) {
                        return;
                    }
                    return handleSubmit();
                }}
            >
                {' '}
                {intl.formatMessage({ id: 'sign-up-button', defaultMessage: 'Sign up' })}{' '}
            </AuthButton>

            <AuthFooter>
                {intl.formatMessage(
                    {
                        id: 'signup-form-signup-cta',
                        defaultMessage: 'Already have a Blurb account? <link>Sign in</link>',
                    },
                    {
                        link: (...chunks) => (
                            <DescriptionLink
                                as={Link}
                                to={'/auth/login'}
                                onClick={()=>handleTealiumClick(chunks)}
                                text={chunks}
                                href=""
                                color="black"
                            />
                        ),
                    }
                )}
            </AuthFooter>
        </AuthScreenWrapper>
    );
};
