import acrylic_wallart from '../design/images/materials/acrylic_wallart.png';
import canvas_wallart from '../design/images/materials/canvas_wallart.png';
import metal_wallart from '../design/images/materials/metal_wallart.png';
import { defineMessages } from 'react-intl';



const acrylic = {
    url: acrylic_wallart,
    finish_type: 'acrylic_gloss',
    translation:defineMessages( {
        title: {
            id: 'slide_acrylic_title',
            defaultMessage: 'Acrylic Wall Art',
        },
        description: {
            id: 'slide_acrylic_description',
            defaultMessage: 'Your description here',
        },
        select: {
            id: 'select-material-acrylic',
            defaultMessage: 'Select Acrylic',
        },
    })
};

const canvas = {
    url: canvas_wallart,
    finish_type: 'canvas_matte',
    translation:defineMessages( {
        title: {
            id: 'slide_canvas_title',
            defaultMessage: 'Canvas Wall Art',
        },
        description: {
            id: 'slide_canvas_description',
            defaultMessage: 'Archival-quality canvas prints with a classic woven texture add warmth, texture and timeless style to any space',
        },
        select: {
            id: 'select-material-canvas',
            defaultMessage: 'Select Canvas',
        }
    })
};

const metal = {
    url: metal_wallart,
    finish_type: 'metal_gloss',
    translation: defineMessages({
        title: {
            id: 'slide_metal_title',
            defaultMessage: 'Metal Wall Art',
        },
        description: {
            id: 'slide_metal_description',
            defaultMessage: 'Sleek metal prints are designed to impress with radiant colors, round edges and a lustrous finish.',
        },
        select: {
            id: 'select-material-metal',
            defaultMessage: 'Select Metal',
        },
    }),
}


export default [
    canvas,
    acrylic,
    metal
];
