import { ValidationResult } from '../components/hooks/ValidationResult';

export const getAxiosError = error => {
    if (error && error.response && error.response.data && error.response.data) {
        const code = error.response.code ? error.response.code : {};
        return {
            data: error.response.data,
            ...code,
        };
    }
    return {
        httpCode: null,
        data: error.toString(),
    };
};

export const errorTranslation = (error, intl) => {
    switch (error) {
        case 'authentication-server-error':
            return intl.formatMessage({
                id: 'authentication-server-error',
                defaultMessage: 'Error while accessing server. Please try again',
            });
        case 'login.invalid':
            return intl.formatMessage({ id: 'login.invalid', defaultMessage: 'Invalid username or password.' });
        case 'email.address_already_taken':
            return intl.formatMessage({
                id: 'email.address_already_taken',
                defaultMessage: 'This email is already taken',
            });
        case 'user.username_already_exists':
            return intl.formatMessage({
                id: 'user.username_already_exists',
                defaultMessage: 'This username already exists.',
            });
        case 'user.password_too_short':
            return intl.formatMessage({ id: 'user.password_too_short', defaultMessage: 'Password is too short.' });
        case 'user.username_contains_forbidden_characters':
            return intl.formatMessage({
                id: 'user.username_contains_forbidden_characters',
                defaultMessage: 'Username contains forbidden characters.',
            });
        case 'user.username_must_be_between':
            return intl.formatMessage({
                id: 'user.username_must_be_between',
                defaultMessage: 'Username must be between 3 and 12 characters long.',
            });
        case 'user.username_contains_bad_word':
            return intl.formatMessage({
                id: 'user.username_contains_bad_word',
                defaultMessage: 'Username contains forbidden word.',
            });
        case 'email.address_is_not_valid':
            return intl.formatMessage({
                id: 'email.address_is_not_valid',
                defaultMessage: 'Email address is not valid.',
            });
        case 'registration.error':
            return intl.formatMessage({
                id: 'registration.error',
                defaultMessage: 'Server reported error while trying to sign-up. Please try again.',
            });
        default: {
            return `unknown error ${error}`; // not localized as this error is not expected
        }
    }
};
export function handleTealiumClick(text) {
    try {
        if (window?.utag?.link && text?.length > 0) {
            window.utag.link({
                tealium_event: 'trackEvent',
                click_text: text,
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export const getBlurbApiError = (error, intl) => {
    const { httpCode, data } = getAxiosError(error);
    let blurbApiMessage = errorTranslation('authentication-server-error', intl);
    let blurbApiCode = null;
    const serverErrors = {};
    if (data.error) {
        if (Array.isArray(data.error)) {
            if (data.error.length > 0) {
                blurbApiMessage = null;
                data.error.forEach(err => {
                    const parameter = err.parameter;

                    const code = err.code;
                    if (parameter) {
                        const validationResult = new ValidationResult();
                        validationResult.key = code;
                        validationResult.success = false;
                        validationResult.errorMessage = errorTranslation(err.code, intl);
                        serverErrors[parameter] = validationResult;
                    }
                });
            }
        } else {
            const parameter = data.error.parameter;

            const code = data.error.code;
            if (parameter) {
                const validationResult = new ValidationResult();
                validationResult.key = code;
                validationResult.success = false;
                validationResult.errorMessage = errorTranslation(code, intl);
                serverErrors[parameter] = validationResult;
            }
        }
        if (data.error.code) {
            blurbApiCode = data.error.code;
        }
        if (data.error.message) {
            blurbApiMessage = errorTranslation(data.error.code, intl);
        }
    }
    return {
        httpCode,
        data,
        serverErrors,
        blurbApiMessage,
        blurbApiCode,
    };
};
