import React, { useEffect, useState } from 'react';
import uppy, { customFileListener } from '../../redux/uppy';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
export const useDragUpload = (initial = false, handleFirstRender = () => {}) => {
    const uploadRef = React.useRef();
    const dragRef = React.useRef();
    const [firstRender, setFirstRender] = useState(true);
    const dispatch = useDispatch();
    let history=useHistory()

    if (initial && firstRender) {
        handleFirstRender();
        setFirstRender(false);
    }

    useEffect(() => {
        uploadRef.current.addEventListener('change', event => {
            const files = event.target.files;
            customFileListener(Array.from(files), uppy,history);
        });

        dragRef.current.addEventListener('dragover', event => {
            event.stopPropagation();
            event.preventDefault();
        });

        dragRef.current.addEventListener('drop', event => {
            event.stopPropagation();
            event.preventDefault();
            const files = event.dataTransfer.files;
            customFileListener(Array.from(files), uppy,history);
        });
        // eslint-disable-next-line
    }, [dragRef.current, uploadRef.current]);

    return [uploadRef, dragRef];
};
