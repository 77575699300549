export default [
    {
        id: 'wallart-acrylic_gloss-portrait_8x10',
        finish_type: 'acrylic_gloss',
        orientation: 'portrait',
        trim_text: '8x10',
        trim_text_cm:"20x25",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 576,
            height: 720,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_8x10',
        trim: {
            width: 576,
            height: 720,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-landscape_10x8',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-landscape_10x8',
        finish_type: 'acrylic_gloss',
        orientation: 'landscape',
        trim_text: '10x8',
        trim_text_cm:"25x20",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 720,
            height: 576,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_10x8',
        trim: {
            width: 720,
            height: 576,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-portrait_8x10',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-portrait_11x14',
        finish_type: 'acrylic_gloss',
        orientation: 'portrait',
        trim_text: '11x14',
        trim_text_cm:"28x36",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 792,
            height: 1008,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_11x14',
        trim: {
            width: 792,
            height: 1008,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-landscape_14x11',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-landscape_14x11',
        finish_type: 'acrylic_gloss',
        orientation: 'landscape',
        trim_text: '14x11',
        trim_text_cm:"36x28",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 1008,
            height: 792,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_14x11',
        trim: {
            width: 1008,
            height: 792,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-portrait_11x14',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-square_12x12',
        finish_type: 'acrylic_gloss',
        orientation: 'square',
        trim_text: '12x12',
        trim_text_cm:"30x30",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 864,
            height: 864,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'square_12x12',
        trim: {
            width: 864,
            height: 864,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-square_12x12',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-portrait_16x20',
        finish_type: 'acrylic_gloss',
        orientation: 'portrait',
        trim_text: '16x20',
        trim_text_cm:"41x51",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 1152,
            height: 1440,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_16x20',
        trim: {
            width: 1152,
            height: 1440,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-landscape_20x16',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-landscape_20x16',
        finish_type: 'acrylic_gloss',
        orientation: 'landscape',
        trim_text: '20x16',
        trim_text_cm:"51x41",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 1440,
            height: 1152,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_20x16',
        trim: {
            width: 1440,
            height: 1152,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-portrait_16x20',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-portrait_20x30',
        finish_type: 'acrylic_gloss',
        orientation: 'portrait',
        trim_text: '20x30',
        trim_text_cm:"51x76",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 1440,
            height: 2160,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_20x30',
        trim: {
            width: 1440,
            height: 2160,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-landscape_30x20',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-landscape_30x20',
        finish_type: 'acrylic_gloss',
        orientation: 'landscape',
        trim_text: '30x20',
        trim_text_cm:"76x51",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 2160,
            height: 1440,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_30x20',
        trim: {
            width: 2160,
            height: 1440,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-portrait_20x30',
        paper_base_type: 'standard',
    },
    {
        id: 'wallart-acrylic_gloss-square_20x20',
        finish_type: 'acrylic_gloss',
        orientation: 'square',
        trim_text: '20x20',
        trim_text_cm:"51x51",
        bleed: {
            width: 0,
            height: 0,
        },
        rendered_rect: {
            width: 1440,
            height: 1440,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'acrylic_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'square_20x20',
        trim: {
            width: 1440,
            height: 1440,
        },
        corner_type: 'square',
        inverted_id: 'wallart-acrylic_gloss-square_20x20',
        paper_base_type: 'standard',
    },
    // Fuji has discontinued support for 20x20 metal art  https://blurb-books.atlassian.net/browse/IU-169
    // {
    //     id: 'wallart-metal_gloss-square_20x20',
    //     finish_type: 'metal_gloss',
    //     orientation: 'square',
    //     trim_text: '20x20',
    //     trim_text_cm:"51x51",
    //     bleed: {
    //         width: 9,
    //         height: 9,
    //     },
    //     rendered_rect: {
    //         width: 1458,
    //         height: 1458,
    //     },
    //     safe_area: {
    //         width: 38,
    //         height: 38,
    //     },
    //     printable_product_type: 'metal_print',
    //     hanging_hardware_type: 'flush_mount',
    //     printable_product_dimension: 'square_20x20',
    //     trim: {
    //         width: 1440,
    //         height: 1440,
    //     },
    //     corner_type: 'square',
    //     inverted_id: 'wallart-metal_gloss-square_20x20',
    //     paper_base_type: null,
    // },
    {
        id: 'wallart-metal_gloss-portrait_11x14',
        finish_type: 'metal_gloss',
        orientation: 'portrait',
        trim_text: '11x14',
        trim_text_cm:"28x36",
        bleed: {
            width: 18,
            height: 18,
        },
        rendered_rect: {
            width: 828,
            height: 1044,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_11x14',
        trim: {
            width: 792,
            height: 1008,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-landscape_14x11',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-landscape_14x11',
        finish_type: 'metal_gloss',
        orientation: 'landscape',
        trim_text: '14x11',
        trim_text_cm:"36x28",
        bleed: {
            width: 18,
            height: 18,
        },
        rendered_rect: {
            width: 1044,
            height: 828,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_14x11',
        trim: {
            width: 1008,
            height: 792,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-portrait_11x14',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-portrait_8x10',
        finish_type: 'metal_gloss',
        orientation: 'portrait',
        trim_text: '8x10',
        trim_text_cm:"20x25",
        bleed: {
            width: 9,
            height: 9,
        },
        rendered_rect: {
            width: 594,
            height: 738,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_8x10',
        trim: {
            width: 576,
            height: 720,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-landscape_10x8',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-landscape_10x8',
        finish_type: 'metal_gloss',
        orientation: 'landscape',
        trim_text: '10x8',
        trim_text_cm:"20x25",
        bleed: {
            width: 9,
            height: 9,
        },
        rendered_rect: {
            width: 738,
            height: 594,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_10x8',
        trim: {
            width: 720,
            height: 576,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-portrait_8x10',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-square_12x12',
        finish_type: 'metal_gloss',
        orientation: 'square',
        trim_text: '12x12',
        trim_text_cm:"30x30",
        bleed: {
            width: 9,
            height: 9,
        },
        rendered_rect: {
            width: 882,
            height: 882,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'square_12x12',
        trim: {
            width: 864,
            height: 864,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-square_12x12',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-portrait_16x20',
        finish_type: 'metal_gloss',
        orientation: 'portrait',
        trim_text: '16x20',
        trim_text_cm:"30x30",
        bleed: {
            width: 9,
            height: 9,
        },
        rendered_rect: {
            width: 1170,
            height: 1458,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_16x20',
        trim: {
            width: 1152,
            height: 1440,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-landscape_20x16',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-landscape_20x16',
        finish_type: 'metal_gloss',
        orientation: 'landscape',
        trim_text: '20x16',
        trim_text_cm:"51x46",
        bleed: {
            width: 9,
            height: 9,
        },
        rendered_rect: {
            width: 1458,
            height: 1170,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_20x16',
        trim: {
            width: 1440,
            height: 1152,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-portrait_16x20',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-portrait_20x30',
        finish_type: 'metal_gloss',
        orientation: 'portrait',
        trim_text: '20x30',
        trim_text_cm:"51x76",
        bleed: {
            width: 18,
            height: 18,
        },
        rendered_rect: {
            width: 1458,
            height: 2178,
        },
        safe_area: {
            width: 75,
            height: 75,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'portrait_20x30',
        trim: {
            width: 1422,
            height: 2142,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-landscape_30x20',
        paper_base_type: null,
    },
    {
        id: 'wallart-metal_gloss-landscape_30x20',
        finish_type: 'metal_gloss',
        orientation: 'landscape',
        trim_text: '30x20',
        trim_text_cm:"76x51",
        bleed: {
            width: 18,
            height: 18,
        },
        rendered_rect: {
            width: 2178,
            height: 1458,
        },
        safe_area: {
            width: 75,
            height: 75,
        },
        printable_product_type: 'metal_print',
        hanging_hardware_type: 'flush_mount',
        printable_product_dimension: 'landscape_30x20',
        trim: {
            width: 2142,
            height: 1422,
        },
        corner_type: 'square',
        inverted_id: 'wallart-metal_gloss-portrait_20x30',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-portrait_8x10',
        finish_type: 'canvas_matte',
        orientation: 'portrait',
        trim_text: '8x10',
        trim_text_cm:"20x25",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 828,
            height: 972,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'portrait_8x10',
        trim: {
            width: 594,
            height: 738,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-landscape_10x8',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-landscape_10x8',
        finish_type: 'canvas_matte',
        orientation: 'landscape',
        trim_text: '10x8',
        trim_text_cm:"25x20",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 972,
            height: 828,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'landscape_10x8',
        trim: {
            width: 738,
            height: 594,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-portrait_8x10',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-portrait_11x14',
        finish_type: 'canvas_matte',
        orientation: 'portrait',
        trim_text: '11x14',
        trim_text_cm:"28x36",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1044,
            height: 1260,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'portrait_11x14',
        trim: {
            width: 810,
            height: 1026,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-landscape_14x11',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-landscape_14x11',
        finish_type: 'canvas_matte',
        orientation: 'landscape',
        trim_text: '14x11',
        trim_text_cm:"36x28",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1260,
            height: 1044,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'landscape_14x11',
        trim: {
            width: 1026,
            height: 810,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-portrait_11x14',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-square_12x12',
        finish_type: 'canvas_matte',
        orientation: 'square',
        trim_text: '12x12',
        trim_text_cm:"30x30",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1116,
            height: 1116,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'square_12x12',
        trim: {
            width: 882,
            height: 882,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-square_12x12',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-portrait_16x20',
        finish_type: 'canvas_matte',
        orientation: 'portrait',
        trim_text: '16x20',
        trim_text_cm:"41x51",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1404,
            height: 1692,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'portrait_16x20',
        trim: {
            width: 1170,
            height: 1458,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-landscape_20x16',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-landscape_20x16',
        finish_type: 'canvas_matte',
        orientation: 'landscape',
        trim_text: '20x16',
        trim_text_cm:"51x41",

        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1692,
            height: 1404,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'landscape_20x16',
        trim: {
            width: 1458,
            height: 1170,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-portrait_16x20',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-portrait_20x24',
        finish_type: 'canvas_matte',
        orientation: 'portrait',
        trim_text: '20x24',
        trim_text_cm:"51x61",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1692,
            height: 1980,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'portrait_20x24',
        trim: {
            width: 1458,
            height: 1746,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-landscape_24x20',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-landscape_24x20',
        finish_type: 'canvas_matte',
        orientation: 'landscape',
        trim_text: '24x20',
        trim_text_cm:"61x51",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1980,
            height: 1692,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'landscape_24x20',
        trim: {
            width: 1746,
            height: 1458,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-portrait_20x24',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-portrait_20x30',
        finish_type: 'canvas_matte',
        orientation: 'portrait',
        trim_text: '20x30',
        trim_text_cm:"51x76",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 1692,
            height: 2412,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'portrait_20x30',
        trim: {
            width: 1458,
            height: 2178,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-landscape_30x20',
        paper_base_type: null,
    },
    {
        id: 'wallart-canvas_matte-landscape_30x20',
        finish_type: 'canvas_matte',
        orientation: 'landscape',
        trim_text: '30x20',
        trim_text_cm:"76x51",
        bleed: {
            width: 117,
            height: 117,
        },
        rendered_rect: {
            width: 2412,
            height: 1692,
        },
        safe_area: {
            width: 38,
            height: 38,
        },
        printable_product_type: 'canvas_print',
        hanging_hardware_type: 'cable',
        printable_product_dimension: 'landscape_30x20',
        trim: {
            width: 2178,
            height: 1458,
        },
        corner_type: null,
        inverted_id: 'wallart-canvas_matte-portrait_20x30',
        paper_base_type: null,
    },
];
