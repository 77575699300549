import React from 'react';
import PseudoBox from './PseudoBox';

export const ButtonStyle = ({ 
    color="blurb_cyan.25", 
    fontSize="13px", 
    borderRadius="4px 3px 3px 3px", 
    padding="13px 17px 11px 17px", 
    ...props 
}) => {
    return (
        <PseudoBox
            {...props}
            textTransform="uppercase"
            border="1px solid"
            borderColor={color}
            borderRadius={borderRadius}
            bg="white"
            color={color}
            fontFamily="blurb"
            fontSize={fontSize}
            padding={padding}
            letterSpacing="blurb"
            fontWeight="bold"
            lineHeight="normal"
            textAlign="center"
            transition="all .2s linear"
            outline="none"
            boxSizing="border-box"
            textDecoration="none"
            textRendering="auto"
            textShadow="none"
        />
    );
};

export const OutlineButton = ({ children, ...props }) => (
    <ButtonStyle as={'button'} {...props}>
        {children}
    </ButtonStyle>
);