import axios from 'axios';
import { useSelector } from 'react-redux';

axios.defaults.baseURL = process.env.REACT_APP_HOST;

export const API_SESSION_LOGIN = 'api/session/login';
export const API_SESSION_FETCHED = 'api/session/fetch';
export const API_CREATE_PRODUCT = 'api/create/product';
export const API_PRICES_FETCHED = 'api/prices/fetch';
// TODO: localize
const prices = {
    metal_gloss: {
        portrait_8x10: 'US $39.99',
        landscape_10x8: 'US $39.99',
        square_12x12: 'US $59.99',
        portrait_11x14: 'US $49.99',
        landscape_14x11: 'US $49.99',
        portrait_16x20: 'US $109.99',
        landscape_20x16: 'US $109.99',
        square_20x20: 'US $139.99',
        portrait_20x24: '<span class="disabled">Not Available</span>',
        landscape_24x20: '<span class="disabled">Not Available</span>',
        portrait_20x30: 'US $179.99',
        landscape_30x20: 'US $179.99',
    },
    canvas_matte: {
        portrait_8x10: 'US $39.99',
        landscape_10x8: 'US $39.99',
        square_12x12: 'US $59.99',
        portrait_11x14: 'US $54.99',
        landscape_14x11: 'US $54.99',
        portrait_16x20: 'US $79.99',
        landscape_20x16: 'US $79.99',
        square_20x20: '<span class="disabled">Not Available</span>',
        portrait_20x24: 'US $99.99',
        landscape_24x20: 'US $99.99',
        portrait_20x30: 'US $119.99',
        landscape_30x20: 'US $119.99',
    },
    acrylic_gloss: {
        portrait_8x10: 'US $69.99',
        landscape_10x8: 'US $69.99',
        square_12x12: 'US $99.99',
        portrait_11x14: 'US $89.99',
        landscape_14x11: 'US $89.99',
        portrait_16x20: 'US $149.99',
        landscape_20x16: 'US $149.99',
        square_20x20: 'US $189.99',
        portrait_20x24: '<span class="disabled">Not Available</span>',
        landscape_24x20: '<span class="disabled">Not Available</span>',
        portrait_20x30: 'US $229.99',
        landscape_30x20: 'US $229.99',
    },
};

const initialState = {
    session_id: null,
    web_session_id: null,
    printableProductId: null,
    bookServeId: null,
    productId: null,
    prices: prices,
    project_id: null,
};

export const assignSession = data => async dispatch => {
    const { id, web_session_id } = data;
    dispatch({ type: API_SESSION_FETCHED, id, web_session_id });
};

export const changePrices = prices => dispatch => {
    dispatch({ type: API_PRICES_FETCHED, prices });
};
export const validateSession = async cookie => {
    try {
        const response = await axios.post(
            'api/v4/sessions.json',
            {
                web_session_id: cookie,
                api_key: process.env.REACT_APP_API_KEY,
                sig: process.env.REACT_APP_API_SIGNATURE,
            },
            { withCredentials: true }
        );

        if (!response.data.user) {
            return { success: false, data: response.data };
        }
        return { success: true, data: response.data };
    } catch (e) {
        return { success: false, data: e };
    }
};

export const useProductPrice = () => {
    const product = useSelector(state => state.product);
    const prices = useSelector(state => state.api.prices);
    const { printable_product_dimension, finish_type } = product.productData;

    return prices[finish_type][printable_product_dimension];
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case API_SESSION_LOGIN: {
            return {
                ...state,
                session_id: action.id,
                web_session_id: action.web_session_id,
            };
        }
        case API_SESSION_FETCHED: {
            return {
                ...state,
                session_id: action.id,
                web_session_id: action.web_session_id,
            };
        }
        case API_CREATE_PRODUCT: {
            return {
                ...state,
                printableProductId: action.printableProductId,
                bookServeId: action.bookServeId,
                productId: action.productId,
            };
        }
        case API_PRICES_FETCHED: {
            return {
                ...state,
                prices: action.prices,
            };
        }

        default: {
            return { ...state };
        }
    }
};
export default reducer;
