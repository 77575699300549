import ctEventHandler from './CTEventHandler';
import { uuid } from 'uuidv4';

class CTEventsService {
    getInstanceId() {
        const storageKey = 'web-builder-instance-id';
        let storageValue = localStorage.getItem(storageKey);
        if (storageValue && storageValue?.length > 0) {
            return storageValue;
        }
        storageValue = uuid();
        localStorage.setItem(storageKey, storageValue);
        return storageValue;
    }

    sendAppStart(projectId, isLoggedIn) {
        const event = ctEventHandler.buildEvent('appStart', {
            is_logged_in: isLoggedIn,
            os: window.navigator.platform,
            user_agent: window.navigator.userAgent,
            referred_page: window.location.hostname,
        });
        ctEventHandler.sendEvent(projectId, this.getInstanceId(), [event]);
    }

    sendNewProject(projectId, data) {
        this.sendEmpty(projectId, 'newProject', data);
    }

    sendImageImportError(projectId, reason) {
        this.sendEmpty(projectId, 'imageImportError', reason);
    }

    sendCloseProject(projectId, reason) {
        this.sendEmpty(projectId, 'closeProject', reason);
    }
    sendMaterialHelp(projectId, reason) {
        this.sendEmpty(projectId, 'materialHelp', reason);
    }

    sendProcessingStart(projectId, reason) {
        this.sendEmpty(projectId, 'processingStart', reason);
    }
    sendProcessingSuccess(projectId, reason) {
        this.sendEmpty(projectId, 'processingSuccess', reason);
    }
    sendProcessingError(projectId, reason) {
        this.sendEmpty(projectId, 'processingError', reason);
    }
    sendProcessingCancel(projectId, reason) {
        this.sendEmpty(projectId, 'processingCancel', reason);
    }

    sendUploadStart(projectId, reason) {
        this.sendEmpty(projectId, 'uploadStart', reason);
    }

    sendUploadSuccess(projectId, reason, image = true) {
        const eventName = image ? "uploadSuccess" : "uploadSuccessMetaData";
        this.sendEmpty(projectId, eventName, reason);
    }

    sendUploadFail(projectId, reason) {
        this.sendEmpty(projectId, 'uploadFail', reason);
    }
    sendUploadCancel(projectId, reason) {
        this.sendEmpty(projectId, 'uploadCancel', reason);
    }
    sendEmpty(projectId, name, reason) {
        const data = reason ? reason : {};
        const event = ctEventHandler.buildEvent(name, data);
        ctEventHandler.sendEvent(projectId, this.getInstanceId(), [event]);
    }
}

const ctEventsService = new CTEventsService();
export default ctEventsService;
