import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import UploadProcessingIcon from '../../components/design/icons/UploadProcessingIcon';
import Progress from '../../components/design/Progress';
import { Box } from '../../components/design/Box';
import UploadingIcon from '../../components/design/icons/UploadingIcon';
import { LeftArrow } from '../../components/design/icons/LeftArrow';
import { Redirect, useHistory, Prompt } from 'react-router-dom';

import { getBleedPercentages, getProductFinishTitle, getProductOrientationTitle } from '../../utils/productService';
import Modals from '../../components/design/modals/Modals';
import { WarningIconFull } from '../../components/design/icons/WarningIconFull';
import { UploadErrorIcon } from '../../components/design/icons/UploadErrorIcon';
import { uploadErrorSet } from '../../redux/fileReducer';
import { useProductPrice } from '../../redux/apiReducer';

import { CanvasImage } from '../../components/design/CanvasImage';
import change_image_icon from '../../components/design/images/change_image_icon.svg';
import CPNT from './uploadScreenComponents';
import { useIntl } from 'react-intl';
import uppy from '../../redux/uppy';
import { getSupportUrl } from '../../index';
import { handleTealiumClick } from '../../utils/serverUtil';
export default function UploadScreen() {
    const upload = useSelector(state => state.upload);
    const product = useSelector(state => state.product);
    const dispatch = useDispatch();
    const currentPrice = useProductPrice();
    const intl = useIntl();

    let history = useHistory();
    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                uppy.cancelAll();
            }
        };
    }, [history]);

    if (!upload.imageInfo) {
        return <Redirect to={'/'} noThrow />;
    }

    const UploadModals = {
        UploadFail: () => (
            <Modals.Error
                isOpen={upload.uploadError}
                onClose={(tealium = true) => {
                    if (tealium) {
                        handleTealiumClick('upload-fail-close');
                    }

                    return dispatch(uploadErrorSet(false));
                }}
                button={{
                    name: intl.formatMessage({ id: 'button-title-retry', defaultMessage: 'Retry' }),
                    onClick: () => history.push('/edit', { from: '/upload' }),
                }}
            >
                <Box>
                    <Box>
                        <WarningIconFull
                            width="23px"
                            height="23px"
                            position="absolute"
                            marginTop="45px"
                            marginLeft="39px"
                            fill="white"
                        />
                        <UploadErrorIcon width="66px" height="66px" color="#3399A2" />
                    </Box>
                    <Box fontSize="16px" lineHeight="19px" textAlign="center" letterSpacing="0.7px" marginTop="26px">
                        {intl.formatMessage({
                            id: 'upload-screen-upload-error',
                            defaultMessage:
                                'There seems to have been an error uploading your project. Please be sure you are on a reliable network and retry your upload.',
                        })}
                    </Box>
                </Box>
            </Modals.Error>
        ),

        ProcessingFail: () => (
            <Modals.Error
                isOpen={upload?.uploading?.uploadProcessing?.successful === false}
                onClose={(tealium=true) => {
                    if(tealium){
                        handleTealiumClick('processing-fail-close');
                    }
                    return dispatch(uploadErrorSet(false));
                }}
                button={{
                    name: intl.formatMessage({ id: 'change-image-button', defaultMessage: 'Change Image' }),
                    onClick: () => history.push('/edit', { from: '/upload' }),
                }}
            >
                <Box>
                    <Box>
                        <Box as="img" src={change_image_icon} width="66px" height="66px" />
                    </Box>
                    <Box fontSize="16px" lineHeight="19px" textAlign="center" letterSpacing="0.7px" marginTop="26px">
                        {intl.formatMessage(
                            {
                                id: 'upload-screen-upload-error-processing',
                                defaultMessage:
                                    'There was an error with your upload and it cannot be ordered. Please try another image or contact <supportLink>Customer Supports.</supportLink>',
                            },
                            {
                                supportLink: (...chunks) => (
                                    <Box as="a" target="_blank" href={getSupportUrl(intl.locale)} color="#3399A2">
                                        {chunks}
                                    </Box>
                                ),
                            }
                        )}
                    </Box>
                </Box>
            </Modals.Error>
        ),
    };

    const BackButton = ({ ...props }) => (
        <CPNT.BackButton
            {...props}
            id={"upload-screen-navigation-button-back-to-create-wall-art"}
            onClick={() => {
                uppy.cancelAll();
                return history.push('/edit');
            }}
        >
            <LeftArrow width="18px" height="12px" color="blurb_cyan.25" />
            <CPNT.BackButtonText>
                {' '}
                {intl.formatMessage({
                    id: 'upload-screen-navigation-button-back-to-create-wall-art',
                    defaultMessage: 'BACK TO CREATE WALL ART',
                })}{' '}
            </CPNT.BackButtonText>
        </CPNT.BackButton>
    );

    return (
        <>
            <UploadModals.UploadFail />
            <UploadModals.ProcessingFail />
            <CPNT.BackButtonBox>
                <BackButton />
            </CPNT.BackButtonBox>
            <CPNT.Base>
                <CPNT.Title>
                    {intl.formatMessage({ id: 'upload-screen-your-wall-art-title', defaultMessage: 'Your Wall Art' })}
                </CPNT.Title>

                <CPNT.ImageContainer>
                    <CanvasImage
                        url={upload.url}
                        imageSize={product?.productData?.rendered_rect}
                        drawBleed={getBleedPercentages(product.id, product.orientation)}
                        width="100%"
                        objectFit="contain"
                        drawBorder={{
                            width: 1,
                            color: '#A0ABC0',
                        }}
                    />
                </CPNT.ImageContainer>

                <CPNT.Description>
                    Material: {getProductFinishTitle(intl, product.productData)} Wall Art <br />
                    Size: {getProductOrientationTitle(intl, product.productData,true)} <br />
                    Price: {currentPrice}
                </CPNT.Description>

                <BackButton display={['flex', 'flex', 'none']} />
                {/* {JSON.stringify(product)} */}
            </CPNT.Base>
        </>
    );
}
