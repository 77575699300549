import { useState } from 'react';
import { ValidationResult } from './ValidationResult';

export function useFormValidation(formState = {}, validation = {}) {
    const defaultErrorState = {};
    Object.keys(formState).forEach(key => {
        defaultErrorState[key] = new ValidationResult();
    });
    const [errorState, setErrorState] = useState(defaultErrorState);
    const [serverError, setServerError] = useState(null);
    const isFormValid = () => {
        Object.keys(errorState).forEach(key => {
            if (!errorState[key].success) {
                return false;
            }
        });
        return true;
    };
    const validate = () => {
        let success = true;
        Object.keys(formState).forEach(key => {
            if (validation.hasOwnProperty(key)) {
                const validationFunction = validation[key];
                const validationResult = validationFunction(formState[key]);
                validationResult.key = key;
                if (!validationResult.success) {
                    success = false;
                }
                setErrorState(prevState => ({
                    ...prevState,
                    [key]: validationResult,
                }));
            }
        });
        return success;
    };

    const setServerErrors = serverErrors => {
        Object.keys(serverErrors).forEach(serverErrorKey => {
            if (formState.hasOwnProperty(serverErrorKey)) {
                setErrorState(prevState => ({
                    ...prevState,
                    [serverErrorKey]: serverErrors[serverErrorKey],
                }));
            }
        });
    };

    const setError = (field, error) => {
        setErrorState(prevState => ({
            ...prevState,
            [field]: error,
        }));
    };

    return { errorState, validate, serverError, setServerError, setServerErrors, setError, isFormValid };
}
