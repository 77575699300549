import { ValidationResult } from '../../components/hooks/ValidationResult';
import {errorTranslation} from "../../utils/serverUtil";

export const validateUserName = (intl, username) => {
    let validationResult = new ValidationResult();
    if (!username || username.length < 3 || username.length >12) {
        validationResult.success = false;
        validationResult.errorMessage = errorTranslation('user.username_must_be_between', intl);
    }
    return validationResult.toObject();
};

export const validatePassword = (intl, password) => {
    let validationResult = new ValidationResult();
    if (!password || password.length < 6) {
        validationResult.success = false;
        debugger;
        validationResult.errorMessage = errorTranslation('user.password_too_short', intl);
    }
    return validationResult.toObject();
};

export const validateEmail = (intl, email) => {
    let validationResult = new ValidationResult();
    /*eslint-disable-next-line no-useless-escape*/ 
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;
    if (!regex.test(String(email).toLowerCase())) {
        validationResult.success = false;
        validationResult.errorMessage = errorTranslation('email.address_is_not_valid', intl);
    }
    return validationResult.toObject();
};
