import React from 'react';
import { Box } from '../../Box';
import { BlurbTooltip } from "../BlurbTooltip";

export const Info = ({ title, description, link, trigger=["click", "hover"], placement="top", url="https://support.blurb.com/hc/en-us", ...props })  => {
    const Overlay = (
        <Box
            width="200px"
            height="100%"
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="left"
            lineHeight="1.5"
            fontSize="12px"
            paddingTop="5px"
        >
            { title && <Box id={`${props.id}-title`} fontWeight="700">{title}</Box> }
            { description && <Box  id={`${props.id}-description`}>{description}</Box> }
            { link && (
                <Box
                    as='a'
                    href='https://support.blurb.com/hc/en-us/articles/360041191211'
                    id={`${props.id}-link`}
                    target="_blank"
                    fontWeight="700"
                    color="blurb_orange.900"
                    textDecoration="underline"
                    marginTop="10px"
                    textTransform="uppercase"
                >
                    {link}
                </Box>
            )}
        </Box>
    );

    return <BlurbTooltip
        { ...props }
        placement={placement}
        trigger={trigger}
        overlay={Overlay}
        transitionName="zoom"
    />;
}
