import React from 'react';
import Modal from 'react-modal';
import style from './style.json';
import { CloseIcon } from '../../icons/CloseIcon';
import { Button } from '../../Button';
import { OutlineButton } from '../../OutlineButton';
import CPNT from './components';

export const Confirmation = (props) => {
    const {
        isOpen,
        onClose,
        title,
        children,
        button1,
        button2,
        noDivider,
        modalWidth
    } = props;

    const handleSubmit = (fun) => {
        fun();
        return onClose(false);
    }

    const ModalBody = () => (
      <CPNT.Base width={ modalWidth }>
        <CPNT.CloseBox onClick={() => onClose()}>
          <CloseIcon width="12px" height="12px" color="#979797" disabled/>
        </CPNT.CloseBox>

        { title &&
          <CPNT.Title>
            { title }
            { !noDivider && <CPNT.Divider /> }
          </CPNT.Title>
        }

        { children }

        { ( button1 || button2) &&
          <CPNT.ButtonBox>
            { button1 &&
              <Button
                width="100%"
                borderRadius="3px"
                id={button1?.id}
                marginBottom="28px"
                onClick={() => handleSubmit(button1?.onClick)}
              >
                { button1?.name }
              </Button>
            }

            { button2 &&
              <OutlineButton
                width="100%"
                borderRadius="3px"
                id={button2?.id}
                onClick={() => handleSubmit(button2?.onClick)}
              >
                { button2?.name }
              </OutlineButton>
            }
          </CPNT.ButtonBox>
        }
      </CPNT.Base>
    );

    return (
      <Modal
        closeTimeoutMS={700}
        isOpen={isOpen}
        onRequestClose={onClose}
        style={style}
        contentLabel="Confirmation Modal"
      >
        <ModalBody />
      </Modal>
    );
};
