import React, { useState } from 'react';
import { Button } from '../Button';
import { Box } from '../Box';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './imageSlider.css';
import {
    CloseIconContainter,
    DotsRow,
    ScreenBase,
    SlideContainer,
    SlideContainerBig,
    SlideObject,
    SlidesBase,
    SlidesFooter,
} from './ImageSliderComponents';
import { CloseIcon } from '../icons/CloseIcon';
import styled, { keyframes } from 'styled-components';
import { useIntl } from 'react-intl';
import slides from '../../footer/materialSlides';
import Modals from '../modals/Modals';
import { handleTealiumClick } from '../../../utils/serverUtil';

const fadeInOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const BaseTransition = styled(Box)`
    opacity: 1;
    animation-name: ${fadeInOpacity};
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.25s;
    z-index: 998;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
`;

const SliderImageContainer = ({ src, ...props }) => (
    <Box {...props} as="img" src={src} width="294px" maxHeight="237px" />
);

export function ImageSlider({ onSlideSelect, ...props }) {
    const intl = useIntl();
    const { show, setShow } = props;

    const getSlideIndex = index => {
        if (index < 0) {
            index = Math.abs(index);
            if (index >= slides.length - 1) {
                index -= Math.floor(index / slides.length) * slides.length;
            }
            index = slides.length - index;
        }

        if (index >= slides.length) {
            index -= Math.floor(index / slides.length) * slides.length;
        }

        return index;
    };

    const handleSelectCanvas = index => {
        index = getSlideIndex(index);
        const selectedSlide = slides[index];
        onSlideSelect(selectedSlide);
        setShow(false);
    };

    function SmallScreen({ display, slidesPerPage }) {
        const [slideIndex, setSlideIndex] = useState(0);

        const handleSlideChange = index => {
            handleTealiumClick('slide to ' + index);
            setSlideIndex(index);
        };

        return (
            <Box display={display}>
                <Carousel
                    centered
                    infinite
                    slidesPerPage={slidesPerPage}
                    slidesPerScroll={1}
                    offset={134}
                    itemWidth={294}
                    value={slideIndex}
                    onChange={handleSlideChange}
                >
                    {slides.map((slide, i) => {
                        const { translation } = slide;
                        return (
                            <SlideObject
                                key={`slide${i}`}
                                title={intl.formatMessage(translation.title)}
                                description={intl.formatMessage(translation.description)}
                            >
                                <SliderImageContainer src={slide.url} />
                            </SlideObject>
                        );
                    })}
                </Carousel>
                <SlidesFooter>
                    <DotsRow>
                        <Dots value={slideIndex} onChange={handleSlideChange} number={slides.length} />
                    </DotsRow>
                    <Button
                        width="222px"
                        onClick={() => {
                            handleTealiumClick(
                                intl.formatMessage(slides[getSlideIndex(slideIndex)]?.translation?.select)
                            );
                            handleSelectCanvas(slideIndex);
                        }}
                    >
                        {intl.formatMessage(slides[getSlideIndex(slideIndex)]?.translation?.select)}
                    </Button>
                </SlidesFooter>
            </Box>
        );
    }

    function BigScreen({ display }) {
        return (
            <Box display={display}>
                <SlideContainerBig>
                    {slides.map((slide, i) => {
                        const { translation } = slide;

                        return (
                            <SlideContainer key={`slide-container${i}`}>
                                <Box maxWidth="294px">
                                    <SlideObject
                                        title={intl.formatMessage(translation.title)}
                                        description={intl.formatMessage(translation.description)}
                                    >
                                        <SliderImageContainer src={slide.url} />
                                    </SlideObject>
                                    <Button onClick={() => {
                                        handleTealiumClick(intl.formatMessage(slides[i].translation.select))
                                        handleSelectCanvas(i);
                                    }} width="222px">
                                        {intl.formatMessage(slides[i].translation.select)}
                                    </Button>
                                </Box>
                            </SlideContainer>
                        );
                    })}
                </SlideContainerBig>
            </Box>
        );
    }

    return (
        // <BaseTransition display={show ? 'flex' : 'none'}>
        <Modals.Wrapper isOpen={show} onClose={() => {
            handleTealiumClick("slider close")
            return setShow(false);
        }}>
            <SlidesBase>
                <CloseIconContainter onClick={() => setShow(false)}>
                    <CloseIcon width="15px" />
                </CloseIconContainter>
                <ScreenBase>
                    <SmallScreen slidesPerPage={1} display={['initial', 'none', 'none', 'none']} />
                    <SmallScreen slidesPerPage={2} display={['none', 'initial', 'none', 'none']} />
                    <BigScreen className="BigScreen" display={['none', 'none', 'initial', 'initial']} />
                </ScreenBase>
            </SlidesBase>
        </Modals.Wrapper>
        // </BaseTransition>
    );
}
