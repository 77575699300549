export const SCREEN_SWITCH = '/editScreen/switch';

const initialState = {
    currentEditScreen: 'material',
    editScreens: { image: 'image', material: 'material', size: 'size' },
};

export const switchEditScreen = newScreen => dispatch => {
    dispatch({ type: SCREEN_SWITCH, newScreen });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SCREEN_SWITCH: {
            return {
                ...state,
                currentEditScreen: action.newScreen,
            };
        }
        default: {
            return { ...state };
        }
    }
};
export default reducer;
