import React from 'react';
import PseudoBox from '../PseudoBox';

function MaterialIcon({ width = '50px', height = '50px', color }) {
    const style = {
        fill: color ? color : ""
    }
    return (
        <PseudoBox
            {...style}
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 26 27"
        >
            <path
                fillRule="evenodd"
                d="M25.49 6.068a.528.528 0 01.51.527v19.877a.52.52 0 01-.52.528H.513A.527.527 0 010 26.473V6.596a.527.527 0 01.52-.528h6.769l4.214-3.261a1.823 1.823 0 01-.29-.992C11.212.815 12.013 0 13 0c.986 0 1.788.814 1.788 1.815 0 .366-.107.706-.292.992l4.215 3.261H25.491zM13.75 1.815c0-.42-.336-.76-.749-.76a.755.755 0 00-.749.76c0 .419.336.76.749.76a.755.755 0 00.749-.76zm-1.423 1.681a1.757 1.757 0 001.347 0l3.324 2.572H9.003l3.323-2.572zm10.446 5.103l1.454-1.476H1.774l1.454 1.476h19.544zM11.655 20.53l3.387-4.15 5.742 7.035h-6.651l-2.478-2.884zm1.1 2.885L9.003 19.05l-3.75 4.365h7.5zm2.687-8.198a.517.517 0 00-.8 0l-3.68 4.508-1.567-1.823a.516.516 0 00-.783 0l-4.737 5.513h-.342V9.654h18.934v13.76h-.334l-6.69-8.198zM2.494 9.346L1.039 7.868v17.33l1.455-1.476V9.345zm-.72 16.6l1.454-1.477h19.544l1.454 1.476H1.774zm21.732-2.223l1.455 1.477V7.869l-1.455 1.476v14.378z"
                clipRule="evenodd"
            />
        </PseudoBox>
    );
}

export default MaterialIcon;
