import React from 'react';
import { Box } from '../../components/design/Box';
import "./edit-screen.css";

const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;

export default {
    Base: ({ ...props }) => <Box
        {...props}
        display="flex"
        flexDirection={[ "column", "column", "row-reverse", "row-reverse" ]}
        justifyContent="space-between"
        height="100%"
        width="100%"
        position="relative"
        bg="blurb_gray.800"
        overflow="hidden"
        backfaceVisibility="hidden"
        className={isIOS && "iosBaseBox"}
    />,

    ImageBox: ({ ...props }) => <Box
        {...props}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        width={[ "100%", "100%", "70%", "70%" ]}
        height={[ "60%", "60%", "100%", "100%" ]}
        position="relative"
    />,

    FooterBox: ({ ...props }) => <Box
        {...props}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        width={[ "100%", "100%", "30%", "30%" ]}
        maxHeight={[ "40%", "40%", "100%", "100%" ]}
        overflow="hidden"
        position="relative"
        className={isIOS && "iosFooterBox"}
    />,
}