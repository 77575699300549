import React, { useEffect } from 'react';
import InitialScreen from './screens/initial/initialScreen';
import EditScreen from './screens/edit/edit-screen';
import { ThemeProvider } from 'styled-components';

import 'rc-tooltip/assets/bootstrap.css';
import '../src/fonts/fonts.css';

import theme from './components/design/theme/theme';
import UploadScreen from './screens/upload/uploadScreen';

import GlobalStyle from './components/design/GlobalStyle';
import { Box } from './components/design/Box';
import { AuthScreen } from './screens/auth/AuthScreen';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { NotFound } from './screens/NotFound';
import { UploadHeader } from './components/header/UploadHeader';
import { Header } from './components/header/Header';
import { useScript } from './components/hooks/useScript';
import { RouterWrapper } from './RouterWrapper';
import { CurrencyWrapper } from './CurrencyWrapper';
import Cookies from 'js-cookie';
import { assignSession, validateSession } from './redux/apiReducer';
import ctEventsService from './utils/ctEvents/CTEventsService';
import { useDispatch, useSelector } from 'react-redux';
import PseudoBox from './components/design/PseudoBox';
import { LeftArrow } from './components/design/icons/LeftArrow';
import { useIntl } from 'react-intl';
import { handleTealiumClick } from './utils/serverUtil';

const AppContainer = ({ children }) => {
    const headerPaddingPx = 14;
    let headerHeightPx = 38;
    const upload = useSelector(state => state.upload);
    let history = useHistory();

    const { pathname } = useLocation();
    const isUploadScreen = pathname.startsWith('/upload');
    const isAuthScreen = pathname.startsWith('/auth');
    const intl = useIntl();

    if (isUploadScreen) {
        headerHeightPx = 80;
    }
    if (isAuthScreen && upload.imageInfo) {
        headerHeightPx = 15;
    }

    const bodyHeight = pathname.startsWith('/upload')
        ? '80%'
        : `calc(100% - ${2 * headerPaddingPx + headerHeightPx}px)`;

    function renderHeader() {
        if (isAuthScreen && upload.imageInfo) {
            return (
                <Box
                    height={`${headerHeightPx}px`}
                    paddingY={`${headerPaddingPx}px`}
                    paddingLeft={'18px'}
                    paddingRight={'18px'}
                    fontFamily={'blurb'}
                    color={'white'}
                    cursor={'pointer'}
                    textTransform={'uppercase'}
                    textDecoration={'underline'}
                    backgroundColor={'blurb_cyan.25'}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        onClick={() => {
                            history.push('/edit');
                        }}
                    >
                        <LeftArrow width="30px" height="20px" color="white" />
                        <Box
                            fontWeight={'bold'}
                            fontSize={'13px'}
                            letterSpacing={'0.7px'}
                            lineHeight={'17px'}
                            onClick={() =>
                                handleTealiumClick(
                                    intl.formatMessage({
                                        id: 'back-to-project-button',
                                        defaultMessage: 'Back to project',
                                    })
                                )
                            }
                        >
                            {intl.formatMessage({ id: 'back-to-project-button', defaultMessage: 'Back to project' })}
                        </Box>
                    </Box>
                </Box>
            );
        }

        if (isUploadScreen) {
            return <UploadHeader />;
        }

        return (
            <Header
                height={`${headerHeightPx}px`}
                paddingY={`${headerPaddingPx}px`}
                paddingLeft={'18px'}
                paddingRight={'18px'}
            />
        );
    }
    return (
        <Box height="100%">
            {renderHeader()}
            <Box height={bodyHeight}>
                <Switch>
                    <Route exact path={'/'}>
                        <InitialScreen />
                    </Route>
                    <Route path={'/auth'}>
                        <AuthScreen />
                    </Route>
                    <Route path={'/edit'}>
                        <EditScreen />
                    </Route>
                    <Route path={'/upload'}>
                        <UploadScreen />
                    </Route>
                    <Route path={'*'}>
                        <NotFound />
                    </Route>
                </Switch>
            </Box>
        </Box>
    );
};

//https://github.com/facebook/create-react-app/issues/1491
function suppressLog() {}

function App() {
    const upload = useSelector(state => state.upload);
    const sessionCookie = Cookies.get('_session_id', { domain: '*.blurb.com' });
    const dispatch = useDispatch();
    const intl = useIntl();
    if (
        (process.env.REACT_APP_ENV_NAME && process.env.REACT_APP_ENV_NAME === 'integration') ||
        process.env.REACT_APP_ENV_NAME === 'production'
    ) {
        console.log = suppressLog;
        console.warn = suppressLog;
        console.error = suppressLog;
    }

    useEffect(()=>{
        const metas =document.getElementsByTagName("meta")
        for (let element of metas){
            if(element?.name==="description"){
                element.content=intl.formatMessage({
                    id: 'preview_link',
                    defaultMessage: 'Create your own Wall-Art"',
                })
            }
        }
    },[])

    useEffect(() => {
        validateSession(sessionCookie).then(result => {
            const { success, data } = result;
            ctEventsService.sendAppStart(upload.project_id, success);
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <CurrencyWrapper>
                    <RouterWrapper>
                        <AppContainer />
                    </RouterWrapper>
                </CurrencyWrapper>
            </>
        </ThemeProvider>
    );
}

export default App;
