import React from "react";
import Tooltip from 'rc-tooltip';
import "./tooltip_design.css";

export const BlurbTooltip = ({ trigger=['hover', 'click'], ...props }) => {
    return <Tooltip
      {...props}
      trigger={trigger}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
    />
} 