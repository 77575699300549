import React from "react";

function SupportIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="none"
            viewBox="0 0 19 19"
        >
            <path
                stroke="#3399A2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.79"
                d="M8.53 11.523l5.784 5.791c.993.994 2.143.927 2.902.172.754-.754.82-1.91-.172-2.904l-5.81-5.76-2.705 2.7zM10.345 9.6l-5.36-5.363-.073-1.039-2.717-1.753-.979.98.976-.976-.98.979 1.753 2.718 1.039.074 5.36 5.362M9.906 10.145l7.235 7.237"
            ></path>
            <path
                fill="#fff"
                d="M9.449 7.106l1.667-1.662a3.489 3.489 0 014.463-4.263.156.156 0 01.065.26L13.87 3.21a1.257 1.257 0 000 1.777l.239.24a1.1 1.1 0 001.555 0l1.908-1.88a.155.155 0 01.258.066 3.509 3.509 0 01-4.352 4.437l-9.28 9.313c-.987.985-2.068.983-2.734.315-.665-.668-.664-1.748.324-2.733l7.846-7.827"
            ></path>
            <path
                stroke="#3399A2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.79"
                d="M9.449 7.106l1.667-1.662a3.489 3.489 0 014.463-4.263.156.156 0 01.065.26L13.87 3.21a1.257 1.257 0 000 1.777l.239.24a1.1 1.1 0 001.555 0l1.908-1.88a.155.155 0 01.258.066 3.509 3.509 0 01-4.352 4.437l-9.28 9.313c-.987.985-2.068.983-2.734.315-.665-.668-.664-1.748.324-2.733l7.846-7.827"
            ></path>
        </svg>
    );
}

export default SupportIcon;
