export class ValidationResult {

    constructor() {
        this.key = '';
        this.success = true;
        this.errorMessage = null;
    }


    toObject() {
        return { success: this.success, errorMessage: this.errorMessage, key: this.key };
    }

    static success(){
        return {success:true,errorMessage:null,key:""}
    }
    static failure(key,message){
        return {success:false,errorMessage:message,key:key}
    }
}
