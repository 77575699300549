import React from 'react';
import { Box } from '../design/Box';
import { Button } from '../design/Button';

export default {
    Base: ({ ...props }) => <Box
        {...props}
        bg='white'
        display='flex'
        flexDirection="row"
        alignItems='center'
        justifyContent="space-between"    
    />,

    BackButton: ({ ...props }) => <Box
        { ...props }
        border="none"
        as='button'
        cursor='pointer'
        outline={0}
        background="none"
        padding={0}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
    />,

    BackText: ({ ...props }) => <Box
        { ...props }
        marginLeft="7px"
        fontSize="16px"
        lineHeight="19px"
        letterSpacing="0.0646154px"
        textDecoration="underline"
        textTransform="uppercase"
        color="#4a4a4a"
    />,

    CartButton: ({ ...props }) => <Button
        { ...props }
        width="187px"
        buttonColor="blurb_red.900"
        fontSize="13px"
    />
}