/*eslint-disable */

import React from 'react';
import { Box } from '../Box';
import { handleTealiumClick } from '../../../utils/serverUtil';

export function WarningIconFull({ width = '22', height = '21', fill = 'none', color = '#CD5225', ...props }) {
    return (
        <Box
            as="svg"
            width={width}
            height={height}
            onClick={() => {
                handleTealiumClick('warning icon clicked');
            }}
            viewBox="0 0 22 21"
            fill="none"
            id={"warning-icon-full"}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1L22 19H0L11 1Z" fill={fill} />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.3277 1.99681L21.5298 15.5291C22.142 16.6795 22.0995 18.0849 21.4191 19.1969C20.7387 20.309 19.4985 20.9999 18.1826 20.9999H3.77837C2.46233 20.9999 1.22211 20.3089 0.5418 19.1966C-0.13866 18.0849 -0.18112 16.6795 0.43114 15.5291L7.63321 1.99681C8.28881 0.765124 9.57133 0 10.9804 0C12.3895 0 13.6721 0.765124 14.3277 1.99681ZM18.1826 18.8276C18.7328 18.8276 19.2514 18.5387 19.536 18.0735C19.8206 17.6084 19.8383 17.0208 19.5823 16.5397L12.3801 3.00734C12.1061 2.49227 11.5697 2.17234 10.9804 2.17234C10.3912 2.17234 9.85477 2.49234 9.58072 3.00734L2.37858 16.5397C2.12257 17.0208 2.14025 17.6084 2.42478 18.0733C2.70939 18.5387 3.22793 18.8276 3.77829 18.8276H18.1826Z"
                fill={color}
            />
            <path d="M10.5627 14.2432H11.3981L12.2133 5.97815H9.74744L10.5627 14.2432Z" fill={color} />
            <path
                d="M10.9804 15.0105C10.3219 15.0105 9.7887 15.5371 9.7887 16.1874C9.7887 16.837 10.3219 17.3642 10.9804 17.3642C11.6389 17.3642 12.1722 16.837 12.1722 16.1874C12.1722 15.5372 11.639 15.0105 10.9804 15.0105Z"
                fill={color}
            />
        </Box>
    );
}

export default WarningIconFull;
