import React, { forwardRef } from 'react';
import { Box } from '../Box';

export const SlidesBase = forwardRef(({ ...props }, ref) => (
    <Box
        {...props}
        ref={ref}
        background="rgba(0, 0, 0, 0.01)"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="flex-start"
        zIndex="999"
        display="flex"
        backfaceVisibility="hidden"
    />
));

export const DotsRow = ({ ...props }) => (
    <Box
        {...props}
        marginBottom="33px"
    />
);

export const SlidesFooter = ({ ...props }) => (
    <Box
        {...props}
        textAlign="center"
    />
);

export const SlideContainer = ({ ...props }) => (
    <Box
        {...props}
        display="flex"
        flexDirection="column"
        textAlign="center"
        alignItems="center"
    />
);

export const SlideTitle = ({ ...props }) => (
    <Box
        {...props}
        fontStyle="normal"
        fontWeight="500"
        fontSize="16px"
        lineHeight="21px"
        letterSpacing="0.0861538px"
        color="#d8d8d8"
        textAlign="center"
        marginBottom="16px"
    />
);

export const SlideDescription = ({ ...props }) => (
    <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
    >
        <Box
            {...props}
            width="80%"
            marginTop="22px"
            marginBottom={["25px", "39px", "51px", "62px"]}
            fontStyle="normal"
            fontWeight="normal"
            fontSize="13px"
            lineHeight="16px"
            letterSpacing="0.07px"
            color="#d8d8d8"
            textAlign="left"
        />
    </Box>
);

export const SlideObject = ({ title, description, children, ...props }) => (
    <SlideContainer {...props}>
        <SlideTitle> { title } </SlideTitle>
            { children }
        <SlideDescription> { description } </SlideDescription>
    </SlideContainer>
);

export const SlideContainerBig = ({ ...props }) => (
    <Box
        {...props}
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
    />
);

export const ScreenBase = ({ ...props }) => (
    <Box
        {...props}
        marginTop={["44px", "44px", "91px", "91px"]}
    />
)

export const CloseIconContainter = ({ ...props }) => (
    <Box
        width="100%"
        position="absolute"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginTop={10}
        right={10}
    >
        <Box
            {...props}
            as="button"
            width="48px"
            height="48px"
            textAlign="center"
            background="none"
            border="none"
            outline={0}
        />
    </Box>
)
