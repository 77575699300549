import React from 'react';
import Modal from 'react-modal';
import style from './style.json';

export const Wrapper = (props) => {
    const {
        isOpen,
        onClose,
        children,
    } = props;

    return (
      <Modal
        closeTimeoutMS={700}
        isOpen={isOpen}
        onRequestClose={onClose}
        style={style}
        contentLabel="Wrapper Modal"
        shouldCloseOnOverlayClick={false}
      >
        { children }
      </Modal>
    );
};
