import React from 'react';
import { Box } from '../../components/design/Box';
import { Input } from '../../components/design/input/Input';
import { Button } from '../../components/design/Button';
import { Label } from '../../components/design/input/Label';
import {useIntl} from 'react-intl'

export const AuthTitle = ({ errorMessage, children, ...props }) => (
    <Box textAlign="center">
        <Box {...props} fontSize={"46px"} letterSpacing={"2px"} fontWeight={400} paddingTop={3} paddingBottom={errorMessage ? 0: 3}>
            {children}
        </Box>
        {errorMessage && <Box paddingTop={3} paddingBottom={errorMessage ? 3: 0} fontSize={"13px"} color={'red.400'}>{errorMessage}</Box>}
    </Box>
);

export const AuthInput = ({ children, error, ...props }) => {
    return (

        <Box minHeight="70px" marginBottom="2%">
            <Box as="label" color="#595959" fontWeight="bold">
                {children}
            </Box>
            <Input {...props} marginTop="1%" required />
            <ErrorLabel>{error && error.errorMessage }</ErrorLabel>
        </Box>
    );
}

export const AuthPasswordLabel = ({ ...props }) => (
    <Box {...props} display="flex" justifyContent="space-between" width="100%" />
);

export const AuthInfo = ({ ...props }) => (
    <Box {...props} marginBottom="40px" color="blurb_gray.500" fontWeight={400} marginTop={1} />
);

export const DescriptionLink = ({ text, as, color, ...props }) => (
    <Box {...props} as={as || 'a'} color={color || 'blurb_gray.500'}>
        {text}
    </Box>
);

export const AuthOptions = ({ ...props }) => (
    <Box {...props} display="flex" justifyContent="space-between" marginBottom="2%" />
);

export const AuthButton = ({ ...props }) => <Button {...props} width="100%" buttonColor="black" />;

export const AuthFooter = ({ ...props }) => <Box {...props} paddingY="20px" textAlign="center" />;

export const ShowHidePassword = ({ children, onClick, src, ...props }) => (
    <Box {...props} onClick={onClick}>
        <Box as="img" marginRight={0.5} src={src} />
        {children}
    </Box>
);

export const ErrorLabel = ({ ...props }) => (
    <Label {...props} color="#f73c51" fontSize="12px" lineHeight="1" margin="3px 0 0" boxSizing="border-box" />
);
