import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
  body {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  html,body,#root{
    height:100%;
    font-family: proxima-nova, sans-serif;
  }
  body {
    margin:auto
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;
export default GlobalStyle;
