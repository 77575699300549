import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { addFilesToUpload, uploadErrorSet } from '../../redux/fileReducer';
import { assignSession, validateSession } from '../../redux/apiReducer';
import { switchEditScreen } from '../../redux/editScreenReducer';
import uppy from '../../redux/uppy';

import { WarningIconFull } from '../design/icons/WarningIconFull';
import { LeftChevron } from '../design/icons/LeftChevron';
import MaterialIcon from '../design/icons/MaterialIcon';
import blurbLogo from '../design/blurb-logo.svg';
import Modals from '../design/modals/Modals';
import { Box } from '../design/Box';

import CPNT from './headerComponents';
import { useIntl } from 'react-intl';
import { getProductTitle } from '../../utils/productService';
import { issueTranslations } from '../../utils/translationMessages';
import ctEventsService from '../../utils/ctEvents/CTEventsService';
import { handleTealiumClick } from '../../utils/serverUtil';

export const Header = ({ ...props }) => {
    const upload = useSelector(state => state.upload);
    const [purchaseWarning, setPurchaseWarning] = useState(false);
    const [leaveEdit, setLeaveEdit] = useState(false);
    const product = useSelector(state => state.product);

    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { editScreens } = useSelector(state => state.editScreen);
    let history = useHistory();
    const intl = useIntl();

    const handleAddToCart = async () => {
        if (window?.utag?.link) {
            window.utag.link({
                event_name: 'add-to-cart-clicked',
                event_category: 'add-to-cart-clicked',
                event_action: 'click',
                event_label: 'click',
            });
        }
        if (pathname === '/') return;
        const sessionCookie = Cookies.get('_session_id', { domain: '*.blurb.com' });

        const { success, data } = await validateSession(sessionCookie);
        dispatch(uploadErrorSet(false));

        let lowRezWarning = !!upload.validations.issues.imageResolution;
        ctEventsService.sendNewProject(upload.project_id, {
            projectInfo: {
                type: product.productData.finish_type,
                size: product.productData.trim_text,
                orientation: product.productData.orientation,
            },
            imageInfo: {
                imageName: upload.filename,
                size: upload.blob.size,
                imageSize: upload.imageInfo,
            },
            editImage: { clickedOnEdit: upload.clickedOnEdit, ...upload.editImageInfo },
            lowResolutionWarning: lowRezWarning,
            lowResolutionByPass: lowRezWarning,
            changeSize: product.sizeChanged,
        });

        if (success) {
            await dispatch(assignSession(data));

            await history.push('/upload', { from: '/edit' });
            await dispatch(await addFilesToUpload(intl));
            uppy.setState({
                printableProduct: {
                    sessionId: data.id,
                    title: getProductTitle(intl, product.productData,true),
                },
            });
            return await uppy.upload();
        }
        await history.push('/auth/login', { from: '/edit' });
        await dispatch(await addFilesToUpload(intl));

        history.push('/auth/login', { from: '/edit' });
    };

    const AppModals = {
        ImageWarning: () => {
            let modalTitle = '';
            let modalDescription = '';
            if (upload?.validations?.issues?.imageResolution) {
                modalTitle = intl.formatMessage(issueTranslations.imageResolution.translation.modalTitle);
                modalDescription = intl.formatMessage(issueTranslations.imageResolution.translation.modalDescription);
            }

            return (
                <Modals.Confirmation
                    isOpen={purchaseWarning}
                    onClose={(tealium = true) => {
                        if (tealium) {
                            handleTealiumClick('warning-change-my-image-button-close');
                        }

                        setPurchaseWarning(false);
                    }}
                    title={modalTitle}
                    modalWidth="265px"
                    button1={{
                        id: 'warning-change-my-image-button',
                        name: intl.formatMessage({
                            id: 'warning-change-my-image-button',
                            defaultMessage: 'Change my image',
                        }),
                        onClick: () => {
                            handleTealiumClick(
                                intl.formatMessage({
                                    id: 'warning-change-my-image-button',
                                    defaultMessage: 'Change my image',
                                })
                            );
                            dispatch(switchEditScreen(editScreens.image));
                            const el = document.getElementById('my-file-input-again');
                            if (el && el.click) el.click();
                        },
                    }}
                    button2={{
                        id: 'warning-continue-to-cart-button',
                        name: intl.formatMessage({
                            id: 'warning-continue-to-cart-button',
                            defaultMessage: 'Continue to cart',
                        }),
                        onClick: async () => {
                            handleTealiumClick(
                                intl.formatMessage({
                                    id: 'warning-continue-to-cart-button',
                                    defaultMessage: 'Continue to cart',
                                })
                            );
                            return await handleAddToCart();
                        },
                    }}
                >
                    <Box>
                        <Box>
                            <WarningIconFull
                                width="32px"
                                height="28px"
                                position="absolute"
                                marginTop="-1px"
                                marginLeft="-20px"
                            />
                            <MaterialIcon width="80px" height="80px" color="#3399A2" disabled />
                        </Box>
                        <Box
                            fontSize="16px"
                            lineHeight="19px"
                            textAlign="center"
                            letterSpacing="0.7px"
                            marginTop="21px"
                            marginX="15px"
                        >
                            {modalDescription}
                        </Box>
                    </Box>
                </Modals.Confirmation>
            );
        },

        BackButtonWarning: () => {
            return (
                <Modals.Confirmation
                    id="back-button-warning-modal"
                    isOpen={leaveEdit}
                    onClose={(tealium = true) => {
                        if (tealium) {
                            handleTealiumClick('back-button-warning-modal-close');
                        }
                        setLeaveEdit(false);
                    }}
                    modalWidth="230px"
                    button1={{
                        name: intl.formatMessage({ id: 'back-button-warning-continue', defaultMessage: 'Continue' }),
                        onClick: () => {
                            ctEventsService.sendCloseProject(
                                upload?.project_id,
                                'Click on back button to leave the project'
                            );
                            handleTealiumClick(
                                intl.formatMessage({ id: 'back-button-warning-continue', defaultMessage: 'Continue' })
                            );

                            window.location.replace('https://www.blurb.com/custom-wall-art');
                        },
                        id: 'back-button-warning-modal-button-continue',
                    }}
                    button2={{
                        name: intl.formatMessage({ id: 'back-button-warning-cancel', defaultMessage: 'Cancel' }),
                        onClick: () => {
                            handleTealiumClick(
                                intl.formatMessage({ id: 'back-button-warning-cancel', defaultMessage: 'Cancel' })
                            );
                        },
                        id: 'back-button-warning-modal-button-cancel',
                    }}
                >
                    <Box>
                        <img src={blurbLogo} width="52px" />
                        <Box
                            fontSize="16px"
                            lineHeight="19px"
                            textAlign="center"
                            letterSpacing="0.7px"
                            marginTop="21px"
                        >
                            {intl.formatMessage({
                                id: 'back-button-warning-are-you-sure-you-want-to-leave',
                                defaultMessage:
                                    'Are you sure you want to leave your Wall Art? Any changes made will not be saved.',
                            })}
                        </Box>
                    </Box>
                </Modals.Confirmation>
            );
        },
    };

    return (
        <Box>
            <AppModals.ImageWarning />
            <AppModals.BackButtonWarning />

            <CPNT.Base {...props}>
                <CPNT.BackButton
                    onClick={() => {
                        handleTealiumClick(intl.formatMessage({ id: 'top-nav-back', defaultMessage: 'BACK' }));
                        setLeaveEdit(true);
                    }}
                    id={'top-nav-back'}
                >
                    <LeftChevron width="16px" height="16px" />
                    <CPNT.BackText>
                        {' '}
                        {intl.formatMessage({ id: 'top-nav-back', defaultMessage: 'BACK' })}{' '}
                    </CPNT.BackText>
                </CPNT.BackButton>

                <CPNT.CartButton
                    id={'add-to-cart-button'}
                    disabled={!pathname.startsWith('/edit')}
                    display={pathname.startsWith('/auth') ? 'none' : 'initial'}
                    onClick={() => {
                        if (!pathname.startsWith('/edit')) {
                            handleTealiumClick(intl.formatMessage({ id: 'add-to-cart', defaultMessage: 'Add to Cart' }) + "disabled")
                            return;
                        }
                        handleTealiumClick(intl.formatMessage({ id: 'add-to-cart', defaultMessage: 'Add to Cart' }))
                        if (upload?.validations?.issues?.imageResolution) {
                            return setPurchaseWarning(true);
                        }
                        return handleAddToCart();
                    }}
                >
                    {intl.formatMessage({ id: 'add-to-cart', defaultMessage: 'Add to Cart' })}
                </CPNT.CartButton>
            </CPNT.Base>
        </Box>
    );
};
