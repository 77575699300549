import React, { useMemo } from 'react';
import canvasTypes from './canvasTypes';
import { Box } from '../Box';
import big from '../../design/images/canvas/general.png';
import './style.css';
import { CanvasImage } from '../CanvasImage';
import { nextStepEdit } from '../../../redux/fileReducer';
import { calculateAspectRatioForDoka } from '../../../utils/productService';
import { useDispatch, useSelector } from 'react-redux';
import { handleTealiumClick } from '../../../utils/serverUtil';

export const CanvasPreview = ({ url, material, orientation, size, bleed, imageSize, ...props }) => {
    const sizeArray = useMemo(() => size.split('x'), [size]);
    const product = useSelector(state => state.product);
    const dispatch = useDispatch();

    const sizeMultiplier = 1.13;

    return (
        <Box position="relative" {...props}>
            <Box
                as="img"
                display="block"
                height="100%"
                width="auto"
                id={"canvas-image-background"}
                backfaceVisibility="hidden"
                src={big}
            />
            <Box
                position="absolute"
                width={`${sizeArray[0] * sizeMultiplier}%`}
                height={`${sizeArray[1] * sizeMultiplier}%`}
                top="25%"
                left="51.5%"
                transform="translate(-50%, -50%)"
            >
                <CanvasImage
                    url={url}
                    id={"canvas-image"}
                    as={'button'}
                    onClick={() => {
                        handleTealiumClick('sizing screen photo click');
                        return dispatch(
                            nextStepEdit(calculateAspectRatioForDoka(product.id, product.orientation), 'crop')
                        );
                    }}
                    imageSize={imageSize}
                    cropBleed={bleed}
                    width="100%"
                    boxShadow="-3px 3px 5px 0px rgba(0,0,0,0.37)"
                />
            </Box>
        </Box>
    );
};
