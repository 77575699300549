import React from 'react';
import { Box } from '../../components/design/Box';
import { WarningIconFull } from '../../components/design/icons/WarningIconFull';
import {useIntl} from 'react-intl';
import Tooltips from '../../components/design/tooltips/Tooltips';
import {handleTealiumClick} from "../../utils/serverUtil";

export default {
    EditButton: ({ ...props }) => <Box
        {...props}
        as="a"
        textAlign="center"
        pb={2}
        cursor="pointer"
        fontWeight="bold"
        pt={2}
        fontSize="15px"
        textDecoration="underline"
        color="blurb_cyan.25"
    />,

    ContainerColumn: ({ ...props }) => <Box
        {...props}
        display="flex"
        justifyContent="flex-start"
        width="100%"
        maxHeight="100%"
        flexDirection="column"
        marginTop="5%"
        position="relative"
    />,

    Container: ({ ...props }) => <Box {...props}
        display="flex"
        width="100%"
        justifyContent="center"
        position="relative"
    />,

    Description: ({ ...props }) => <Box
        {...props}
        display="flex"
        justifyContent="center"
        flexDirection="row"
        marginBottom={["4px","18px"]}
        textAlign="center"
        pb={2}
        fontSize="20px"
        color="gray.400"
    />,

    SwitchLabel: ({ active = false, ...props }) => <Box
        {...props}
        fontSize="12px"
        lineHeight="14px"
        textAlign="center"
        cursor={"pointer"}
        color={active ? '#4a4a4a' : '#acacad'}
        marginLeft="15px"
        marginRight="15px"
        fontWeight="bold"
    />,

    SwitchRow: ({ ...props }) => <Box
        {...props}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop={['15px', '20px']}
        alignItems="baseline"
    />,

    ImgContainer: ({ ...props }) => <Box
        {...props}
        display="flex"
        flexDirection="column"
        position="relative"
        alignItems="center"
        justifyContent="flex-start"
    />,

    WarningTooltip: ({ title, description }) => {
        const intl = useIntl();
        if (!title) return null;
        return (
            <Box
                position="absolute"
                marginTop="-30px"
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                marginLeft="-5px"
            >
                <Tooltips.Info
                    id={"learn-more-tooltip"}
                    placement="bottomRight"
                    trigger={["click", "hover"]}
                    title={title}
                    description={description}
                    link={intl.formatMessage({id:'learn-more-tooltip', defaultMessage:'Learn More'})}
                    children={ <WarningIconFull width="25px" height="25px" ml="10px"/> }
                    onClick={()=>handleTealiumClick(intl.formatMessage({id:'learn-more-tooltip', defaultMessage:'Learn More'}))}
                    url="https://support.blurb.com/hc/en-us/articles/360041191211"
                />
            </Box>
        );
    },

    NormalContainer: ({ ...props }) => <Box
        {...props}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
    />
}
