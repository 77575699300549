import { Box } from '../../components/design/Box';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import authAsset from './auth-asset.svg';
import { BlurbLogo } from '../../components/design/icons/BlurbLogo';
import PseudoBox from '../../components/design/PseudoBox';
import SupportIcon from '../../components/design/icons/SupportIcons';
import { getSupportUrl } from '../../index';
import { handleTealiumClick } from '../../utils/serverUtil';

export const AuthScreenWrapper = ({ children }) => {
    let history = useHistory();
    let upload = useSelector(state => state.upload);
    const intl = useIntl();

    const subHeaderHeight = 100;
    const footerHeight = 67;
    const subHeaderPadding = 10;
    const gradientHeight = 25;
    return (
        <>
            <Box
                paddingLeft="30px"
                paddingRight="18px"
                display="flex"
                justifyContent={'space-between'}
                alignItems={'center'}
                paddingY={`${subHeaderPadding}px`}
                height={`${subHeaderHeight}px`}
            >
                <Box as={'a'} href={'https://www.blurb.com/'} onClick={e => handleTealiumClick('blurb logo clicked')}>
                    <BlurbLogo width={70} />
                </Box>

                <Box display={['none', 'flex']} paddingRight={'10%'} whiteSpace={'nowrap'}>
                    <PseudoBox
                        paddingX={'20px'}
                        as={Link}
                        to={'/auth/sign-up'}
                        textTransform={'uppercase'}
                        onClick={() =>
                            handleTealiumClick(intl.formatMessage({ id: 'sign-up-button', defaultMessage: 'Sign up' }))
                        }
                        paddingBottom={'3px'}
                        cursor={'pointer'}
                        borderBottom="1px solid white"
                        textDecoration={'none'}
                        color={'black'}
                        _hover={{
                            paddingBottom: '3px',
                            borderBottom: '1px solid #000',
                        }}
                    >
                        {intl.formatMessage({ id: 'sign-up-button', defaultMessage: 'Sign up' })}{' '}
                    </PseudoBox>
                    <PseudoBox
                        as={Link}
                        to={'/auth/login'}
                        paddingX={'20px'}
                        textTransform={'uppercase'}
                        onClick={() =>
                            handleTealiumClick(intl.formatMessage({ id: 'log-in-button', defaultMessage: 'Log in' }))
                        }
                        paddingBottom={'3px'}
                        cursor={'pointer'}
                        borderBottom="1px solid white"
                        textDecoration={'none'}
                        color={'black'}
                        _hover={{
                            paddingBottom: '3px',
                            borderBottom: '1px solid #000',
                        }}
                    >
                        {intl.formatMessage({ id: 'log-in-button', defaultMessage: 'Log in' })}{' '}
                    </PseudoBox>
                    <PseudoBox
                        paddingX={'20px'}
                        as={'a'}
                        href={'https://support.blurb.com/hc/en-us/articles/360041191211 '}
                        textTransform={'uppercase'}
                        onClick={() =>
                            handleTealiumClick(intl.formatMessage({ id: 'help_button_text', defaultMessage: 'Help' }))
                        }
                        paddingBottom={'3px'}
                        cursor={'pointer'}
                        borderBottom="1px solid white"
                        textDecoration={'none'}
                        color={'black'}
                        _hover={{
                            borderBottom: '1px solid #000',
                        }}
                    >
                        {intl.formatMessage({ id: 'help_button_text', defaultMessage: 'Help' })}{' '}
                    </PseudoBox>
                </Box>
                <Box display={['flex', 'none']} alignItems={'center'} paddingRight={'5%'}>
                    <SupportIcon />
                    <Box
                        fontWeight={'bold'}
                        color={'blurb_cyan.25'}
                        text
                        fontSize={'15px'}
                        cursor={'pointer'}
                        paddingLeft={'10px'}
                        textTransform={'uppercase'}
                        onClick={() =>
                            handleTealiumClick(intl.formatMessage({ id: 'support_text', defaultMessage: 'Support' }))
                        }
                        textDecoration={'underline'}
                        letterSpacing={'0.7px'}
                        lineHeight={'17px'}
                        as={'a'}
                        href={getSupportUrl(intl.locale)}
                    >
                        {intl.formatMessage({ id: 'support_text', defaultMessage: 'Support' })}{' '}
                    </Box>
                </Box>
            </Box>
            <Box
                height={[`calc(100% - ${subHeaderHeight + footerHeight + 2 * subHeaderPadding}px)`]}
                backgroundImage={'linear-gradient(lightgray, white)'}
                backgroundSize={`100% ${gradientHeight}px`}
                backgroundRepeat={'no-repeat'}
            >
                <Box display={['block', 'flex']} height={['initial', '100%']}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        paddingTop={['10%', '0px']}
                        justifyContent={'center'}
                        width={['initial', '50%']}
                        height={'100%'}
                    >
                        <Box marginX={['20px', '20%']}>{children}</Box>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        width={['100%', '50%']}
                        zIndex={-1}
                        paddingTop={`${gradientHeight - 20}px`}
                        background={'#E2CAB7'}
                    >
                        <Box as={'img'} src={authAsset} height={300} />
                    </Box>
                </Box>
                <Box height={'67px'} background={'#333333'} />
            </Box>
        </>
    );
};
