import { load } from 'exifreader';
import { MAX_FILE_SIZE_MB } from './Constants';
import { FILE_PARSED } from '../redux/fileReducer';
import iccSRGB from '../icc/sRGB_IEC61966-2-1_no_black_scaling.icc';

import loadImage from 'blueimp-load-image';
import ctEventsService from './ctEvents/CTEventsService';
import store from '../redux/store';
import Cookies from 'js-cookie';

export function convertDataURI(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return { blob: new Blob([ab], { type: mimeString }), arrayBuffer: ab };
}
export const cropToAspect = async (url, aspectRatio) => {
    const canvasPromise = new Promise(resolve => {
        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {
            // let's store the width and height of our image
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            // if it's bigger than our target aspect ratio
            let outputWidth = inputWidth;
            let outputHeight = inputHeight;
            if (inputImageAspectRatio > aspectRatio) {
                outputWidth = inputHeight * aspectRatio;
            } else if (inputImageAspectRatio < aspectRatio) {
                outputHeight = inputWidth / aspectRatio;
            }

            // calculate the position to draw the image at
            const outputX = (outputWidth - inputWidth) * 0.5;
            const outputY = (outputHeight - inputHeight) * 0.5;

            // create a canvas that will present the output image
            let outputImage = document.createElement('canvas');

            // set it to the same size as the image
            outputImage.width = outputWidth;
            outputImage.height = outputHeight;

            // draw our image at position 0, 0 on the canvas
            let ctx = outputImage.getContext('2d');
            ctx.drawImage(inputImage, outputX, outputY);
            resolve(outputImage);
            ctx = null;
            outputImage = null;
        };

        // start loading our image
        inputImage.src = url;
    });

    const canvasResult = await canvasPromise;
    const dataURL = canvasResult.toDataURL('image/jpeg');
    let {blob} = convertDataURI(dataURL);
    return {
        url: dataURL,
        blob: blob,
        imageInfo: {
            width: canvasResult.width,
            height: canvasResult.height,
        },
    };
};


export function initialValidationState() {
    return {
        issues: {
            fileSize: null,
            imageResolution: null,
            type: null,
            colorSpace: null,
            colorProfile: null,
            dpi: null,
            aspect: null,
            unknown: null,
        },
        success: true,
    };
}

function hasString(dataView, idString, offset) {
    for (let i = 0; i < idString.length; i++) {
        if (dataView.getUint8(offset + i) !== idString.charCodeAt(i)) {
            return false;
        }
        return true;
    }
}

function removeICCProfile(arrayBuffer) {
    const dv = new DataView(arrayBuffer);
    let totalLength = dv.byteLength;
    let offset = 0;
    let recess = 0;
    const pieces = [];
    let i = 0;

    if (dv.getUint16(offset) !== 0xffd8) {
        throw new Error('Not a JPEG File, invalid header');
    }
    offset = 2;

    while (offset < dv.byteLength) {
        const id = dv.getUint16(offset);
        offset += 2;

        if (id === 0xffe2 || id === 0xffe1) {
            // ICC profile in APP2 or XMP

            let remove = true;
            if (id === 0xffe1) {
                const xmpId = 'http://ns.adobe.com/xap/1.0/\0';
                const xmpExtensionId = 'http://ns.adobe.com/xmp/extension/\0';

                if (hasString(dv, xmpId, offset + 2)) {
                    console.debug('XMP block found, deleting');
                } else if (hasString(dv, xmpExtensionId, offset + 2)) {
                    console.debug('XMP extension found, deleting');
                } else {
                    remove = false;
                }
            }

            if (remove) {
                pieces[i] = { recess, offset: offset - 2 };
                recess = offset + dv.getUint16(offset);
                totalLength -= dv.getUint16(offset) + 2;
                i += 1;
            } else {
                console.log('Will not remove ', id);
            }
        } else if (id === 0xffda || id === 0xffd9) {
            // SOS or EOI
            break;
        } else if (id === 0xffd8) {
            // SOI has no length
        }
        offset += dv.getUint16(offset);
    }
    if (pieces.length > 0) {
        const newOutput = new Uint8Array(totalLength);
        let newOutputOffset = 0;
        pieces.forEach(v => {
            if (v.recess === v.offset) return;
            const array = arrayBuffer.slice(v.recess, v.offset);

            newOutput.set(new Uint8Array(array), newOutputOffset);
            newOutputOffset += array.byteLength;
        }, this);

        const lastSlice = arrayBuffer.slice(recess);
        newOutput.set(new Uint8Array(lastSlice), newOutputOffset);
        return newOutput.buffer;
    }
    return arrayBuffer;
}

function addIccProfile(inputFile, iccProfile) {
    const inputView = new DataView(inputFile);

    const outputAB = new ArrayBuffer(inputFile.byteLength + 4 + 14 + iccProfile.byteLength);
    const output = new Uint8Array(outputAB);
    const outputView = new DataView(outputAB);
    const input = new Uint8Array(inputFile);

    let offset = 2; // skip APP0 and APP1 as they must come first in JPEG file
    while (true) {
        const marker = inputView.getUint16(offset);
        if (marker === 0xffe0 || marker === 0xffe1) {
            offset += inputView.getUint16(offset + 2) + 2;
        } else break;
    }

    output.set(input.slice(0, offset), 0); // copy origin

    outputView.setUint16(offset, 0xffe2); // APP02
    outputView.setUint16(offset + 2, iccProfile.byteLength + 2 + 14); // APP02 length

    const id = 'ICC_PROFILE\0';
    for (let i = 0; i < id.length; i++) {
        outputView.setUint8(offset + 4 + i, id.charCodeAt(i));
    }
    outputView.setUint8(offset + 16, 1);
    outputView.setUint8(offset + 17, 1);

    output.set(new Uint8Array(iccProfile), offset + 18); // copy icc profile

    const rest = input.slice(offset);
    output.set(rest, offset + 18 + iccProfile.byteLength);

    return outputAB;
}

export function parseExif(tags) {
    const retVal = {};
    retVal.width = tags.file['Image Width'].value;
    retVal.height = tags.file['Image Height'].value;
    retVal.aspect = retVal.width / retVal.height;
    retVal.colorComponents = tags.file['Color Components'].value || 3;

    if (tags.icc && tags.icc['ICC Description']) {
        retVal.iccProfile = tags.icc['ICC Description'].value;
    }
    return retVal;
}

// function getValidationMessages(validationResults) {
//     if (!validationResults.success) {
//         if (validationResults.fileSize) {
//             return [validationResults.fileSize];
//         }
//         if (validationResults.type) {
//             return [validationResults.type];
//         }
//         if (validationResults.colorSpace) {
//             return [validationResults.colorSpace];
//         }
//         if (validationResults.unknown) {
//             return [validationResults.unknown];
//         }
//     }
//
//     let messages = [];
//     if (validationResults.imageResolution) {
//         messages.push(validationResults.imageResolution);
//     }
//     if (validationResults.colorProfile) {
//         messages.push(validationResults.colorProfile);
//     }
//     if (validationResults.colorSpace) {
//         messages.push(validationResults.colorSpace);
//     }
//     return messages;
// }

export function validateJpeg(tags, file) {
    let { issues } = initialValidationState();
    const imageInfo = parseExif(tags);

    if (file.fileSize > MAX_FILE_SIZE_MB * 1000 * 1000)
        issues.size = {
            titleId: 'error-max-file-size',
            title: `File is larger than ${MAX_FILE_SIZE_MB}MB`,
            descriptionId: 'error-max-file-size-description',
            description: '',
        };
    if (file.fileSize === 0)
        issues.fileSize = {
            titleId: 'error-file-empty',
            title: 'File is empty',
            descriptionId: 'error-file-empty-description',
            description: '',
        };

    if (file.type !== 'image/jpeg')
        issues.type = {
            titleId: 'error-only-jpeg',
            title: `Only JPEG images are supported`,
            descriptionId: 'error-only-jpeg-description',
            description: '',
        };

    if (imageInfo.colorComponents !== 3) {
        issues.colorSpace = {
            titleId: 'error-color-components',
            title: 'Only RGB images are supported',
            descriptionId: 'error-color-components-description',

            description: '',
        };
        // 'CMYK or other non-RGB images are not supported. Convert the image to RGB, preferably with the sRGB Color Profile';
    } else if (imageInfo.iccProfile != null && !/.*IEC.*61966.*/i.test(imageInfo.iccProfile)) {
        issues.colorProfile = {
            titleId: 'error-color-profile',
            title: `This image has a non-supported Color Profile  ${imageInfo.iccProfile}. Blurb recommends using sRGB Color profile, or you can proceed and we will remove the current profile`,
            descriptionId: 'error-color-profile-description',
            description: '',
        };
    }
    // const { width, height } = imageInfo;

    // if (width < 720 || height < 720)
    //     validationResults.imageResolution = {
    //         title: 'Low Resolution!',
    //         description: 'May appear blurry when printed.',
    //     }; // minimal resolution for minimal product
    // if (width < 720 || height < 720)
    //     validationResults.imageResolution = {
    //         title: 'Low Resolution!',
    //         description: 'May appear blurry when printed.',
    //     }; // minimal resolution for minimal product

    let success = true;
    if (issues.fileSize !== null || issues.type !== null || issues.colorSpace !== null) {
        success = false;
    }

    return { issues, success };
}

async function readSRGBIccProfile() {
    const iccData = await fetch(iccSRGB);
    return iccData.arrayBuffer();
}

async function rotateImage(blob, url) {
    return new Promise(async (resolve, reject) => {
        const rotationResult = await loadImage(
            blob,
            async img => {
                const dataUrl = img.toDataURL('image/jpeg');

                let { blob, arrayBuffer } = convertDataURI(dataUrl);

                if (url) window.URL.revokeObjectURL(url);
                const imageUrl = window.URL.createObjectURL(blob);
                resolve({ blob, arrayBuffer, imageUrl });
            },
            {
                orientation: true,
                canvas: true,
            }
        );
    });
}

const validate = (data, blob, url) => {
    if (url) window.URL.revokeObjectURL(url);
    const imageUrl = window.URL.createObjectURL(blob);

    const tags = load(data, { expanded: true });
    const validations = validateJpeg(tags, blob);

    const imageInfo = parseExif(tags);

    return { validations, imageInfo, imageUrl, tags };
};

export const parseFile = async (file, initialProcessing, url) => {
    console.log('ParseFile', file, initialProcessing, url);
    let blob = file.hasOwnProperty('data') ? file.data : file;
    const { upload } = store.getState();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let data = null;
        reader.onload = async function(readerEvent) {
            try {
                data = readerEvent.target.result;
                let validationResult = validate(data, blob, url);
                let { validations, imageInfo, imageUrl, tags } = validate(data, blob, url);

                let removeIcc = false;
                if (validations.success && validations.issues.colorProfile !== null && initialProcessing) {
                    removeIcc = true;
                    data = removeICCProfile(data);
                    const sRGBProfile = await readSRGBIccProfile();

                    data = addIccProfile(data, sRGBProfile);
                    blob = new Blob([data], { type: 'image/jpeg' });
                    let { validations, imageInfo, imageUrl, tags } = validate(data, blob, url);
                }

                if (!validations.success) {
                    ctEventsService.sendImageImportError(
                        upload?.project_id,
                        JSON.stringify(validations?.issues)
                    );
                }
                if (initialProcessing) {
                    let {
                        blob: rotatedBlob,
                        arrayBuffer: rotatedBuffer,
                        imageUrl: rotatedImageUrl,
                    } = await rotateImage(blob, url);
                    const tags = load(rotatedBuffer, { expanded: true });
                    imageInfo = parseExif(tags);
                    imageUrl = rotatedImageUrl;
                    blob = rotatedBlob;
                }

                resolve({
                    type: FILE_PARSED,
                    exif: tags,
                    validations,
                    blob,
                    filename: file.name,
                    imageInfo,
                    removeIcc: removeIcc,
                    url: imageUrl,
                });
            } catch (error) {
                console.error('parse file error', error.message);

                let { issues } = initialValidationState();

                if (
                    error.message.startsWith('Not a JPEG File') ||
                    error.message.includes('Invalid image format') ||
                    (file && file.type && file.type.includes('png'))
                ) {
                    issues.type = {
                        titleId: 'error-only-jpeg',
                        title: `Only JPEG images are supported`,
                        descriptionId: 'error-only-jpeg-description',
                        description: '',
                    };
                } else {
                    issues.unknown = {
                        titleId: 'error-unknown',
                        title: ``,
                        descriptionId: 'error-unknown-description',
                        description: '',
                    };
                }
                let validation = { issues, success: false };
                ctEventsService.sendImageImportError(upload?.project_id, JSON.stringify(issues));
                if (file && file.type && file.type.includes('png') && data) {
                    let blob = new Blob([data], { type: 'image/png' });
                    const imageUrl = window.URL.createObjectURL(blob);
                    resolve({
                        type: FILE_PARSED,
                        exif: {},
                        validations: validation,
                        url: imageUrl,
                        blob,
                    });
                    return;
                }

                resolve({
                    type: FILE_PARSED,
                    exif: {},
                    validations: validation,
                    url: null,
                    blob,
                });
            }
        };

        reader.readAsArrayBuffer(blob);
    });
};
