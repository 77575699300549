import allProducts from '../utils/allProducts';
import { findInvert, findProductByMaterial, findProductId, getAllSizes } from '../utils/productRepository';
import { validateResolution } from '../utils/productService';
import { updateValidations } from './fileReducer';

export const PRODUCT_CHANGED = 'productData/changed';
export const PRODUCT_SIZE_CHANGED = 'productData/size';
export const ORIENTATION_CHANGED = 'productData/orientation';
export const AUTODETECT_RESULT = 'productData/autodetect/result';
export const MATERIAL_CHANGED = 'material/changed';

export const OrientationLandscape = 'landscape';
export const OrientationPortrait = 'portrait';
export const OrientationDetect = 'detect';

export const autoDetectResult = productData => dispatch => {
    dispatch({ type: AUTODETECT_RESULT, productData });
};

export const orientationChange = () => (dispatch, getState) => {
    dispatch({ type: ORIENTATION_CHANGED });
    const { upload, product } = getState();

    const { validations: resolutionValidation } = validateResolution(upload, product, upload.validations);
    dispatch(updateValidations(resolutionValidation));
};

export const productSizeChange = productData => (dispatch, getState) => {
    dispatch({ type: PRODUCT_SIZE_CHANGED, productData });
    const { upload, product } = getState();
    const { validations: resolutionValidation } = validateResolution(upload, product, upload.validations);
    dispatch(updateValidations(resolutionValidation));
};

export const productMaterialChange = finish_type => (dispatch, getState) => {
    dispatch({ type: MATERIAL_CHANGED, finish_type });
    const { upload, product } = getState();

    const { validations: resolutionValidation } = validateResolution(upload, product, upload.validations);
    dispatch(updateValidations(resolutionValidation));
};

const initialProduct = allProducts[0];
const initialSize = getAllSizes(initialProduct)[0];
const orientationControl = initialProduct.orientation ==="square" ? OrientationPortrait : initialProduct.orientation
export const initialState = {
    productData: initialProduct,
    size: initialSize,
    id: initialProduct.id,
    sizeChanged:false,
    orientation: OrientationDetect,
    orientationControl: orientationControl,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTODETECT_RESULT: {
            const productData = action.productData;
            const orientationControl = productData.orientation ==="square" ? OrientationPortrait : productData.orientation

            return {
                ...state,
                size: productData.trim_text,
                id: productData.id,
                productData: productData,
                orientation: action.orientation,
                orientationControl: orientationControl,
            };
        }

        case ORIENTATION_CHANGED: {
            const invert = findInvert(state.productData);
            const currentControl = state.orientationControl;
            const invertedControl =
                currentControl === OrientationLandscape ? OrientationPortrait : OrientationLandscape;
            return {
                ...state,
                size: invert.trim_text,
                id: invert.id,
                productData: invert,
                orientation: invert.orientation,
                orientationControl: invertedControl,
            };
        }

        case PRODUCT_CHANGED: {
            let productId = null;
            if (action.productData !== null && state.size !== null) {
                productId = findProductId(action.productData, state.size);
                if (!productId) productId = findProductId(action.productData);
            }
            let currentControl = null;
            if (action.productData.orientation === 'square') {
                currentControl = state.orientationControl;
            } else {
                currentControl = action.productData.orientation;
            }

            return {
                ...state,
                productData: action.productData,
                id: productId,
            };
        }

        case PRODUCT_SIZE_CHANGED: {
            return {
                ...state,
                productData: action.productData,
                id: action.productData.id,
                orientation: action.productData.orientation,
                sizeChanged:true
            };
        }

        case MATERIAL_CHANGED: {
            const productData = findProductByMaterial(action.finish_type, state.productData);
            return {
                ...state,
                productData,
                id: productData.id,
            };
        }

        default: {
            return { ...state };
        }
    }
};
export default reducer;
