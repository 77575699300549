import { combineReducers } from 'redux';
import uploadReducer from './fileReducer';
import productReducer from './productReducer';
import apiReducer from './apiReducer';
import editScreenReducer from './editScreenReducer';

export default combineReducers({
    upload: uploadReducer,
    product: productReducer,
    api: apiReducer,
    editScreen: editScreenReducer,
});
