import React from 'react';
import Modal from 'react-modal';
import style from './style.json';
import { CloseIcon } from '../../icons/CloseIcon';
import CPNT from './components';

export const Error = (props) => {
    const {
        isOpen,
        onClose,
        title,
        children,
        button,
        noDivider,
        modalWidth
    } = props;

    const handleSubmit = (fun) => {
        fun();
        return onClose(false);
    }

    const ModalBody = () => (
      <CPNT.Base width={ modalWidth }>
        <CPNT.CloseBox onClick={() => onClose()}>
          <CloseIcon width="12px" height="12px" color="#979797" disabled/>
        </CPNT.CloseBox>

        { title &&
          <CPNT.Title>
            { title }
            { !noDivider && <CPNT.Divider /> }
          </CPNT.Title>
        }

        { children &&
          <CPNT.ChildrenBox>
            { children }
          </CPNT.ChildrenBox>
        }

        { button &&
          <CPNT.ButtonBox>
            <CPNT.Button onClick={() => handleSubmit(button?.onClick)} >
              { button?.name }
            </CPNT.Button>
          </CPNT.ButtonBox>
        }
      </CPNT.Base>
    );

    return (
      <Modal
        closeTimeoutMS={700}
        isOpen={isOpen}
        onRequestClose={onClose}
        style={style}
        contentLabel="Confirmation Modal"
      >
        <ModalBody />
      </Modal>
    );
};
