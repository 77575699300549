import { Box } from '../Box';
import React from 'react';

export const Input = ({ border, width, ...props }) => {
    return (
        <Box
            {...props}
            padding={'10px'}
            border={border || '1px solid #e9e9e9'}
            width={width || '100%'}
            boxSizing={'border-box'}
            backgroundColor={'whitesmoke'}
            as={'input'}
        />
    );
};
