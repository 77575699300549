import { cropToAspect, parseFile } from '../utils/jpegUtils';
import uppy from './uppy';
import { uuid } from 'uuidv4';
import {
    calculateAspectRatio,
    calculateAspectRatioForDoka,
    getProductTitle,
    validateResolution,
} from '../utils/productService';

export const FILE_PARSED = 'file/parse/request';
export const UPDATE_IMAGE = 'file/update';
export const NEXT_STEP_EDIT = 'file/next_step/edit';
export const BACK_FROM_EDIT = 'file/back_from_edit';
export const NEW_IMAGE = 'file/new';
export const CANCEL_EDIT = 'file/cancel_edit';
export const UPLOAD_START = 'upload/start';
export const UPLOAD_RESET = 'upload/reset';
export const UPLOAD_PROGRESS = 'upload/progress';
export const UPLOAD_SUCCESS = 'upload/success';
export const UPLOAD_PROCESSING = 'upload/processing';
export const UPLOAD_ERROR = 'upload/error';
export const VALIDATE = 'validate/all';
export const LOGIN = 'login/init';

const initialState = {
    blob: null,
    url: null,
    modalEnabled: false,
    filename: null,
    exif: null,
    validations: null,
    imageInfo: null,
    requestedAspect: null,
    editJson: [],
    originalFile: null,
    dokaOverlay: false,
    editMode: false,
    uploadError: false,
    project_id: uuid(),
    clickedOnEdit:false,
    editImageInfo: {crop:false,markup:false,color:false,filter:false},
    uploading: {
        uploadProcessing: {
            started: false,
            finished: false,
            successful: null,
            reason: null,
        },
        image: {
            bytesUploaded: 0,
            bytesTotal: 0,
            location: null,
        },
        analytics: {
            bytesUploaded: 0,
            bytesTotal: 0,
            location: null,
        },
    },
};

// export const removeColorProfileAndContinue = () => async (dispatch, getState) => {
//     const { upload } = getState();
//
//     const data = { ...(await parseFile(upload.blob, true, upload.url)), type: FILE_PARSED };
//     dispatch(data);
// };

export const newImage = () => async dispatch => {
    dispatch({ type: NEW_IMAGE });
};

export const backFromEdit = (output,editImageInfo) => async (dispatch, getState) => {
    let { upload } = getState();

    const data = {
        ...(await parseFile(output.file, false, upload.url)),
        type: BACK_FROM_EDIT,
        editJson: output.data,
        editImageInfo:editImageInfo,
        dokaEditedFile: output.file,
    };

    dispatch(data);

    const { upload: uploadAfterDispatch, product } = getState();
    const { validations: resolutionValidation } = validateResolution(
        uploadAfterDispatch,
        product,
        uploadAfterDispatch.validations
    );
    dispatch(updateValidations(resolutionValidation));
};

export const uploadStart = (image, analytics) => dispatch => {
    dispatch({ type: UPLOAD_START, image, analytics });
};
export const uploadReset = () => dispatch => {
    dispatch({ type: UPLOAD_RESET });
};

export const updateValidations = validations => dispatch => {
    dispatch({ type: VALIDATE, validations });
};

export const uploadProcessing = (started, finished, successful, reason) => dispatch => {
    const uploadProcessing = { started, finished, successful, reason };
    dispatch({ type: UPLOAD_PROCESSING, uploadProcessing });
};
export const updateUploadLocation = location => dispatch => {
    dispatch({ type: UPLOAD_SUCCESS, location });
};

export const cancelEdit = () => async dispatch => {
    dispatch({ type: CANCEL_EDIT });
};

export const nextStepEdit = (requestedAspect, utils) => dispatch => {
    console.info('Requesting aspect ratio', requestedAspect);
    dispatch({ type: NEXT_STEP_EDIT, requestedAspect, dokaUtils: utils });
};

export const updateImageInfo = () => async (dispatch, getState) => {
    const { upload, product } = getState();
    const { url, blob, imageInfo } = await cropToAspect(upload.url, calculateAspectRatio(product.id));
    dispatch({ type: UPDATE_IMAGE, url, blob, imageInfo });
};
export const uploadErrorSet = uploadError => dispatch => {
    dispatch({ type: UPLOAD_ERROR, uploadError: uploadError });
};

function createStatsFile(intl, upload, product) {
    const { finish_type, printable_product_dimension } = product.productData;

    const notification = upload.validations.issues.imageResolution ?  `<notification status="ignored" container="${upload?.project_id}" type="image-resolution" page=""/>`:""
    const placeHolder = `
  <wallart schema="2.10" finishtype="${finish_type}" format="${printable_product_dimension}">
    <info>
        <title><![CDATA[${getProductTitle(intl, product.productData)}]]></title>
        <source><![CDATA[WebBuilder]]></source>
    </info>
    </wallart>
   ${notification}
  `;
    return placeHolder;
}

export const addFilesToUpload = intl => async (dispatch, getState) => {
    await dispatch(uploadReset());
    uppy.cancelAll();

    const { upload, product } = getState();
    const file = {
        name: upload.filename,
        data: upload.blob,
        type: 'image/jpeg',
        isRemote: false,
        handledByDoka: true,
    };

    uppy.addFile(file);
    const imageStats = createStatsFile(intl, upload, product);
    const historyBlob = new Blob([imageStats], { type: 'application/xml' });
    const statsFile = {
        name: 'metadata.xml',
        data: historyBlob,
        type: 'application/xml',
        isRemote: false,
        handledByDoka: true,
    };
    uppy.addFile(statsFile);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_IMAGE: {
            return initialState;
        }

        case FILE_PARSED: {
            if (action.validations.success && action.validations.issues.colorProfile === null) {
                const retVal = {
                    ...state,
                    url: action.url,
                    exif: action.exif,
                    validations: action.validations,
                    blob: action.blob,
                    imageInfo: action.imageInfo,
                    filename: action.filename || state.filename,
                    originalFile: action.file,
                };

                if (action.removeIcc) {
                    retVal.editJson.push({ [new Date().toISOString()]: 'Removed ICC profile' });
                }
                return retVal;
            }
            return {
                ...state,
                exif: action.exif,
                validations: action.validations,
                blob: action.blob,
                filename: action.filename || state.filename,
                imageInfo: action.imageInfo,
                originalFile: action.file,
                url: action.url,
            };
        }
        case UPDATE_IMAGE: {
            return {
                ...state,
                url: action.url,
                imageInfo: action.imageInfo,
                blob: action.blob,
            };
        }

        case LOGIN: {
            return {
                ...state,
            };
        }
        case UPLOAD_START: {
            const { uploading } = state;
            const { image, analytics } = uploading;
            return {
                ...state,
                uploading: {
                    ...uploading,
                    image: {
                        ...image,
                        ...action.image,
                    },
                    analytics: {
                        ...analytics,
                        ...action.analytics,
                    },
                },
            };
        }
        case UPLOAD_RESET: {
            return {
                ...state,
                uploading: initialState.uploading,
            };
        }

        case UPLOAD_PROGRESS: {
            const { uploading } = state;
            const { image, analytics } = uploading;
            if (image) {
                return {
                    ...state,
                    uploading: {
                        ...uploading,
                        image: {
                            ...image,
                            ...action.image,
                        },
                    },
                };
            }
            if (analytics) {
                return {
                    ...state,
                    uploading: {
                        ...uploading,
                        analytics: {
                            ...analytics,
                            ...action.analytics,
                        },
                    },
                };
            }

            return {
                ...state,
            };
        }
        case UPLOAD_PROCESSING: {
            const { uploading } = state;

            return {
                ...state,
                uploading: {
                    ...uploading,
                    uploadProcessing: action.uploadProcessing,
                },
            };
        }
        case UPLOAD_ERROR: {
            return {
                ...state,
                uploadError: action.uploadError,
            };
        }

        case VALIDATE: {
            return {
                ...state,
                validations: action.validations,
            };
        }

        case NEXT_STEP_EDIT: {
            return {
                ...state,
                dokaOverlay: true,
                dokaUtils: action.dokaUtils,
                requestedAspect: action.requestedAspect,
                editMode: true,
                clickedOnEdit:true
            };
        }
        case BACK_FROM_EDIT: {
            const retVal = {
                ...state,
                url: action.url,
                exif: action.exif,
                validations: action.validations,
                imageInfo: action.imageInfo,
                editImageInfo:action.editImageInfo,
                dokaOverlay: false,
                editMode: false,
            };

            retVal.editJson.push({ [new Date().toISOString()]: action.editJson });

            retVal.blob = action.dokaEditedFile;
            if (!state.url) {
                retVal.originalUrl = action.url;
            }

            return retVal;
        }
        case CANCEL_EDIT: {
            return {
                ...state,
                dokaOverlay: false,
                editMode: false,
            };
        }

        default: {
            return { ...state };
        }
    }
};
export default reducer;
