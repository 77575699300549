import { useState } from 'react';

export function useFormState(initialState = {}, fieldId = 'name') {
    const [formState, setFormState] = useState(initialState);
    const handleFormChange = event => {
        const key = event.target[fieldId];
        const value = event.target.value;
        setFormState(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };
    return { handleFormChange, formState };
}
