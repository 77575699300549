import { defineMessages } from 'react-intl';

export const issueTranslations = {
    imageResolution: {
        translation: defineMessages({
            title: {
                id: 'low-resolution-warning-title',
                defaultMessage: 'Low Resolution!',
            },
            description: {
                id: 'low-resolution-warning-description',
                defaultMessage: 'May appear blurry when printed',
            },
            modalTitle: {
                id: 'low-resolution-warning-modal-title',
                defaultMessage: 'Resolution Warning',
            },
            modalDescription: {
                id: 'low-resolution-warning-modal-description',
                defaultMessage:
                    'Due to the size of your image, the resolution is too low. Your Wall Art may appear blurry and pixelated when printed.',
            },
        }),
    },
};
