import React, { useState } from 'react';
import { Box } from '../../../components/design/Box';
import show from './show.png';
import hide from './hide.png';

import { AuthScreenWrapper } from '../AuthScreenWrapper';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
    AuthButton,
    AuthFooter,
    AuthInfo,
    AuthInput,
    AuthOptions,
    AuthPasswordLabel,
    AuthTitle,
    DescriptionLink,
    ShowHidePassword,
} from '../AuthScreenComponents';
import { useFormValidation } from '../../../components/hooks/useFormValidation';
import { validatePassword } from '../auth-validation';
import { useFormState } from '../../../components/hooks/useFormState';
import { useDispatch, useSelector } from 'react-redux';
import { assignSession } from '../../../redux/apiReducer';
import apiClient from '../../../components/apiClient';
import { getBlurbApiError, handleTealiumClick } from '../../../utils/serverUtil';
import uppy from '../../../redux/uppy';
import { ValidationResult } from '../../../components/hooks/ValidationResult';
import { getProductTitle } from '../../../utils/productService';

export const Login = ({ ...props }) => {
    const intl = useIntl();
    const { handleFormChange, formState } = useFormState({
        username: '',
        password: '',
    });
    const { errorState, validate, serverError, setServerError } = useFormValidation(formState, {
        username: username => ValidationResult.success(),
        password: validatePassword.bind(this, intl),
    });

    let history = useHistory();
    const product = useSelector(state => state.product);

    const { username: userNameError, password: passwordError } = errorState;

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { state } = useLocation();

    const handleSubmit = async event => {
        const validationSuccess = validate();
        if (!validationSuccess) {
            return;
        }
        const { username, password } = formState;
        const loginFormData = new FormData();
        loginFormData.append('username', username);
        loginFormData.append('password', password);
        loginFormData.append('api_key', process.env.REACT_APP_API_KEY);
        try {
            setLoading(true);
            const loginResponse = await apiClient.post(`/v1_1/logins.json`, loginFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (window?.utag?.link) {
                window.utag.link({
                    event_name: 'log-in-success',
                    event_category: 'log-in-success',
                    event_action: 'log-in-success',
                    event_label: 'success-result',
                });
            }
            const { web_session_id, id } = loginResponse.data;
            dispatch(assignSession(loginResponse.data));

            if (state?.from === '/edit') {
                uppy.setState({
                    printableProduct: {
                        sessionId: id,
                        title: getProductTitle(intl, product.productData,true),
                    },
                });

                history.push('/upload', {
                    from: '/auth/login,',
                });
                return await uppy.upload();
            } else {
                await history.push('/', { from: '/auth/login,' });
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            const { blurbApiMessage } = getBlurbApiError(e, intl);
            if (blurbApiMessage) {
                setServerError(blurbApiMessage);
            }
        }
    };

    return (
        <AuthScreenWrapper>
            <AuthTitle errorMessage={serverError}>
                {' '}
                {intl.formatMessage({ id: 'log-in-title', defaultMessage: 'Log in' })}{' '}
            </AuthTitle>
            <AuthInput id={"login-username"} name="username" value={formState.username} onChange={handleFormChange}>
                {' '}
                {intl.formatMessage({ id: 'enter-email-or-username-label', defaultMessage: 'Email or username' })}{' '}
            </AuthInput>
            <Box color={'red.300'}>{userNameError && userNameError.errorMessage}</Box>
            <AuthInput
                name="password"
                id={"login-password"}
                value={formState.password}
                onChange={handleFormChange}
                type={showPassword ? 'text' : 'password'}
            >
                <AuthPasswordLabel>
                    <Box>{intl.formatMessage({ id: 'enter-password-label', defaultMessage: 'Password' })}</Box>
                    <ShowHidePassword
                        onClick={() => {
                            handleTealiumClick(
                                showPassword
                                    ? intl.formatMessage({ id: 'signup-form-hide-password', defaultMessage: 'Hide' })
                                    : intl.formatMessage({ id: 'signup-form-show-password', defaultMessage: 'Show' })
                            );
                            setShowPassword(!showPassword);
                        }}
                        src={showPassword ? hide : show}
                    >
                        {showPassword
                            ? intl.formatMessage({ id: 'signup-form-hide-password', defaultMessage: 'Hide' })
                            : intl.formatMessage({ id: 'signup-form-show-password', defaultMessage: 'Show' })}
                    </ShowHidePassword>
                </AuthPasswordLabel>
            </AuthInput>
            <Box color={'red.300'}>{passwordError && passwordError.errorMessage}</Box>

            <AuthOptions>
                <Box>
                    <input
                        type={'checkbox'}
                        onChange={() => {
                            const text = intl.formatMessage({
                                id: 'keep-me-logged-in',
                                defaultMessage: 'Keep me logged in',
                            });
                            handleTealiumClick(text);
                        }}
                    />
                    {intl.formatMessage({ id: 'keep-me-logged-in', defaultMessage: 'Keep me logged in' })}
                </Box>
                <DescriptionLink
                    text={intl.formatMessage({ id: 'login-forgot-password-button', defaultMessage: 'Forgot password' })}
                    id={"forgot-password-link"}
                    onClick={() => {
                        handleTealiumClick(
                            intl.formatMessage({
                                id: 'login-forgot-password-button',
                                defaultMessage: 'Forgot password',
                            })
                        );
                    }}
                    as={Link}
                    to="/auth/forgot-password"
                />
            </AuthOptions>

            <AuthInfo>
                {intl.formatMessage(
                    {
                        id: 'login-terms-and-conditions',
                        defaultMessage:
                            "This page is protected by reCAPTCHA, and is subject to Google's <googleTermsLink>Terms & Conditions</googleTermsLink> and <googlePrivacyLink>Privacy Policy</googlePrivacyLink>",
                    },
                    {
                        googleTermsLink: (...chunks) => (
                            <DescriptionLink
                                key={Math.random().toString()}
                                target="_blank"
                                onClick={()=>handleTealiumClick(chunks)}
                                text={chunks}
                                href="https://www.google.com/intl/en/policies/terms/"
                            />
                        ),
                        googlePrivacyLink: (...chunks) => (
                            <DescriptionLink
                                key={Math.random().toString()}
                                target="_blank"
                                onClick={()=>handleTealiumClick(chunks)}
                                text={chunks}
                                href="https://www.google.com/policies/privacy/"
                            />
                        ),
                    }
                )}
            </AuthInfo>

            <AuthButton
                disabled={loading}
                id={"login-button"}
                onClick={() => {
                    handleTealiumClick(intl.formatMessage({ id: 'log-in-button', defaultMessage: 'Log in' }));
                    if (loading) {
                        return;
                    }
                    return handleSubmit();
                }}
            >
                {' '}
                {intl.formatMessage({ id: 'log-in-button', defaultMessage: 'Log in' })}{' '}
            </AuthButton>

            <AuthFooter>
                {intl.formatMessage(
                    { id: 'need-a-blub-account-signup', defaultMessage: 'Need a Blurb account? <link>Sign up</link>' },
                    {
                        link: (...chunks) => (
                            <DescriptionLink
                                key={Math.random().toString()}
                                as={Link}
                                text={chunks}
                                onClick={()=>handleTealiumClick(chunks)}
                                to={'/auth/sign-up'}
                                color="black"
                            />
                        ),
                    }
                )}
            </AuthFooter>
        </AuthScreenWrapper>
    );
};
