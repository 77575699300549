import React from 'react';
import { Box } from '../../Box';
import { ErrorButton } from '../../ErrorButton';

export default {
    Base: ({ width="220px", ...props }) => <Box
        { ...props }
        width={ width }
        bg="white"
        display="flex"
        flexDirection="column"
        textAlign="center"
        justifyContent="flex-start"
    />,

    CloseBox: ({ ...props }) => <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width="100%"
    >
        <Box
            { ...props }
            as="button"
            border="none" 
            background="none" 
            outline={0}
            padding="0px"
        />
    </Box>,
    
    Title: ({ ...props }) => <Box
        { ...props }
        textTransform="uppercase"
        fontSize="19px"
        lineHeight="21px"
        letterSpacing="0.7px"
        color="black"
        fontWeight="600"
        marginTop="21px"
    />,

    Divider: () => <Box
        width="100%"
        borderBottom="1px solid lightgray"
        marginTop="4px"
        marginBottom="16px"
    />,

    ButtonBox: ({ ...props }) => <Box
        { ...props }
        marginTop="130px"
        display="flex"
        flexDirection="column"
        alignItems="center"
    />,

    Button: ({ ...props }) => <ErrorButton
        { ...props }
        width="100%"
        borderRadius="4px 3px 3px 3px"
    />,

    ChildrenBox: ({ ...props }) => <Box
        { ...props }
        paddingX="11px"
    />,
}