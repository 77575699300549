import axios from 'axios';

class CTEventHandler {
    constructor() {
        this.context = 'WebBuilder';
        this.branch = process.env.REACT_APP_ENV_NAME;
        this.build = process.env.REACT_APP_BUILD_TAG;
        this.version = '1';
        this.commit = process.env.REACT_APP_COMMIT;
    }


    sendEvent(projectId, instanceId, events) {
        const ctEventRequest = {
            context: this.context,
            branch: this.branch,
            build: this.build,
            env: this.env,
            commit: this.commit,
            events: events,
            version: this.version,
            project_id: projectId,
            instance_id: instanceId,
        };
        axios
            .post(process.env.REACT_APP_CT_EVENT_URL, 'data=' + JSON.stringify(ctEventRequest), {
                headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            })
            .then(res => {
                console.log(res);
            })
            .catch(e => console.log(e));
    }

    buildEvent(name, data) {
        return {
            data: {
                [name]: data,
            },
            timestamp: new Date().toISOString(),
        };
    }
}

const ctEventHandler = new CTEventHandler();
export default ctEventHandler;
