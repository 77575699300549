import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { Footer } from '../../components/footer/footer';

import CPNT from './edit-screen-components';
import { Redirect } from 'react-router-dom'
import { ImageSection } from './image-section';
import uppy from '../../redux/uppy';

import { Doka } from './doka-screen';

import { ImageSlider } from '../../components/design/slider/ImageSlider';
import slides from '../../components/footer/materialSlides';

import { productMaterialChange } from '../../redux/productReducer';

export default function EditScreen() {
    const [showInfo, setShowInfo] = React.useState(false);
    const dispatch = useDispatch();
    const upload = useSelector(state => state.upload);
    const { imageInfo } = upload;
    if (!imageInfo) {
        return <Redirect to={'/'} noThrow />;
    }

    if (!imageInfo) {
        return <Redirect to={'/'} noThrow />;
    }
    
    const EditModals = () => (
        <>
          { upload.editMode && <Doka /> }
          { showInfo &&
            <ImageSlider
              slides={slides}
              show={showInfo}
              setShow={setShowInfo}
              onSlideSelect={slide => dispatch(productMaterialChange(slide.finish_type))}
            />
          }
        </>
    )

    return (
        <>
          <EditModals />
          <CPNT.Base>
            <CPNT.ImageBox>
              <ImageSection />
            </CPNT.ImageBox>
            <CPNT.FooterBox>
              <Footer setShowInfo={setShowInfo}/>
            </CPNT.FooterBox>
          </CPNT.Base>
        </>
    );
}
