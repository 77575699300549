import { Box } from '../design/Box';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleGrid from '../design/SimpleGrid';
import {
    OrientationDetect,
    OrientationLandscape,
    OrientationPortrait,
    productSizeChange,
} from '../../redux/productReducer';
import allProducts from '../../utils/allProducts';
import { getFrame } from '../design/sizing_indicators/sizing_indicators';
import { useIntl } from 'react-intl';
import { renderInCm } from '../../index';
import { handleTealiumClick } from '../../utils/serverUtil';

export const SizeScreenFooter = ({ show }) => {
    const dispatch = useDispatch();
    const product = useSelector(state => state.product);
    const prices = useSelector(state => state.api.prices);
    const productTypes = allProducts
        .filter(p => {
            let correctFinish = p.finish_type === product.productData.finish_type;
            let orientation = p.orientation === product.orientationControl;
            let square = p.orientation === 'square';
            return correctFinish && (orientation || square);
        })
        .sort((a, b) => {
            if (product.orientationControl === OrientationLandscape) {
                return a.rendered_rect.width - b.rendered_rect.width;
            }

            return a.rendered_rect.height - b.rendered_rect.height;
        });

    const gridLen = productTypes?.length ?? 6;

    let screenRefs = useRef(productTypes.map(() => React.createRef()));

    useEffect(() => {
        const buttonToFocus = screenRefs?.current.find(sRef => {
            if (sRef.current && sRef.current.id === product.productData.trim_text) {
                sRef.current.scrollIntoView();
            }
        });
    }, []);

    const Frames = () => {
        const { locale } = useIntl();
        if (!prices) return null;

        return productTypes?.map((pt, i) => {
            const isActive = product.productData.trim_text === pt.trim_text;

            const size = product.orientationControl === OrientationPortrait ? 0.5 : 0.5;

            return (
                <Box
                    id={"size"+pt.trim_text}
                    ref={screenRefs.current[i]}
                    key={`size-screen${i}`}
                    as="button"
                    onClick={() => {
                        const unit = renderInCm(locale) ? pt.trim_text_cm : pt.trim_text;
                        const price = prices?.[pt.finish_type]?.[pt.printable_product_dimension]?.replace('US', '');

                        handleTealiumClick(unit + ' ' + price);
                        return dispatch(productSizeChange(pt));
                    }}
                    background="none"
                    border="none"
                    outline={0}
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    justifyItems={'center'}
                    alignItems="center"
                    paddingRight={['40px', '40px', '0px']}
                    marginBottom={['0px', '0px', '15px']}
                >
                    <Box border={`1px solid ${isActive ? "#3399A2" : "#979797"}`}>
                        {getFrame(pt.trim_text, isActive, size)}
                    </Box>
                    <Box
                        paddingTop={[1, 2, 3, 3]}
                        fontSize="12px"
                        lineHeight="15px"
                        color={isActive ? 'blurb_cyan.25' : '#979797'}
                        fontWeight={isActive ? '700' : '500'}
                        whiteSpace="nowrap"
                    >
                        {renderInCm(locale) ? pt.trim_text_cm : pt.trim_text} <br />
                        {prices?.[pt.finish_type]?.[pt.printable_product_dimension]?.replace('US', '')}
                    </Box>
                </Box>
            );
        });
    };

    return (
        <Box
            display={show ? 'flex' : 'none'}
            width="100%"
            flexDirection="column"
            justifyContent={["flex-end", "flex-end", "flex-start"]}
        >
            <SimpleGrid
                columns={[gridLen, gridLen, 3]}
                overfloxX={'scroll'}
                id={"size-grid"}
                alignItems="end"
                width={['100%', '100%', '100%', '100%']}
                marginX={['0px', '0px', 'initial']}
            >
                <Frames />
            </SimpleGrid>
        </Box>
    );
};
