import React from 'react';

const sizes = {
    "8x10": {
        width: "23",
        height: "29",
        path: {
            d: "M21.5908 1.38623L21.5908 28.239L0.882076 28.239L0.882076 1.38623L21.5908 1.38623Z"
        }
    },
    "10x8": {
        width: "28",
        height: "22",
        rect: {
            x: "0.5",
            y: "0.735046",
            width: "26.8527",
            height: "20.7087"
        }
    },
    "11x14": {
        width: "31",
        height: "40",
        rect: {
            x:"0.5",
            y:"1.39221",
            width:"29.7199",
            height:"37.5023"
        }
    },
    "12x12": {
        width: "34",
        height: "33",
        rect: {
            x:"0.71875",
            y:"0.675842",
            width:"32.1775",
            height:"31.7679"
        }
    },
    "14x11": {
        width: "40",
        height: "31",
        rect: {
            x:"1.30176",
            y:"1.13342",
            width:"37.5023",
            height:"29.3103"
        }
    },
    "16x20": {
        width: "46",
        height: "55",
        rect: {
            x:"1.06738",
            y:"0.557556",
            width:"44.0559",
            height:"53.8863"
        }
    },
    "20x16": {
        width: "56",
        height: "46",
        rect: {
            x:"0.5",
            y:"1.39221",
            width:"54.7055",
            height:"43.2367"
        }
    },
    "20x20": {
        width: "56",
        height: "56",
        rect: {
            x:"1.2041",
            y:"1.35272",
            width:"54.2959",
            height:"53.8863"
        }
    },
    "20x24": {
        width: "56",
        height: "67",
        path: {
            d:"M54.873 0.883972L54.873 66.239L0.577187 66.239L0.577187 0.883972L54.873 0.883972Z"
        }
    },
    "20x30": {
        width: "56",
        height: "83",
        path: {
            d:"M55.084 0.5L55.084 82.239L0.788124 82.239L0.788124 0.5L55.084 0.5Z"
        }
    },
    "24x20": {
        width: "67",
        height: "55",
        rect: {
            x:"1.03857",
            y:"0.557556",
            width:"65.355",
            height:"53.8863"
        }
    },
    "30x20": {
        width: "83",
        height: "55",
        rect: {
            x:"0.718262",
            y:"0.557556",
            width:"81.739",
            height:"53.8863"
        }
    }
};

export const getFrame = (type, active, size) => {
    const data = sizes[type];
    const style = {
        fill: "white",
        // stroke: active ? "#3399A2" : "#979797",
        stroke: "white",
        strokeWidth: active ? "1.5px" : "1px"
    }
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg"
            width={data.width * size} height={data.height * size} viewBox={`0 0 ${data.width} ${data.height}`}
        >
            { data.rect && <rect { ...data.rect } { ...style }/> }
            { data.path && <path { ...data.path } { ...style }/> }
        </svg>
    )
}