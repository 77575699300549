import React, { forwardRef } from 'react';
import Grid from './Grid';

const px = n => (typeof n === 'number' ? n + 'px' : n);

export const widthToColumns = width => {
    if (Array.isArray(width)) {
        return width.map(widthToColumns);
    }

    if (width !== null && typeof width === 'object' && Object.keys(width).length > 0) {
        const acc = {};
        for (let key in width) {
            acc[key] = `repeat(auto-fit, minmax(${px(width[key])}, 1fr))`;
        }
        return acc;
    }

    if (width != null) {
        return `repeat(auto-fit, minmax(${px(width)}, 1fr))`;
    }

    return null;
};

export const countToColumns = count => {
    if (Array.isArray(count)) {
        return count.map(countToColumns);
    }

    if (count !== null && typeof count === 'object' && Object.keys(count).length > 0) {
        const acc = {};
        for (let key in count) {
            acc[key] = `repeat(${count[key]}, 1fr)`;
        }
        return acc;
    }

    if (count != null) {
        return `repeat(${count}, 1fr)`;
    }

    return null;
};

const SimpleGrid = forwardRef(({ columns, spacingX, spacingY, spacing, minChildWidth, ...props }, ref) => {
    const templateColumns = !!minChildWidth ? widthToColumns(minChildWidth) : countToColumns(columns);

    return (
        <Grid
            ref={ref}
            gap={spacing}
            columnGap={spacingX}
            rowGap={spacingY}
            templateColumns={templateColumns}
            {...props}
        />
    );
});

SimpleGrid.displayName = 'SimpleGrid';

export default SimpleGrid;
