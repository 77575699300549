import axios from 'axios';
import { createBrowserHistory } from 'history';
const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

apiClient.interceptors.response.use(
    response => response,
    error => {
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.code
        ) {
            const code = error.response.data.error.code;
            if (code === 'verify.session.invalid' || code === 'verify.session.expired') {
                return Promise.resolve(window.location.replace('/auth/login'));
            }
            else {
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }
);

export default apiClient;
