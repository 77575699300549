import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import App from './App';
import { IntlProvider } from 'react-intl';

import messages_si from './translations/si.json';
import messages_en from './translations/en.json';
import messages_de from './translations/de.json';
import messages_es from './translations/es.json';
import messages_fr from './translations/fr.json';
import messages_it from './translations/it.json';
import messages_nl from './translations/nl.json';
import messages_pt from './translations/pt.json';

export const locales = {
    us: {
        messages: messages_en,
        currency: 'US',
        supportUrl: 'https://support.blurb.com/hc/en-us/requests/new',
        renderInCm: false,
    },

    au: {
        messages: messages_en,
        currency: 'AUD',
        supportUrl: 'https://support.blurb.com/hc/en-us/requests/new',
        renderInCm: true,
    },
    si: {
        messages: messages_si,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/en-us/requests/new',
        renderInCm: true,
    },
    ca: {
        messages: messages_en,
        currency: 'CAD',
        supportUrl: 'https://support.blurb.com/hc/en-us/requests/new',
        renderInCm: false,
    },
    'ca-fr': {
        messages: messages_fr,
        currency: 'CAD',
        supportUrl: 'https://support.blurb.com/hc/fr/requests/new ',
        renderInCm: false,
    },
    en: {
        messages: messages_en,
        currency: 'GBP',
        supportUrl: 'https://support.blurb.com/hc/en-us/requests/new',
        renderInCm: true,
    },
    la: {
        messages: messages_es,
        currency: 'US',
        supportUrl: 'https://support.blurb.com/hc/es/requests/new',
        renderInCm: false,
    },
    de: {
        messages: messages_de,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/de/requests/new',
        renderInCm: true,
    },
    es: {
        messages: messages_es,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/es/requests/new',
        renderInCm: true,
    },
    br: {
        messages: messages_pt,
        currency: 'US',
        supportUrl: 'https://support.blurb.com/hc/pt-br/requests/new ',
        renderInCm: true,
    },
    fr: {
        messages: messages_fr,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/fr/requests/new',
        renderInCm:true
    },
    it: {
        messages: messages_it,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/it/requests/new',
        renderInCm: true,
    },
    nl: {
        messages: messages_nl,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/nl/requests/new',
        renderInCm: true,
    },
    pt: {
        messages: messages_pt,
        currency: 'EUR',
        supportUrl: 'https://support.blurb.com/hc/pt-br/requests/new',
        renderInCm: true,
    },
};

const supportedHostnames = {
    'au.create.blurb.com': 'au',
    'br.create.blurb.com': 'br',
    'nl.create.blurb.com': 'nl',
    'it.create.blurb.com': 'it',
    'create.blurb.co.uk': 'en',
    'create.blurb.fr': 'fr',
    'create.blurb.de': 'de',
    'create.blurb.es': 'es',
    'create.blurb.ca': 'ca',
    'fr.create.blurb.ca': 'ca-fr',
    'la.create.blurb.com': 'la',
};

export function getCurrency(locale) {
    if (Object.keys(locales).includes(locale)) {
        return locales[locale].currency;
    }
    return 'US';
}

export function renderInCm(locale) {
    if (Object.keys(locales).includes(locale)) {
        return locales[locale].renderInCm;
    }
    return false;
}
export function getSupportUrl(locale) {
    if (Object.keys(locales).includes(locale)) {
        return locales[locale].supportUrl;
    }
    return locales.en.supportUrl;
}

function setLocale() {
    const urlParams = new URLSearchParams(window.location.search);
    const localeParam = urlParams.get('locale');

    if (process.env.REACT_APP_QUERY_PARAM_LOCALE_ENABLED === 'true' && localeParam) {
        const urlParams = new URLSearchParams(window.location.search);
        const localeParam = urlParams.get('locale');
        if (Object.keys(locales).includes(localeParam)) {
            return localeParam;
        }
        return 'us';
    } else {
        const hostname = window.location.hostname;
        if (Object.keys(supportedHostnames).includes(hostname)) {
            return supportedHostnames[hostname];
        } else return 'us';
    }
}
const locale = setLocale();

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale={locale} messages={locales[locale].messages}>
            <App />
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
