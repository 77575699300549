import React from 'react';
import { Box } from './Box';
import './canvas-preview/style.css';

import Tooltips from './tooltips/Tooltips';

import { defineMessages, useIntl } from 'react-intl';

const bleedTooltipInfo = {
    "canvas_matte" : {
        translation:defineMessages({
            title: {
                id: 'bleed_tooltip_canvas_title',
                defaultMessage: 'Canvas Wrap',
            },
            description: {
                id: 'bleed_tooltip_canvas_description',
                defaultMessage: 'The shaded indicator shows where your image will wrap.',
            }
        })
    },
    "metal_gloss" : {
        translation:defineMessages({
            title: {
                id: 'bleed_tooltip_metal_title',
                defaultMessage: 'Metal Bleed',
            },
            description: {
                id: 'bleed_tooltip_metal_description',
                defaultMessage: 'The shaded indicator shows where your image will be trimmed off when printed.',
            }
        })
    }
}

export const CanvasImage = ({ url, imageSize, cropBleed, drawBleed, showSpinner, showTooltip, ...props }) => {
    const intl = useIntl();
    const [loadingImages, setLoadingImages] = React.useState(true);
    const canvasRef = React.useRef();

    React.useEffect(() => {
        createImage();
    }, []);

    const createImage = async () => {
        if (!url || !imageSize) return;

        const canvas = canvasRef.current;
        canvas.width = imageSize?.width;
        canvas.height = imageSize?.height;

        if (cropBleed?.width && cropBleed?.height) {
            cropBleed = {
                width: cropBleed.width,
                height: cropBleed.height,
            };
            canvas.width -= canvas.width * cropBleed.width * 2;
            canvas.height -= canvas.height * cropBleed.height * 2;
        }

        const ctx = await canvas.getContext('2d');

        const mainImage = await loadImage(url);
        await Promise.all([mainImage]).then(() => setLoadingImages(false));
        let imgWidth, imgHeight;

        if (imageSize?.width >= imageSize?.height) {
            fitToWidth();
            if (imgHeight < imageSize?.height) fitToHeight();
        } else if (imageSize?.width < imageSize?.height) {
            fitToHeight();
            if (imgWidth < imageSize?.width) fitToWidth();
        }

        await ctx.drawImage(
            mainImage,
            canvas.width / 2 - imgWidth / 2,
            canvas.height / 2 - imgHeight / 2,
            imgWidth,
            imgHeight
        );

        if (drawBleed?.width && drawBleed?.height) {
            drawBleed = {
                width: drawBleed.width,
                height: drawBleed.height,
            };
            ctx.fillStyle = 'rgba(255, 0, 0, 0.3)';
            const bleedWidth = canvas.width * drawBleed.width;
            const bleedHeight = canvas.height * drawBleed.height;

            await ctx.fillRect(0, 0, bleedWidth, canvas.height);
            await ctx.fillRect(canvas.width - bleedWidth, 0, bleedWidth, canvas.height);
            await ctx.fillRect(bleedWidth, 0, canvas.width - 2 * bleedWidth, bleedHeight);
            await ctx.fillRect(bleedWidth, canvas.height - bleedHeight, canvas.width - 2 * bleedWidth, bleedHeight);
        }

        function fitToWidth() {
            const ratio = mainImage.height / mainImage.width;
            imgWidth = imageSize?.width;
            imgHeight = imageSize?.width * ratio;
        }

        function fitToHeight() {
            const ratio = mainImage.width / mainImage.height;
            imgWidth = imageSize?.height * ratio;
            imgHeight = imageSize?.height;
        }
    };

    const loadImage = src => {
        return new Promise((resolve, reject) => {
            try {
                const image = new Image();
                image.src = src;
                image.onload = () => {
                    return resolve(image);
                };
            } catch (error) {
                return reject(error);
            }
        });
    };

    if(showTooltip && (cropBleed?.width || drawBleed?.width)) {
        const canvasWidth = canvasRef?.current?.clientWidth;
        const offsetX = canvasWidth - canvasWidth * ((cropBleed?.width || drawBleed?.width) / 2);
        return (
            <Tooltips.Info
                id={"learn-more-tooltip-inside"}
                placement="left"
                trigger={["click", "hover"]}
                align={{
                    offset: [offsetX, 0],
                }}
                title={intl.formatMessage(bleedTooltipInfo[showTooltip]?.translation?.title)}
                description={intl.formatMessage(bleedTooltipInfo[showTooltip]?.translation?.description)}
                link={intl.formatMessage({id:'learn-more-tooltip', defaultMessage:'Learn More'})}
                url="https://support.blurb.com/hc/en-us/articles/360042492291-Understanding-the-Wall-Art-Safe-Art-Space-within-Blurb-s-Online-Tool"
            >
                <Box
                    { ...props }
                    as="canvas"
                    ref={ canvasRef }
                    className="imageTransition"
                />
            </Tooltips.Info>
        )
    }

    return <Box { ...props }
        as="canvas"
        ref={ canvasRef }
        className="imageTransition"
    />;
};
