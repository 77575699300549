import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { calculateAspectRatioForDoka, getProductTitle, getBleedPercentages } from '../../utils/productService';

import { nextStepEdit } from '../../redux/fileReducer';
import { orientationChange, OrientationLandscape, OrientationPortrait } from '../../redux/productReducer';

import { CanvasImage } from '../../components/design/CanvasImage';
import { CanvasPreview } from '../../components/design/canvas-preview/CanvasPreview';
import { Switch } from '../../components/design/Switch';
import { Box } from '../../components/design/Box';

import CPNT from './image-section-components';
import { useIntl } from 'react-intl';
import { issueTranslations } from '../../utils/translationMessages';
import { handleTealiumClick } from '../../utils/serverUtil';
import "./image-section.css";

export const ImageSection = () => {
    const intl = useIntl();
    const upload = useSelector(state => state.upload);
    const product = useSelector(state => state.product);
    const { currentEditScreen, editScreens } = useSelector(state => state.editScreen);
    const dispatch = useDispatch();

    let issueTitle = '';
    let issueDescription = '';

    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;

    if (upload?.validations?.issues?.imageResolution) {
        issueTitle = intl.formatMessage(issueTranslations.imageResolution.translation.title);
        issueDescription = intl.formatMessage(issueTranslations.imageResolution.translation.description);
    }

    const ImgScreen = () => {
        const CanvasPreviewScreen = () => (
            <>
              <CanvasPreview
                url={upload.url}
                material={product.productData.finish_type}
                orientation={product.productData.orientation}
                size={product.productData.trim_text}
                imageSize={product?.productData?.trim}
                bleed={getBleedPercentages(product.id, product.orientation)}
                height="100%"
              />
                <CPNT.SwitchRow>
                  <CPNT.SwitchLabel
                    active={product.orientationControl === OrientationPortrait}
                    onClick={() => {
                      handleTealiumClick(
                        intl.formatMessage({ id: 'orientation_portrait', defaultMessage: 'portrait' })
                      );
                      if (product.orientationControl !== OrientationPortrait) dispatch(orientationChange());
                    }}
                  >
                    {intl.formatMessage({ id: 'orientation_portrait', defaultMessage: 'portrait' })}
                  </CPNT.SwitchLabel>
                  <Switch
                      id={"switch-orientation"}
                    defaultChecked={product.orientationControl === OrientationLandscape}
                    onChange={() => {
                      handleTealiumClick('orientation switch click');
                      return dispatch(orientationChange());
                    }}
                  />
                  <CPNT.SwitchLabel
                    active={product.orientationControl === OrientationLandscape}
                    onClick={() => {
                      handleTealiumClick(
                        intl.formatMessage({ id: 'orientation_landscape', defaultMessage: 'landscape' })
                      );
                      if (product.orientationControl !== OrientationLandscape) dispatch(orientationChange());
                    }}
                  >
                    {intl.formatMessage({ id: 'orientation_landscape', defaultMessage: 'landscape' })}
                  </CPNT.SwitchLabel>
                </CPNT.SwitchRow>
            </>
        );

        const ImagePreviewScreen = () => (
            <>
              <Box
                width="100%"
                maxHeight="100%"
                position="relative"
                display="flex"
                justifyContent="center"
              >
                <CanvasImage
                  url={upload.url}
                  imageSize={product?.productData?.rendered_rect}
                  drawBleed={getBleedPercentages(product.id, product.orientation)}
                  height="auto"
                  width="100%"
                  objectFit={[ "contain", "contain", null ]}
                  showTooltip={product?.productData?.finish_type}
                  onClick={() => handleTealiumClick(currentEditScreen + '  screen photo click')}
                  as="button"
                />
              </Box>
              <CPNT.EditButton
                  id={"edit-image-button"}
                  onClick={() => {
                  handleTealiumClick(intl.formatMessage({ id: 'edit-image', defaultMessage: 'EDIT IMAGE' }));
                  return dispatch(
                    nextStepEdit(calculateAspectRatioForDoka(product.id, product.orientation), 'crop')
                  );
                }}
              >
                {intl.formatMessage({ id: 'edit-image', defaultMessage: 'EDIT IMAGE' })}
              </CPNT.EditButton>
            </>
        );

        return (
            <Box
              maxHeight={[ "65%", "100%", "60%" ]}
              width="100%"
              display="flex"
              position="relative"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              className={isIOS && "iosDeviceContainer"}
            >
              { currentEditScreen !== editScreens.size ? <ImagePreviewScreen /> : <CanvasPreviewScreen /> }
              <CPNT.WarningTooltip title={issueTitle} description={issueDescription} width="100%"/>
            </Box>
        );
    }

    return (
        <CPNT.ContainerColumn
          paddingX={['0px', '0px', '50px', '15%']}
          width="100%"
          height="100%"
          position="relative"
        >
          <CPNT.Description id={"product-title-description"}>
            {getProductTitle(intl, product.productData)}
          </CPNT.Description>
          <ImgScreen />
        </CPNT.ContainerColumn>
    );
};
