import React from 'react';
import theme from '../theme/theme';

function UploadingIcon() {
    const color = theme.colors.blurb_cyan['500'];
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill={color} viewBox="0 0 29 29">
            <path
                fill="#00AEEF"
                fillRule="evenodd"
                d="M0 14.5C0 6.505 6.505 0 14.5 0S29 6.505 29 14.5 22.495 29 14.5 29 0 22.495 0 14.5zm1.208 0c0 7.33 5.963 13.292 13.292 13.292 7.33 0 13.292-5.963 13.292-13.292 0-7.33-5.963-13.292-13.292-13.292-7.33 0-13.292 5.963-13.292 13.292z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#00AEEF"
                d="M15.424 6.178a.595.595 0 00-.848 0l-5.4 5.464a.612.612 0 000 .859.595.595 0 00.848 0L14.4 8.073v14.32c0 .335.268.607.6.607.332 0 .6-.272.6-.607V8.073l4.376 4.428a.595.595 0 00.848 0 .612.612 0 000-.859l-5.4-5.464z"
            ></path>
        </svg>
    );
}

export default UploadingIcon;
