import styled from 'styled-components';
import React from 'react';
import theme from './theme/theme';

const SwitchLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

const SwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.blurb_cyan['25']};
    border-radius: 50%;
    -webkit-transition: 0.4s;
    border-radius: 22px;
    transition: 0.4s;
    &:before {
        border-radius: 50%;
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 4px;
        bottom: 4px;
        background-color: white;

        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
`;

const SwitchInput = styled.input`
    &:checked + ${SwitchSlider} {
        opacity: 1;
        transform: scale(1);
        &:before {
            -webkit-transform: translateX(28px);
            -ms-transform: translateX(28px);
            transform: translateX(28px);
        }
    }
    &:focus + ${SwitchSlider} {
        box-shadow: 0 0 1px #2196f3;
    }
`;

export const Switch = ({ id,defaultChecked, checked, onChange }) => (
    <SwitchLabel id={id}>
        <SwitchInput type={'checkbox'} defaultChecked={defaultChecked} checked={checked} onChange={onChange} />
        <SwitchSlider />
    </SwitchLabel>
);
