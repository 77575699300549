import React, { forwardRef } from 'react';
import { Box } from '../../components/design/Box';

export const FileSelectionScreen = ({ dragRef, ...props }) => (
    <Box
        ref={dragRef}
        bg="blurb_gray.800"
        minHeight={['200px', '500px']}
        display="flex"
        justifyContent="center"
        height="100%"
        {...props}
    />
);

export const FileDropBox = forwardRef(({ ...props }, ref) => (
    <Box ref={ref} paddingTop={['15vw', '10vw', '7vw']} textAlign="center" {...props} />
));

export const ScreenTitle = ({ ...props }) => (
    <Box fontWeight="semibold" fontSize={20} letterSpacing="0.107692px" lineHeight="23px" margin="auto" {...props} />
);

export const ScreenInfo = ({ ...props }) => (
    <Box display={['none', 'none', 'initial']} fontSize={18} letterSpacing="0.0969231px" lineHeight="22px" {...props} />
);

export const ScreenIcon = ({ ...props }) => <Box marginTop="31px" marginBottom="50px" {...props} />;

export const ScreenDescription = ({ ...props }) => (
    <Box
        fontSize={14}
        marginTop="15%"
        letterSpacing="0.0753846px"
        lineHeight="17px"
        opacity="55%"
        color="blurb_gray.600"
        {...props}
    />
);

export const ErrorImageBox = ({ ...props }) => (
    <Box {...props} position="relative" marginBottom={['20px', '52px', '44px']} />
);

export const ErrorImage = ({ ...props }) => (
    <Box
        {...props}
        as="img"
        maxWidth={['50vh', '50vh', 500]}
        border="2px solid"
        borderColor="gray.400"
        maxHeight={[250, 400, 500]}
        opacity="0.4"
        margin="0"
    />
);

export const ErrorIcon = ({ ...props }) => (
    <Box {...props} position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
);

export const ErrorDisplayBox = ({ ...props }) => (
    <Box
        {...props}
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignSelf="center"
        justifyContent="center"
        marginBottom={['45px', '37px']}
    />
);

export const ErrorTextBox = ({ ...props }) => <Box {...props} textAlign="left" paddingLeft="15px" />;

export const ErrorTextTitle = ({ ...props }) => (
    <Box
        {...props}
        color="blurb_orange.900"
        fontSize={12}
        lineHeight="14px"
        fontFamily="blurb"
        fontWeight="bold"
        letterSpacing="blurb"
    />
);

export const ErrorTextDescription = ({ ...props }) => (
    <Box {...props} fontSize={12} lineHeight="14px" fontFamily="blurb" fontWeight="normal" letterSpacing="blurb" />
);
