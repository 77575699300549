import React from 'react';
import { Box } from '../Box';

export function UploadErrorIcon({ width = '50px', height = '50px', color = '#000' }) {
    return (
        <Box 
            as="svg" 
            width={width}
            height={height}
            verticalAlign="middle"
            fill={color}
            overflow="hidden"
            viewBox="0 0 1024 1024" 
        >
            <path d="M142.4384 193.6384c-91.8528 91.8528-142.4384 214.016-142.4384 343.9616s50.5856 252.0576 142.4384 343.9616 214.016 142.4384 343.9616 142.4384 252.0576-50.5856 343.9616-142.4384 142.4384-214.016 142.4384-343.9104-50.5856-252.0576-142.4384-343.9616-214.016-142.4384-343.9616-142.4384-252.0576 50.5856-343.9616 142.4384zM921.6 537.6c0 239.9744-195.2256 435.2-435.2 435.2s-435.2-195.2256-435.2-435.2c0-239.9744 195.2256-435.2 435.2-435.2s435.2 195.2256 435.2 435.2zM468.3264 237.9264l-204.8 204.8c-9.984 9.984-9.984 26.2144 0 36.1984s26.2144 9.984 36.1984 0l161.0752-161.1264 0 527.0016c0 14.1312 11.4688 25.6 25.6 25.6s25.6-11.4688 25.6-25.6l0-527.0016 161.1264 161.0752c9.984 9.984 26.2144 9.984 36.1984 0 5.0176-5.0176 7.4752-11.5712 7.4752-18.0736s-2.5088-13.1072-7.5264-18.0736l-204.8-204.8c-9.984-9.984-26.2144-9.984-36.1984 0z" />
        </Box>
    );
}
