import React from 'react';

import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { getCurrency } from './index';
import apiClient from './components/apiClient';
import { changePrices } from './redux/apiReducer';
import { useDispatch } from 'react-redux';
export const CurrencyWrapper = ({ children }) => {
    const { locale } = useIntl();
    const currency = getCurrency(locale);
    const dispatch = useDispatch();
    useEffect(() => {
        const pricingPromise = apiClient.get(`/v2/printable_product_prices?locale=${locale}&currency_id=${currency}`);
        pricingPromise
            .then(result => {
                if (result?.data?.photo_prints) {
                    dispatch(changePrices(result.data.photo_prints));
                }
            })
            .catch(e => console.log(e));
    }, [locale, currency]);
    return <>{children}</>;
};
