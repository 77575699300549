import React from 'react';
import { Box } from '../design/Box';
import Grid from '../design/Grid';
import PseudoBox from '../design/PseudoBox';

export default {
    Base: ({ ...props }) => <Box
        {...props}
        gridColumn={['', ' ', '1']}
        gridRow={['', ' ', '1']}
        bg='white'
        w="100%"
        h="100%"
    />,

    Column: ({ ...props }) => <Box
        {...props}
        display='flex'
        h="100%"
        w="100%"
        flexDirection={['column', 'column', 'column-reverse']}
        justifyContent={['space-between', 'space-between', 'flex-end']}
    />,

    Screen: ({ ...props }) => <Box
        {...props}
        bg='white'
        paddingX='20px'
        display='flex'
        flexDirection='column'
        justifyContent={['flex-start']}
        paddingTop='9px'
        h='100%'
    />,

    InfoButton: ({ ...props }) => <Box
        {...props}
        as="button"
        background='none'
        border='none'
        outline={0}
    />,

    SelectedScreen: ({ ...props }) => <Box
        {...props}
        overflowX='auto'
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent={["flex-end","flex-start"]}
        pb="5%"
    />,

    IconsGrid: ({ ...props }) => <Grid
        {...props}
        gridTemplateColumns='repeat(3, 1fr)'
        gridTemplateRows='1fr'
        bg='blurb_gray.300'
        fontSize='13px'
        textTransform='uppercase'
        justifyContent='space-around'
    />,

    IconBox: ({ ...props }) => <Box
        {...props}
        height='62px'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-around'
        cursor="pointer"
        borderStyle="solid"
        borderWidth={['3px 0px 0px 0px', '3px 0px 0px 0px', '0px 0px 3px 0px']}
        textAlign="center"
        transition="all .25s linear"
    />,

    IconName: ({ ...props }) => <Box
        {...props}
        fontSize='13px'
    />,

    TabBox: ({ ...props }) => <PseudoBox
        {...props}
        color="white"
        fill="white"
        _hover={{
            fill: 'gray.200',
            color: 'gray.200'
        }}
    />
}
