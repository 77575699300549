import './modals.css';

import { Confirmation } from './confirmation/Confirmation';
import { Error } from './error/Error';
import { Wrapper } from './wrapper/Wrapper';

export default {
    Confirmation,
    Error,
    Wrapper
}