import { Login } from './login/Login';
import React from 'react';
import { SignUp } from './sign-up/SignUp';
import { ForgotPassword } from './forgot-password/ForgotPassword';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { NotFound } from '../NotFound';
export const AuthScreen = () => {
    let { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/login`}>
                    <Login />
                </Route>
                <Route path={`${path}/sign-up`}>
                    <SignUp />
                </Route>
                <Route path={`${path}/forgot-password`}>
                    <ForgotPassword />
                </Route>
                <Route path={'*'}>
                    <NotFound />
                </Route>
            </Switch>
        </>
    );
};
