import React from 'react';
import PseudoBox from '../PseudoBox';

function RulerIcon({ width = '50px', height = '50px', color }) {
    const style = {
        fill: color ? color : ""
    }
    return (
        <PseudoBox
            { ...style }
            as="svg"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width={width}
            height={height}
            enableBackground="new 0 0 512.002 512.002"
            version="1.1"
            viewBox="0 0 512.002 512.002"
            xmlSpace="preserve"
        >
            <path d="M509.502 104.908L407.097 2.502a8.524 8.524 0 00-12.067 0L2.502 395.03a8.524 8.524 0 000 12.067l102.405 102.405a8.513 8.513 0 006.033 2.5 8.526 8.526 0 006.033-2.5L509.5 116.975a8.513 8.513 0 002.5-6.033 8.517 8.517 0 00-2.498-6.034zM110.941 491.402l-90.338-90.338 380.46-380.461 90.338 90.338-380.46 380.461z"></path>
            <path d="M409.631 76.797c-14.115 0-25.601 11.486-25.601 25.601 0 14.115 11.486 25.601 25.601 25.601 14.115 0 25.601-11.486 25.601-25.601 0-14.114-11.486-25.601-25.601-25.601zm0 34.135c-4.711 0-8.534-3.832-8.534-8.534 0-4.702 3.823-8.534 8.534-8.534s8.534 3.832 8.534 8.534c0 4.703-3.823 8.534-8.534 8.534z"></path>
            <path d="M328.558 80.031H345.625V116.239H328.558z" transform="rotate(-45.001 337.093 98.14)"></path>
            <path d="M307.219 103.869H324.286V152.144H307.219z" transform="rotate(-45.001 315.751 128.01)"></path>
            <path d="M277.356 131.227H294.423V167.435H277.356z" transform="rotate(-45.001 285.89 149.335)"></path>
            <path
                d="M256.016 155.056H273.083V203.33100000000002H256.016z"
                transform="rotate(-45.001 264.55 179.197)"
            ></path>
            <path d="M226.153 182.423H243.22V218.631H226.153z" transform="rotate(-45.001 234.687 200.531)"></path>
            <path
                d="M204.814 206.252H221.881V254.52700000000002H204.814z"
                transform="rotate(-45.001 213.346 230.393)"
            ></path>
            <path d="M174.946 233.61H192.013V269.81H174.946z" transform="rotate(-45.009 183.479 251.714)"></path>
            <path
                d="M153.611 257.465H170.678V305.73999999999995H153.611z"
                transform="rotate(-45.001 162.142 281.605)"
            ></path>
            <path d="M123.748 284.831H140.815V321.039H123.748z" transform="rotate(-45.001 132.278 302.938)"></path>
            <path
                d="M102.409 308.669H119.476V356.94399999999996H102.409z"
                transform="rotate(-45.001 110.938 332.808)"
            ></path>
            <path
                d="M72.564 336.045H89.631V372.25300000000004H72.564z"
                transform="rotate(-45.001 81.093 354.151)"
            ></path>
            <path
                d="M51.206 359.873H68.273V408.14799999999997H51.206z"
                transform="rotate(-45.001 59.736 384.014)"
            ></path>
        </PseudoBox>
    );
}

export default RulerIcon;
