import { Box } from '../design/Box';
import React from 'react';
import { useSelector } from 'react-redux';
import change_image_icon from '../design/images/change_image_icon.svg';
import { useDragUpload } from '../hooks/useDragUpload';
import { ButtonStyle } from '../design/Button';
import { useIntl } from 'react-intl';
import { handleTealiumClick } from '../../utils/serverUtil';

export const ImageScreenFooter = ({ show }) => {
    const upload = useSelector(state => state.upload);
    const intl = useIntl();

    const [dragRef, uploadRef] = useDragUpload();
    return (
        <>
            <Box display={show ? 'flex' : 'none'} justifyContent={['left', 'left']} paddingY={1}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box
                        as="div"
                        width={[51, 71]}
                        height={[44, 60]}
                        marginX={'20px'}
                        display="flex"
                        id={"change-image-file"}
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Box
                            ref={dragRef}
                            textAlign="center"
                            color="blurb_gray.500"
                            fontSize="10px"
                            lineHeight="12px"
                            letterSpacing="0.7px"
                            textTransform="uppercase"
                        >
                            <input
                                ref={uploadRef}
                                id="my-file-input-again"
                                accept=".jpg, .jpeg"
                                type="file"
                                style={{ display: 'none' }}
                            />

                            <Box
                                as="label"
                                htmlFor="my-file-input-again"
                                onClick={event => {
                                    if (event.currentTarget) {
                                        handleTealiumClick(
                                            intl.formatMessage({
                                                id: 'change-image-button',
                                                defaultMessage: 'Change Image',
                                            })
                                        );
                                    }
                                }}
                            >
                                <Box as="img" src={change_image_icon} width={[51, 71]} height={[44, 60]} />
                            </Box>
                            <Box />
                        </Box>
                    </Box>
                    <Box fontSize={'10px'} textAlign={'center'} paddingRight="10px">
                        {intl.formatMessage({id:'change-image-button', defaultMessage:'Change Image'})}
                    </Box>
                </Box>
                <Box>
                    <Box
                        as={'img'}
                        height={[65, 80]}
                        maxHeight={'100%'}
                        maxWidth="100px"
                        src={upload.url}
                        // border="1px solid #979797"
                        objectFit="contain"
                    />
                </Box>
            </Box>
        </>
    );
};
