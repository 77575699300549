import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import uppy from '../../redux/uppy';
import { ButtonStyle } from '../../components/design/Button';
import { UploadIcon } from '../../components/design/icons/UploadIcon';

import {
    ErrorDisplayBox,
    ErrorIcon,
    ErrorImage,
    ErrorImageBox,
    ErrorTextBox,
    ErrorTextDescription,
    ErrorTextTitle,
    FileDropBox,
    FileSelectionScreen,
    ScreenDescription,
    ScreenIcon,
    ScreenInfo,
    ScreenTitle,
} from './initialScreenComponents';
import { WarningIconFull } from '../../components/design/icons/WarningIconFull';
import { Box } from '../../components/design/Box';
import { newImage } from '../../redux/fileReducer';
import { useDragUpload } from '../../components/hooks/useDragUpload';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { handleTealiumClick } from '../../utils/serverUtil';

export default function InitialScreen() {
    const upload = useSelector(state => state.upload);
    const location = useLocation();
    const dispatch = useDispatch();
    const handleFirstRender = () => {
        const { from } = location?.state ?? {};
        if (from !== 'validator') {
            uppy.reset();
            dispatch(newImage());
        }
    };
    const [uploadRef, dragRef] = useDragUpload(true, handleFirstRender);

    const FileUpload = () => {
        const intl = useIntl();
        const issues = upload?.validations?.issues ?? {};
        return (
            <FileDropBox ref={dragRef}>
                <input
                    ref={uploadRef}
                    id="my-file-input"
                    accept=".jpg, .jpeg, image/jpeg"
                    type="file"
                    style={{ display: 'none' }}
                />
                {!upload.validations && !upload.url ? (
                    <>
                        <ScreenTitle>
                            {intl.formatMessage({
                                id: 'upload-your-wall-art-image',
                                defaultMessage: 'Upload Your Wall Art Image',
                            })}
                        </ScreenTitle>
                        <ScreenInfo>
                            {intl.formatMessage(
                                {
                                    id: 'you-can-drag-and-drop-to-upload',
                                    defaultMessage: 'You can Drag and Drop To Upload <b>or</b> ',
                                },
                                { b: (...chunks) => <b key={Math.random().toString()}>{chunks}</b> }
                            )}
                        </ScreenInfo>
                        <ScreenIcon>
                            <UploadIcon />
                        </ScreenIcon>
                    </>
                ) : (
                    <>
                        <ErrorImageBox>
                            <ErrorImage src={upload.url} />
                            <ErrorIcon>
                                <WarningIconFull />
                            </ErrorIcon>
                        </ErrorImageBox>

                        <ErrorDisplayBox>
                            <WarningIconFull width="23" height="19" />
                            <ErrorTextBox>
                                <ErrorTextTitle id={'error-text-title'}>
                                    {intl.formatMessage({
                                        id: 'error-select-another-image',
                                        defaultMessage: 'Please select another image to continue.',
                                    })}
                                </ErrorTextTitle>
                                <ErrorTextDescription id={'error-text-description'}>
                                    {Object.keys(issues).map(issue => (
                                        <div key={Math.random().toString()}>{issues[issue]?.title}</div>
                                    ))}
                                </ErrorTextDescription>
                            </ErrorTextBox>
                        </ErrorDisplayBox>
                    </>
                )}

                <ButtonStyle
                    as="label"
                    htmlFor="my-file-input"
                    padding="13px 70px 11px 70px"
                    id="my-file-input-button"
                    onClick={()=>handleTealiumClick(
                        intl.formatMessage({ id: 'select-image', defaultMessage: 'Select image' })
                    )}
                >
                    {intl.formatMessage({ id: 'select-image', defaultMessage: 'Select image' })}
                </ButtonStyle>
                <ScreenDescription>
                    {intl.formatMessage(
                        { id: 'jpeg-max-size', defaultMessage: 'JPEG Images, Maximum size <b>100MB</b>' },
                        { b: (...chunks) => <b key={Math.random().toString()}>{chunks}</b> }
                    )}

                    <Box></Box>
                </ScreenDescription>
            </FileDropBox>
        );
    };

    return (
        <FileSelectionScreen dragRef={dragRef}>
            <FileUpload />
        </FileSelectionScreen>
    );
}
