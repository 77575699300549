import { css, keyframes } from 'styled-components';
import React from 'react';
import { Box } from './Box';

function valueToPercent(value, min, max) {
    return ((value - min) * 100) / (max - min);
}

const indeterminate = keyframes`
0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }
`;

const indeterminateAnimation = css`
    animation: ${indeterminate} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
`;
const ProgressIndicator = ({ isIndeterminate, min, max, value, ...rest }) => {
    const percent = valueToPercent(value, min, max);

    return (
        <Box
            height="100%"
            aria-valuemax={max}
            aria-valuemin={min}
            aria-valuenow={isIndeterminate ? null : value}
            role="progressbar"
            transition="all 0.3s"
            width={`${percent}%`}
            {...rest}
        />
    );
};

const ProgressTrack = ({ size, ...rest }) => {
    return <Box pos="relative" height={'7px'} overflow="hidden" {...rest} />;
};

const Progress = ({
    color = 'blue',
    value = 63,
    min = 0,
    max = 100,
    size = 'md',
    hasStripe,
    isAnimated,
    borderRadius,
    rounded,
    children,
    isIndeterminate,
    ...rest
}) => {
    const _borderRadius = rounded || borderRadius;

    return (
        <ProgressTrack size={size} bg={'blurb_gray.500'} borderRadius={_borderRadius} {...rest}>
            <ProgressIndicator
                min={min}
                max={max}
                value={value}
                bg={'blurb_cyan.500'}
                borderRadius={_borderRadius}
                isIndeterminate={isIndeterminate}
                {...(isIndeterminate && {
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    willChange: 'left, right',
                })}
                css={[isIndeterminate && indeterminateAnimation]}
            />
        </ProgressTrack>
    );
};

export default Progress;
