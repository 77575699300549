import { useSelector } from 'react-redux';
import UploadingIcon from '../design/icons/UploadingIcon';
import UploadProcessingIcon from '../design/icons/UploadProcessingIcon';
import { Box } from '../design/Box';
import Progress from '../design/Progress';
import React from 'react';
import { useIntl } from 'react-intl';

const UploadDescription = ({ description, ...props }) => (
    <Box {...props}>
        <Box paddingY={'7px'} paddingX={'12px'} fontSize={'14px'} color={'blurb_gray.600'}>
            {description}
        </Box>
    </Box>
);

const paddingTop = 10;
const paddingBottom = 7;

export const getTotalUploadPercentage = (image, analytics) => {
    const totalSize = image.bytesTotal + analytics.bytesTotal;
    if (totalSize === 0) {
        return 0;
    } else {
        const uploadedBytes = image.bytesUploaded + analytics.bytesUploaded;
        return (uploadedBytes / totalSize) * 100;
    }
};

export const UploadHeader = ({ ...props }) => {
    const intl = useIntl();
    const upload = useSelector(state => state.upload);
    const { started, image, analytics } = upload.uploading;
    let percentage = getTotalUploadPercentage(image, analytics);

    const uploadText =
        percentage < 100
            ? intl.formatMessage({ id: 'upload-header-uploading-wall-art', defaultMessage: 'Uploading Wall Art' })
            : intl.formatMessage({
                  id: 'upload-header-processing-your-image',
                  defaultMessage: 'Processing your image',
              });

    const Icon = started ? UploadingIcon : UploadProcessingIcon;

    let message =
        percentage < 100
            ? intl.formatMessage({ id: 'upload-header-wait-for-image-upload', defaultMessage: 'Wait for image upload' })
            : intl.formatMessage({
                  id: 'upload-header-hold-on-a-sec-while-we-process-your-image',
                  defaultMessage: 'Hold on a sec while we process your image',
              });
    const uploadDescription =
        percentage === 100 && upload.uploading?.uploadProcessing?.finished
            ? intl.formatMessage({
                  id: 'upload-header-sending-your-wall-art-to-shipping-cart',
                  defaultMessage: 'Sending your wall art to the shipping cart',
              })
            : message;

    return (
        <Box>
            <Box paddingLeft={'12px'} paddingTop={`${paddingTop}px`} paddingBottom={`${paddingBottom}px`}>
                <Icon />
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box fontSize={'14px'} color={'blurb_gray.400'}>
                        {uploadText}
                    </Box>
                    <UploadDescription description={uploadDescription} display={['none', 'initial']} />
                </Box>
            </Box>
            <Progress value={percentage} />
            <UploadDescription description={uploadDescription} display={['initial', 'none']} />
        </Box>
    );
};
