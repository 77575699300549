import React from 'react';

export const BlurbLogo = ({width,height}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 83 83"
    >
        <path
            fill="#00AEEF"
            d="M31.125 65.511H83V0H31.125L0 15.723v65.511l31.125-15.723z"
        ></path>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M14.843 38.591V31.94h-4.247v19.862h4.247v-1.649c.945 1.284 2.485 1.978 4.247 1.978 4.002 0 6.592-3.355 6.592-7.782 0-4.403-2.625-7.735-6.592-7.735-1.739 0-3.314.671-4.247 1.978zm6.394 5.815c0 2.225-1.026 4.062-3.173 4.062-2.147 0-3.22-1.813-3.22-4.062 0-2.225 1.096-4.12 3.22-4.12 2.158 0 3.173 1.895 3.173 4.12z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#fff"
            d="M27.234 51.8V31.94h4.247V51.8h-4.247zM47.186 36.943v14.858h-4.212v-1.613c-.887 1.166-2.427 1.943-4.247 1.943-3.115 0-4.854-2.331-4.854-5.31v-9.878h4.247v8.877c0 1.283.409 2.531 2.124 2.531 1.54 0 2.73-1.024 2.73-2.943v-8.453h4.212v-.012zM58.248 41.18c-.327-.14-.77-.223-1.214-.223-1.738 0-3.138 1.307-3.138 4.31V51.8h-4.248V36.943h4.248v1.836c.793-1.307 2.041-2.084 3.558-2.084.444 0 1.155.106 1.435.248l-.641 4.238z"
        ></path>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M64.29 38.591V31.94h-4.247v19.862h4.247v-1.649c.945 1.284 2.485 1.978 4.247 1.978 4.002 0 6.593-3.355 6.593-7.782 0-4.403-2.626-7.735-6.593-7.735-1.738 0-3.314.671-4.247 1.978zm6.406 5.816c0 2.225-1.027 4.061-3.174 4.061-2.159 0-3.232-1.813-3.22-4.061 0-2.225 1.096-4.12 3.22-4.12 2.159 0 3.174 1.895 3.174 4.12z"
            clipRule="evenodd"
        ></path>
    </svg>
);
