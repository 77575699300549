import React from 'react';
import history from './history';
import { Router } from 'react-router-dom';

//https://github.com/ReactTraining/history/issues/427

let lastPath = ' ';

export function RouterWrapper({ children }) {
    history.listen(location => {
        if (lastPath === location.pathname) {
        } else {
            lastPath = location.pathname;
            if (window?.utag?.view) {
                window.utag.view({ page: location.pathname });
            }
        }
    });
    return <Router history={history}>{children}</Router>;
}
