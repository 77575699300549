import { Uppy } from '@uppy/core';
import { parseFile } from '../utils/jpegUtils';
import store from './store';
import { UPLOAD_ERROR, UPLOAD_PROGRESS, uploadErrorSet, uploadProcessing, uploadStart } from './fileReducer';
import { validateResolution } from '../utils/productService';
import { autoDetectResult, initialState } from './productReducer';
import { BlurbyMultipartV1Plugin } from '../multipart-blurby-v1-plugin/BlurbyMultipartV1Plugin';
import apiClient from '../components/apiClient';
import history from '../history';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import ctEventsService from '../utils/ctEvents/CTEventsService';
import { BlurbyMultipartV2Plugin } from '../multipart-blurby-v2-plugin/BlurbyMultipartV2Plugin';

export function customFileListener(files, uppy, history) {
    files.forEach(async file => {
        try {
            const returnValue = await parseFile(file, true);
            const { validations, ...fileData } = returnValue;
            const fileValid = returnValue.validations.success;
            let fileState = {};

            const urlParams = new URLSearchParams(window.location.search);
            const material = urlParams.get('material');

            if (fileValid) {
                const { product, validations: resolutionValidation } = validateResolution(
                    returnValue,
                    initialState,
                    validations,
                    true,
                    material
                );
                fileState = { validations: resolutionValidation, ...fileData };
                await store.dispatch(fileState);
                await store.dispatch(autoDetectResult(product));
            } else {
                fileState = returnValue;
                await store.dispatch(fileState);
            }

            if (validations.success) {
                await history.push('/edit');
            } else {
                await history.push('/', {
                    from: 'validator,',
                });
            }
        } catch (err) {
            if (err.isRestriction) {
                // handle restrictions
                console.log('Restriction error:', err);
            } else {
                // handle other errors
                console.error(err);
            }
        }
    });
}

async function pollBlurbyForSuccess(sessionId, bookserveId, uppy) {
    let tries = 25;
    let initialTimeOut = 2;
    let backoffFactor = 0.5;
    let result = null;
    let success = false;
    do {
        let timeout = initialTimeOut * 1000;
        const files = await uppy.getFiles();

        if (!files || files.length === 0) {
            return { success: false, result: 'upload canceled', cancelled: true };
        }
        const response = await apiClient.get(
            `/v2/printable_products/status/${bookserveId}?api_key=${process.env.REACT_APP_API_KEY}&session_id=${sessionId}&src_info=src_url`
        );
        if (!files || files.length === 0) {
            return { success: false, result: 'upload canceled', cancelled: true };
        }
        result = response.data;
        success = response.data.status === 'success';
        if (success) {
            return { success, result, cancelled: false };
        }
        if (response.data.status === 'failure') {
            return { success: false, result, cancelled: false };
        }
        await new Promise(r => setTimeout(r, timeout));

        initialTimeOut = initialTimeOut + backoffFactor;
        tries--;
    } while (tries > 0 && success === false);

    return { success, result };
}

function createUppy() {
    const uppy = new Uppy({
        debug: true,
        autoProceed: false,
        allowMultipleUploads: true,
        restrictions: {
            maxNumberOfFiles: 2,
            minNumberOfFiles: 1,
            maxFileSize: 500 * 1000 * 1000, // 500MB
            allowedFileTypes: ['image/*', 'jpg', 'jpeg', 'application/xml'],
        },
        onBeforeFileAdded: file => {
            if (file.handledByDoka) {
                return true;
            }
        },

        onBeforeUpload: files => {
            // We’ll be careful to return a new object, not mutating the original `files`
            const { product } = store.getState();
            const { printableProduct } = uppy.getState();

            // Here we are marking all files  as not uploaded. Because it can happen that user already started and upload,
            // went back change something and then reuploaded the photo.
            uppy.getFiles().forEach(file => {
                uppy.setFileState(file.id, {
                    progress: {
                        percentage: 0,
                        bytesUploaded: 0,
                        bytesTotal: file.size,
                        uploadComplete: false,
                        uploadStarted: null,
                    },
                });
            });

            uppy.setState({
                product: product.productData,
                printableProduct: {
                    session_id: printableProduct.sessionId,
                    api_key: process.env.REACT_APP_API_KEY,
                    printable_product_type: product.productData.printable_product_type,
                    title: printableProduct.title,
                    creation_tool: 'Web_Builder',
                    creation_tool_version: process.env.REACT_APP_CREATION_TOOL_VERSION,
                    color_space: 'RGB',
                    printable_product_dimension: product.productData.printable_product_dimension,
                    corner_type: product.productData.corner_type,
                    finish_type: product.productData.finish_type,
                    hanging_hardware_type: product.productData.hanging_hardware_type,
                    paper_base_type: product.productData.paper_base_type,
                },
            });
        },
    });

    uppy.on('upload', data => {
        let image = {};
        let analytics = {};
        uppy.getFiles().forEach(file => {
            if (file.type.includes('image')) {
                image = {
                    bytesUploaded: 0,
                    bytesTotal: 0,
                };
            } else {
                analytics = {
                    bytesUploaded: 0,
                    bytesTotal: 0,
                };
            }
        });

        store.dispatch(uploadStart(image, analytics));
        const { upload } = store.getState();
        ctEventsService.sendUploadStart(upload.project_id, 'Upload started');
    });

    uppy.on('upload-progress', (file, progress) => {
        let fileType = {};
        if (file.type.includes('image')) {
            fileType = {
                image: {
                    ...progress,
                },
            };
        } else {
            fileType = {
                analytics: {
                    ...progress,
                },
            };
        }

        store.dispatch({ type: UPLOAD_PROGRESS, ...fileType });
    });

    uppy.on('upload-success', (file, response) => {
        const { uploadURL } = response;
        let fileType = {};
        const { upload } = store.getState();
        if (file.type.includes('image')) {
            fileType = {
                image: {
                    location: uploadURL,
                },
            };
            ctEventsService.sendUploadSuccess(upload.project_id, file,true);
        } else {
            fileType = {
                analytics: {
                    location: uploadURL,
                },
            };
            ctEventsService.sendUploadSuccess(upload.project_id, file,false);

        }

        store.dispatch({ type: UPLOAD_PROGRESS, ...fileType });


    });

    uppy.on('error', error => {
        store.dispatch(uploadErrorSet(true));
        const { upload } = store.getState();
        ctEventsService.sendUploadFail(upload.project_id, error);
    });

    uppy.on('upload-error', (file, error, response) => {
        store.dispatch(uploadErrorSet(true));
        const { upload } = store.getState();
        ctEventsService.sendUploadFail(upload.project_id, error);
    });

    uppy.on('complete', async result => {
        let success = false;

        const { upload } = store.getState();

        if (result?.successful?.length > 0 && result.failed?.length === 0) {
            const { printableProduct, upload_info } = uppy.getState();
            let bookserveId = upload_info.bookserve_id;
            if (bookserveId) {
                store.dispatch(uploadProcessing(true, false, null, null));
                ctEventsService.sendProcessingStart(
                    upload.project_id,
                    'Started processing ' + bookserveId
                );

                const { success, result, cancelled } = await pollBlurbyForSuccess(
                    printableProduct.session_id,
                    bookserveId,
                    uppy
                );
                if (success) {
                    store.dispatch(uploadProcessing(true, true, true, result));
                    window.location.href = result.printable_product_url;
                    ctEventsService.sendProcessingSuccess(upload.project_id, result);
                } else {
                    if (cancelled) {
                        store.dispatch(uploadProcessing(false, false, null, null));
                        ctEventsService.sendUploadCancel(upload.project_id , 'Upload cancelled');
                        return;
                    }
                    ctEventsService.sendProcessingError(upload.project_id, result);

                    store.dispatch(uploadProcessing(true, true, false, result));
                }
            }
        } else {
            ctEventsService.sendUploadFail(upload.project_id, result);

            store.dispatch(uploadProcessing(true, true, false, result));
        }
    });

    const blurbyUploadServicePlugin = process.env.REACT_APP_CREATION_TOOL_VERSION === '1' ? BlurbyMultipartV1Plugin : BlurbyMultipartV2Plugin;
    uppy.use(blurbyUploadServicePlugin, {
        limit: 5,
        companionUrl: process.env.REACT_APP_API_URL,
    });

    return uppy;
}

const uppy = createUppy();
export default uppy;
